import i18next from 'i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { tableHelper } from 'common/helpers/table/table-helper';
import { StatusFilterDropdown } from 'common/partials/StatusFilterDropdown';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { DeleteModal } from 'common/partials/DeleteModal';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import ProtectedComponent from 'common/partials/ProtectedComponent';
import ActionButton from 'common/partials/ActionButton';
import { StatusFilter } from 'common/types/status-filter.model';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { OperationSystemModel as Model } from '../model/operation-system.model';
import { OperationSystemService as Service } from '../services/operation-system.service';

export function OperationSystem() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Model[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = (id: number) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const fetchData = async (status: StatusFilter = StatusFilter.Active) => {
    try {
      setIsLoading(true);
      const response = await Service.getDataList(status);
      if (response && !response.hasErrors) {
        setTableData(response.data);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: TableColumn<Model>[] = [
    {
      name: `${t('table.id')}`,
      selector: (row) => row.id || '',
      sortable: true,
      width: '60px'
    },
    {
      name: `${t('table.name')}`,
      selector: (row) => row.name || '',
      sortable: true
    },
    {
      name: `${t('table.description')}`,
      selector: (row) => row.description || '',
      sortable: true
    },
    {
      name: `${t('table.status')}`,
      cell: (row) =>
        row.isActive ? (
          <span className="badge badge-light-primary">{t('generalMessages.active')}</span>
        ) : (
          <span className="badge badge-light-danger">{t('generalMessages.inactive')}</span>
        ),
      center: true,
      width: '150px'
    },
    {
      name: `${t('table.actions')}`,
      cell: (row) => (
        <div className="d-grid d-flex gap-2">
          <ActionButton type='edit' onClick={() => navigate(`/masterdata/operationsystem/${row.id}`)} roles={[RolesDictionary.Infrastructure.OperationSystems_Update]} />
          <ActionButton type='deactivate' onClick={() => handleShowDeleteModal(row.id!)} roles={[RolesDictionary.Infrastructure.OperationSystems_Update]} disabled={!row.isActive} />
        </div>
      ),
      center: true,
      width: '100px'
    },
  ];

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const deleteDataById = async () => {
    try {
      setIsLoading(true);
      const result = await Service.deleteDataById(deleteId);
      if (result && !result.hasErrors) {
        toast.success(`${t('crud.deactivate.successMessage')}`);
      } else {
        toast.error(`${t('crud.deactivate.errorMessage')}`);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setToggleCleared(!toggleCleared);
      handleCloseDeleteModal();
      fetchData();
      setIsLoading(false);
    }
  };

  const contextActions = useMemo(() => {
    const deleteSelectedRows = () => {
      setShowDeleteModal(true);
      selectedRows.forEach((row) => {
        setDeleteId(row.id!);
      });
    }

    return (
      <button type="button" className="btn btn-sm btn-danger" onClick={deleteSelectedRows}>
        {t('crud.deactivate.buttonSave')}
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight>
          <div className="d-flex align-items-center gap-2">
            <StatusFilterDropdown handleFilterData={fetchData} />
            <ProtectedComponent action='hide' role={[RolesDictionary.Infrastructure.OperationSystems_Update]}>
              <Link to="/masterdata/operationsystem/add" className="btn btn-sm btn-primary fw-bolder">
                {t('addDataButton')}
              </Link>
            </ProtectedComponent>
          </div>
        </ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <div className="card card-flush">
          <div className="card-body">
            <DataTable
              title={selectedRows.length === 0 ? false : <></>}
              persistTableHead
              columns={columns}
              data={tableData}
              noDataComponent={tableHelper.getNoDataComponent()}
              pagination
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
              paginationPerPage={tableHelper.defaultRowsPerPage}
              paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
              selectableRows
              selectableRowsHighlight
              dense
              highlightOnHover
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              contextActions={contextActions}
              contextMessage={tableHelper.getContextMessage()}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
              selectableRowsVisibleOnly
              customStyles={itLabTableStyle}
            />
          </div>
        </div>

        <DeleteModal
          type='deactivate'
          handleDelete={deleteDataById}
          onClose={handleCloseDeleteModal}
          open={showDeleteModal}
        />
      </MainLayout>
    </MasterLayout>
  );
}
