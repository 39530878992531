interface ScreenLoaderProps {
  isLoading: boolean;
}

export default function ScreenLoader({ isLoading }: ScreenLoaderProps) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={`screen-loader ${isLoading ? 'show' : ''}`}>
      <div className="screen-loader-overlay"></div>
      <div className="screen-loader-spinner">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};