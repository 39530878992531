/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { formatLocalizedDateTime } from 'common/helpers/dateUtils';
import { NextAndLastRunModel as Model } from 'app/dashboard/model/dashboard.model'
import { DashboardService as Service } from '../../services/dashboard.service'
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import { Tooltip } from '@mui/material';
import DashboardContext from 'app/dashboard/context/dashboard.context';

type Props = {
  className: string;
}

export function RobotNextExecutions(props: Props) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();
  const { hubConnection } = useContext(DashboardContext);
  const [nextRuns, setNextRuns] = useState<Model[]>([]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchNextRuns = async () => {
    setIsLoading(true);
    const response = await Service.getNextExecutions();
    if (response && !response.hasErrors) {
      setNextRuns(response.data);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const receiveMessages = async () => {
      hubConnection?.on("GetNextRun", message => setMessage(message));
    };
    receiveMessages();

    return () => {
      hubConnection?.off("GetNextRun");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubConnection]);


  useEffect(() => {
    fetchNextRuns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const groupedRuns = nextRuns.reduce((acc, run) => {
    const time = formatLocalizedDateTime(run.runDate, "HH:mm");
    if (time) {
      if (!acc[time]) {
        acc[time] = [];
      }
      acc[time].push(run);
    }
    return acc;
  }, {} as Record<string, Model[]>);

  return (
    <div className={`card ${props.className}`}>
      <div className='card-header align-items-center border-0 my-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>{t('stepNextExecutions.title')}</span>
          <span className='text-muted fw-bold fs-7'>
            {nextRuns.length > 0
              ? `${nextRuns.length} ${t('stepNextExecutions.nextTwoHours')}`
              : `${t('stepNextExecutions.noExecution')}`}
          </span>
        </h3>
      </div>
      {isLoading && (
        <div className="text-center mb-10">
          <span className="indicator-progress" style={{ display: "block" }}>
            <span className="spinner-border spinner-border-lg align-middle text-primary"></span>
          </span>
        </div>
      )}
      {!isLoading && (
        <div className='card-body pt-5 mh-300px overflow-auto'>
          <div className='timeline-label'>
            {Object.entries(groupedRuns).map(([time, runs], index) => (
              <div key={index} className='timeline-item'>
                <div className='timeline-label fw-bold text-gray-800 fs-6'>{time}</div>
                <div className='timeline-content'>
                  {runs.map((run, subIndex) => (
                    <div key={subIndex} className='d-flex align-items-center mb-2'>
                      <Tooltip title={t('schedules.runningStatus.Scheduled')}>
                        <div className='timeline-badge'>
                        <i className='fa-regular fa-clock text-primary fs-2' />
                        </div>
                      </Tooltip>
                      <div>
                        <span className='fw-bold text-muted ps-3 d-block'>{run.stepName}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div >
  )
}