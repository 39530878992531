import { useTranslation } from 'react-i18next';

interface SaveButtonProps {
    loading: boolean;
    type: "button" | "submit" | "reset";
    onClick?: () => void;
}

function SaveButton(props: SaveButtonProps) {
    const { t } = useTranslation();

    return (
        <button
            type={`${props.type}`}
            className="btn btn-primary"
            disabled={props.loading}
            onClick={props.onClick}
        >
            {!props.loading && t('crud.update.buttonSave')}
            {props.loading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                    {t('crud.common.wait')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            )}
        </button>
    )
}

export default SaveButton