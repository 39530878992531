import { AgentLogStatus } from "app/dashboard/model/dashboard.model";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useFilterParams = () => {
  const location = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    
    const statusParam = searchParams.get('status');
    const status = statusParam ? Number(statusParam) as AgentLogStatus : undefined;
    
    const errorReasonParam = searchParams.get('hasErrorReason');
    const errorReason = errorReasonParam !== null ? errorReasonParam === 'true' : undefined;

    return { status, errorReason };
  }, [location.search]);
};
