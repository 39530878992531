import { http } from 'common/helpers/axios/axios-helper';
import { ProfileSelectorModel, UserAndGroupsModel } from '../model/profile-selector.model';

const basePath = '/group';

async function getGroups() {
  return await http.get<ProfileSelectorModel[]>(basePath);
}

async function getUserAndGroups(userId: number) {
  return await http.get<UserAndGroupsModel>(`/User/${userId}/allgroups`);
}

async function putGroups(userId: number, groups: number[]) {
  let data = { userId: userId, groupId: groups };
  return await http.put(`${basePath}/updateGroupUser`, data);
}


export const ProfileSelectorService = { getGroups, getUserAndGroups, putGroups };
