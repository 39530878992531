import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import { Modal } from 'react-bootstrap'
import { DepartamentsService } from 'app/departaments/services/departaments.service';
import { DepartamentsModel } from 'app/departaments/model/departaments.model';
import closeModalIcon from 'assets/images/icons/close.svg'
import ITSVG from 'common/helpers/ITSVG';
import SaveButton from 'common/partials/SaveButton';
import { StatusFilter } from 'common/types/status-filter.model';
import { DepartamentsLinkModel as Model } from '../model/departaments-link.model';
import { DepartamentsLinkService as Service } from '../services/departaments-link.service';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';

interface Props {
  id: number;
  open: boolean;
  handleOpen: (id: number) => void;
  handleClose: () => void;
  handleFetchModalData: () => void;
}

export function CreateEditDepartamentsLink(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const { id } = useParams();

  const formSchema = Yup.object().shape({
    departmentId: Yup.string().required(t('crud.validators.requiredField')),
  });

  let initialData: Model = {
    id: props.id ?? null,
    processId: parseInt(id ?? '0'),
    isActive: true,
  };

  const [data] = useState<Model>(initialData);
  const [departaments, setDepartaments] = useState<DepartamentsModel[]>([]);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: Model = {
          id: data.id,
          processId: data.processId,
          departmentId: values.departmentId,
          isActive: values.isActive,
        };

        const result = await Service.postData(body);
        if (result && !result?.hasErrors) {
          toast.success(`${t('crud.create.successMessage')}`);
        } else {
          toast.error(`${t('crud.create.errorMessage')}`);
        }
      } catch (error) {
        toast.error(`${t('crud.create.errorMessage')}`);
      } finally {
        props.handleClose();
        props.handleFetchModalData();
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchDepartaments = async () => {
      if (id) {
        const response = await DepartamentsService.getDataList(StatusFilter.Active);
        if (response && !response.hasErrors) {
          setDepartaments(response.data);
        };
      }
    }
    if (props.open) {
      fetchDepartaments();
    }

    return () => {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Modal
      show={props.open}
      onHide={props.handleClose}
      dialogClassName='modal-dialog-centered mw-600px h-auto'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3>{t('addDataDepartament')}</h3>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.handleClose}>
            <ITSVG path={closeModalIcon} className='svg-icon-1' />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-body scroll-y mx-5 my-5">
            <div className="row">
              <div className="col-md-12">
                <label className="required form-label">{t('form.label.name')}</label>
                <Autocomplete
                  id="departmentId"
                  options={departaments}
                  getOptionLabel={option => option.name ? option.name : ""}
                  onChange={(_, value) => formik.setFieldValue("departmentId", value?.id)}
                  onInputChange={(_, value) => {
                    if (!value) {
                      formik.setFieldValue("departmentId", undefined)
                    }
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      placeholder={`${t('generalMessages.select')}`}
                    />
                  }
                />
                {formik.errors.departmentId && (
                  <div className="mt-3 text-danger fw-bold">{formik.errors.departmentId}</div>
                )}
              </div>
            </div>
          </div>

          <div className="modal-footer d-flex justify-content-end py-6 px-9">
            <button type="button" className="btn btn-light btn-active-light-primary me-2" onClick={props.handleClose}>
              {t('crud.create.buttonCancel')}
            </button>
            <SaveButton type="submit" loading={isLoading} />
          </div>
        </form>
      </div>
    </Modal>
  );
}
