import { http } from 'common/helpers/axios/axios-helper';
import { ScreenshotList as Model } from '../model/machine.model';

const basePath = '/Machine';

async function getScreenshots(id: any) {
    return await http.get<Model[]>(`${basePath}/${id}/screenshots`);
}

async function downloadFile(id: any, filename: string) {
    const data =
    {
        "filename": filename
    };
    return await http.downloadpost(`${basePath}/${id}/screenshots/download/`, data);
}

export const ScreenshotLinkService = { getScreenshots, downloadFile };
