import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

interface AutocompleteProps<T> {
  id: string;
  label: string;
  options: T[];
  value: T | null;
  onChange: (event: React.SyntheticEvent, value: T | null) => void;
  getOptionLabel?: (option: T) => string;
  onOpen?: () => void;
  isLoading?: boolean;
  noOptionsText?: string;
  disabled?: boolean;
}

export function CustomAutocomplete<T>({
  id,
  label,
  options,
  value,
  onChange,
  getOptionLabel,
  onOpen,
  isLoading,
  noOptionsText,
  disabled
}: AutocompleteProps<T>) {
  const { t } = useTranslation();

  const handleChange = (
    event: React.SyntheticEvent,
    value: T | null
  ) => {
    if (onChange) {
      event.stopPropagation();
      onChange(event, value);
    }
  };


  return (
    <div>
      <label className='form-label fw-bold'>{label}</label>
      <Autocomplete
        id={id}
        options={options}
        value={value}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        onOpen={onOpen}
        renderInput={(params) =>
          <TextField
            {...params}
            placeholder={t('generalMessages.select')}
          />
        }
        loading={isLoading}
        loadingText={<div className='text-center'><CircularProgress size={14} /></div>}
        noOptionsText={noOptionsText || t('generalMessages.noOptions')}
        disabled={disabled}
      />
    </div>
  );
}
