import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { TokenHelper } from "common/helpers/auth/token-helper";
import LoadingSpinner from "common/partials/LoadingSpinner";
import { APP_ROUTES } from "./routes";

interface ProtectedRouteProps {
  role: number[];
  children: JSX.Element;
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const helper = new TokenHelper();

  if (!helper.HasAccess(props.role)) {
    return <Navigate to={APP_ROUTES.unauthorizedpage} replace />;
  }

  return props.children
    ? props.children
    : <Suspense fallback={<LoadingSpinner />}><Outlet /></Suspense>;
}