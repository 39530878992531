import { http } from 'common/helpers/axios/axios-helper';
import { MachineModel as Model } from '../model/machine.model';

const basePath = '/Machine';

async function getDataList(id: any) {
  return await http.get<Model[]>(`${basePath}/${id}/process`);
}

async function getDataById(id: any) {
  return await http.get<Model>(`${basePath}/${id}`);
}

async function postData(data: Model) {
  return await http.post<Model>(`${basePath}/process`, data);
}

async function putData(data: Model) {
  return await http.put<Model>(`${basePath}/${data.id}`, data);
}

async function deleteDataById(data: any) {
  return await http.put(`${basePath}/process`, data);
}

export const ProcessLinkService = { getDataList, getDataById, postData, putData, deleteDataById };
