import DashboardContext from "app/dashboard/context/dashboard.context";
import ITSVG from "common/helpers/ITSVG";
import { useCallback, useContext, useEffect, useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import closeModalIcon from 'assets/images/icons/close.svg';
import { ScreenshotModalData, StepLogActionScreenShotPostModel } from "app/dashboard/model/dashboard.model";
import { DashboardService as Service } from "../../services/dashboard.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import en from "../../i18n/en-us.json";
import es from "../../i18n/es-es.json";
import pt from "../../i18n/pt-br.json";
import { LinearProgress, Tooltip } from "@mui/material";
import '../../styles/carousel-style.css'

interface ScreenshotModalProps {
    show: boolean;
    onClose: () => void;
    data: ScreenshotModalData;
}

export default function ScreenshotModal(props: ScreenshotModalProps) {
    i18next.addResourceBundle("us", "translation", en);
    i18next.addResourceBundle("es", "translation", es);
    i18next.addResourceBundle("br", "translation", pt);
    const { t } = useTranslation();
    const { hubConnection } = useContext(DashboardContext);

    const waitingTime = 15;
    const hubConnectionName = `${props.data.machineName}-${props.data.stepId}`;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingNewScreenshot, setIsLoadingNewScreenshot] = useState<boolean>(false);
    const [screenshotAvailable, setScreenshotAvailable] = useState<boolean>(true);
    const [screenshotText, setScreenshotText] = useState<string>(t("screenshotModal.button"));
    const [base64Array, setBase64Array] = useState<string[]>([]);
    const [count, setCount] = useState(waitingTime);

    const closeHubConnection = useCallback(() => {
        if (hubConnection) {
            hubConnection.off(hubConnectionName);
        }
    }, [hubConnection, hubConnectionName]);
    
    useEffect(() => {
        const receiveMessages = async () => {
            if (hubConnection) {
                hubConnection.on(hubConnectionName, message => {
                    if (message.messageCode === "base64") {
                        setBase64Array(prev => [...prev, message.message]);
                    }
                });
            }
        }
        receiveMessages();

        return (() => {
            closeHubConnection();
        })
    }, [hubConnectionName, hubConnection, closeHubConnection]);

    const onClose = () => {
        props.onClose();
    }

    const machineScreenshot = async (body: StepLogActionScreenShotPostModel) => {
        setIsLoading(true);
        setScreenshotAvailable(false);
        setCount(waitingTime - 1);
        setIsLoadingNewScreenshot(true);
        const response = await Service.machineScreenshot(body);
        if (response && !response.hasErrors) {
            toast.success(`${t("crud.refresh.successMessage")}`);
        } else {
            toast.error(`${t("crud.refresh.errorMessage")}`);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (count === 0) {
            setScreenshotAvailable(true);
            setIsLoadingNewScreenshot(false);
            setScreenshotText(t("screenshotModal.button"))
        } else if (count < waitingTime) {
            const timer = setTimeout(() => {
                setCount(count - 1);
                setScreenshotText(`${count}`)
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [count]);

    const openImageNewTab = (base64: string) => {
        const image = new Image();
        image.src = `data:image/jpg;base64,${base64}`;
        const newTab = window.open();
        if (newTab) {
            newTab.document.body.innerHTML = image.outerHTML;
        }

    }

    return <>
        <Modal
            show={props.show}
            onHide={onClose}
            dialogClassName="modal-dialog-centered mw-900px h-auto"
        >
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>{t("screenshotModal.title")}</h3>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={onClose}>
                        <ITSVG path={closeModalIcon} className='svg-icon-1' />
                    </div>
                </div>
                <div className="row">
                    <div className="col px-10">
                        {isLoadingNewScreenshot &&
                            <LinearProgress variant="determinate" value={(waitingTime - count) * (100 / waitingTime)} />
                        }
                        <div className="d-flex justify-content-end flex-shrink-0">
                            <Tooltip title={screenshotText} arrow>
                                <span>
                                    <button
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mt-1"
                                        type="button"
                                        onClick={() => machineScreenshot({ machineName: props.data.machineName, stepId: props.data.stepId, hashRun: props.data.hashRun })}
                                        disabled={!screenshotAvailable}
                                    >
                                        <i className="fa-solid fa-camera" />
                                    </button>
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="row mb-10">
                    <div className="col">
                        <div className="d-flex justify-content-center" >
                            {
                                base64Array.length === 0
                                    ?
                                    <>
                                        <div className="card text-center p-0">
                                            <div className="card-body">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="fw-bolder mb-2 text-dark">{t("screenshotModal.noImages")}</span>
                                                    <br />
                                                    <span className="text-muted fw-bold fs-7">{t("screenshotModal.instructions")}</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </>
                                    : <>
                                        <div>
                                            <Carousel
                                                variant="dark"
                                                prevIcon={<i className="fa-solid fa-caret-left"></i>}
                                                nextIcon={<i className="fa-solid fa-caret-right"></i>}
                                            >
                                                {base64Array.map((base64, index) =>
                                                    <Carousel.Item>
                                                        <a onClick={(e) => { e.preventDefault(); openImageNewTab(base64) }} href="/" target="_blank" rel="noreferrer">
                                                            <img height={"300px"} key={index} src={`data:image/jpg;base64,${base64}`} alt="screenshot" />
                                                        </a>
                                                    </Carousel.Item>)
                                                }
                                            </Carousel>
                                        </div>
                                    </>}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>;
}