import { http } from 'common/helpers/axios/axios-helper';
import { ProcessModel as Model } from '../../../../model/process.model';

const basePath = '/Process';

async function getDataList(id: any) {
  return await http.get<Model[]>(`${basePath}/${id}/document`);
}

async function getDataById(id: any) {
  return await http.get<Model>(`${basePath}/${id}`);
}

async function postData(data: Model) {
  return await http.post<Model>(`${basePath}/document`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function putData(data: Model) {
  return await http.put<Model>(`${basePath}/${data.id}`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function deleteDataById(data: any) {
  return await http.put(`${basePath}/document`, data);
}

async function downloadFile(id: number) {
  return await http.download(`${basePath}/document/${id}/download`);
}

export const documentsService = { getDataList, getDataById, postData, putData, deleteDataById, downloadFile };
