import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface ScheduledEventsModalProps {
  open: boolean;
  onClose: () => void;
}

export default function ScheduledEventsModal({ open, onClose }: ScheduledEventsModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      show={open}
      onHide={onClose}
      dialogClassName='modal-dialog-centered mw-400px h-auto'
    >
      <div className="card">
        <div className="card-body">
          <div className="d-flex mb-2">
            <span className="me-4">
              <i className="fa-solid fa-circle-exclamation text-warning fa-2xl" />
            </span>
            <h3>{t('scheduledEventsModal.title')}</h3>
          </div>
          <div className="fs-6 mb-3">
            <span>{t('scheduledEventsModal.description')}</span>
          </div>
          <div className="row text-center">
          </div>
        </div>
      </div>
    </Modal>
  )
}