/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { formatLocalizedDateTime } from 'common/helpers/dateUtils';
import { NextAndLastRunModel as Model, NextAndLastRunRequestModel } from 'app/dashboard/model/dashboard.model';
import { getTimeLineBadge } from 'app/dashboard/utils/dashboardUtils';
import { DashboardService as Service } from '../../services/dashboard.service';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import RunsFilterDropdown from './RunsFilterDropdown';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routes/routes';
import DashboardContext from 'app/dashboard/context/dashboard.context';

type Props = {
  className: string;
};

export function RobotLastExecutions(props: Props) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();
  const { hubConnection } = useContext(DashboardContext);
  const [lastRuns, setLastRuns] = useState<Model[]>([]);
  const [message, setMessage] = useState<string>('');
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
  const [lastHoursFilter, setLastHoursFilter] = useState<number | undefined>();
  const navigate = useNavigate();

  const fetchLastRuns = async (request?: NextAndLastRunRequestModel) => {
    setRefreshLoading(true);

    const requestBody: NextAndLastRunRequestModel = {
      timeRange: request?.timeRange ?? 2
    }

    const response = await Service.getLastExecutions(requestBody);
    if (response && !response.hasErrors) {
      setLastRuns(response.data);
      setLastHoursFilter(requestBody.timeRange)
    }

    if (initialLoading) {
      setInitialLoading(false);
    }

    setRefreshLoading(false);
  };

  useEffect(() => {
    const receiveMessages = async () => {
      hubConnection?.on('GetLastRun', message => setMessage(message));
    };
    receiveMessages();

    return () => {
      hubConnection?.off('GetLastRun');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubConnection]);

  useEffect(() => {
    fetchLastRuns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const groupedRuns = lastRuns.reduce((acc, run) => {
    const time = formatLocalizedDateTime(run.runDate, "HH:mm");
    if (time) {
      if (!acc[time]) {
        acc[time] = [];
      }
      acc[time].push(run);
    }
    return acc;
  }, {} as Record<string, Model[]>);

  return (
    <div className={`card ${props.className}`}>
      <div className='card-header align-items-center border-0 my-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>{t('stepLastExecutions.title')}</span>
          <span className='text-muted fw-bold fs-7'>
            {lastRuns.length > 0
              ? `${t('stepLastExecutions.lastHours', { count: lastRuns.length, hours: lastHoursFilter })}`
              : `${t('stepLastExecutions.noExecutionInLastHours', { hours: lastHoursFilter })}`}
          </span>
        </h3>
        <div className='d-flex gap-2'>
          <RunsFilterDropdown handleFilterData={fetchLastRuns} />
          <Tooltip title={t('stepLastExecutions.seeAll')}>
            <button
              type="button"
              className="btn btn-icon btn-bg-light btn-sm btn-active-color-primary"
              onClick={() => navigate(`/processes/${APP_ROUTES.robotExecutions.list}?allExecutions=${true}`)}
            >
              <span className="svg-icon svg-icon-3">
                <i className="fa-solid fa-arrow-up-right-from-square" />
              </span>
            </button>
          </Tooltip>
        </div>
      </div>

      {initialLoading ? (
        <div className="text-center mb-10">
          <span className="indicator-progress" style={{ display: "block" }}>
            <span className="spinner-border spinner-border-lg align-middle text-primary"></span>
          </span>
        </div>
      ) : (
        <div className='card-body pt-5 mh-300px overflow-auto'>
          <div className='timeline-label'>
            {Object.entries(groupedRuns).map(([time, runs], index) => (
              <div key={index} className='timeline-item'>
                <div className='timeline-label fw-bold text-gray-800 fs-6'>{time}</div>
                <div className='timeline-content'>
                  {runs.map((run, subIndex) => (
                    <div key={subIndex} className='d-flex align-items-center mb-2'>
                      <Tooltip title={t('schedules.runningStatus.' + run.statusText)}>
                        <div className='timeline-badge'>
                          <i className={`fs-2 ${getTimeLineBadge(run.status)}`}></i>
                        </div>
                      </Tooltip>
                      <div>
                        <span className='fw-bold text-muted ps-3 d-block'>{run.stepName}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {refreshLoading && (
            <div className="text-center mb-10">
              <span className="indicator-progress" style={{ display: "block" }}>
                <span className="spinner-border spinner-border-md align-middle text-primary"></span>
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}