import { AlertEmpty } from "./alert-empty";
import { AlertItem } from "./alert-item";
import { NotificationModel } from "./alerts.model"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface AlertsProps {
  notifications: NotificationModel[];
  show: boolean;
  closeAlerts: () => void;
  viewed: (notification: NotificationModel) => void;
}

export function Alerts(props: AlertsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const notifications = props.notifications;
  const popupSize = notifications.length > 10 ? 10 : notifications.length;

  return (
    <>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
        data-kt-menu="true"
        id="kt_menu_notifications"
        style={{
          zIndex: 107,
          position: "fixed",
          inset: "0px 0px auto auto",
          margin: 0,
          transform: "translate(-93px, 40px)",
          display: props.show ? "block" : "none"
        }}
        data-popper-placement="bottom-end"
      >
        {/*begin::Heading*/}
        <div
          className="d-flex align-items-center justify-content-between py-3 rounded-top"
          style={{"backgroundColor": "#1e1e2d"}}>
          <h3 className="text-white fw-semibold px-9 mt-10 mb-6" style={{"textAlign": "left"}}>
            {`${t("alerts.notifications")}`} <span className="fs-8 opacity-75 ps-3">{props.notifications.filter(x => !x.read).length} {`${t("alerts.noViewed")}`}</span>
          </h3>
        </div>
        {/*end::Heading*/}
        {/*begin::Tab content*/}
        <div className="tab-content">
          {/*begin::Tab panel*/}
          <div
            className="tab-pane fade active show"
            id="kt_topbar_notifications_1"
          >
            {/*begin::Items*/}
            <div className="scroll-y mh-325px my-5 px-8">
              {notifications.length > 0 ?
                notifications.slice(0, popupSize).map((n: NotificationModel) => {
                  return (<AlertItem notification={n} key={n.id} viewed={props.viewed} />)
                }) :
                <AlertEmpty />
              }
            </div>
            {/*end::Items*/}

            <div className="py-3 text-center border-top">
              
              <button 
                onClick={() => navigate("/notifications/center")}
                className="btn btn-color-gray-600 btn-active-color-primary"
              >
                {`${t("alerts.viewAll")}`}
              </button>
            </div>
            {/*end::View more*/}
          </div>
          {/*end::Tab panel*/}
        </div>
        {/*end::Tab content*/}
      </div>
    </>
  );
}