import React from 'react';
import clsx from 'clsx';
import { CLIENT_KEY } from 'config';

interface AppLogoProps {
  className?: string;
  height?: number;
}

const AppLogo: React.FC<AppLogoProps> = ({ height, className }) => {
  const clientName = CLIENT_KEY || 'itlab';
  
  return (
    <img
      className={clsx(className)}
      src={require(`../../assets/images/dynamic/${clientName}/app-logo.png`)}
      height={height}
      alt="App Logo"
    />
  );
};

export default React.memo(AppLogo);