import { http } from 'common/helpers/axios/axios-helper';
import {
    DataBaseConfigModel,
    FrontEndConfigModel,
    GeneralSettingModel,
    HealthCheckModel,
    LogConfigModel,
    SMTPConfigModel,
    UploadConfigModel
} from '../model/health-check.model';

const basePath = '/HealthCheck';

async function getHealthCheck() {
    return await http.get<HealthCheckModel>(basePath);
}

async function getDatabaseConfig() {
    return await http.get<DataBaseConfigModel>(basePath + '/database');
}

async function getNoSQLDatabaseConfig() {
    return await http.get<DataBaseConfigModel>(basePath + '/nosqldatabase');
}

async function getFrontendConfig() {
    return await http.get<FrontEndConfigModel>(basePath + '/frontend');
}

async function getLogConfig() {
    return await http.get<LogConfigModel>(basePath + '/log');
}

async function getSMTPConfig() {
    return await http.get<SMTPConfigModel>(basePath + '/smtp');
}

async function getUploadConfig() {
    return await http.get<UploadConfigModel>(basePath + '/upload');
}

async function getSpecificKey(key: string) {
    return await http.get<GeneralSettingModel>(basePath + '/' + key);
}

async function testSMTPConfig(emailToTest: string) {
    if(emailToTest === '')
        return await http.get(basePath + '/testsmtp');
        
    return await http.get(basePath + '/testsmtp/' + emailToTest);
}

async function testDataBaseConfig() {
    return await http.get(basePath + '/testdatabase');
}

async function testNoSQLDataBaseConfig() {
    return await http.get(basePath + '/testnosqldatabase');
}

async function testUploadConfig() {
    return await http.get(basePath + '/testupload');
}

async function testLogConfig() {
    return await http.get(basePath + '/testLog');
}

export const HealthCheckService = {
    getHealthCheck,
    getDatabaseConfig,
    getNoSQLDatabaseConfig,
    getFrontendConfig,
    getLogConfig,
    getSMTPConfig,
    getUploadConfig,
    getSpecificKey,
    testDataBaseConfig,
    testNoSQLDataBaseConfig,
    testSMTPConfig,
    testUploadConfig,
    testLogConfig
};
