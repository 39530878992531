import i18next from 'i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { tableHelper } from 'common/helpers/table/table-helper';
import { StatusFilterDropdown } from 'common/partials/StatusFilterDropdown';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { DeleteModal } from 'common/partials/DeleteModal';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import ProtectedComponent from 'common/partials/ProtectedComponent';
import ActionButton from 'common/partials/ActionButton';
import { StatusFilter } from 'common/types/status-filter.model';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { DepartamentsModel as Model } from '../model/departaments.model';
import { DepartamentsService as Service } from '../services/departaments.service';

export function Departaments() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Model[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteWithEventModal, setShowDeleteWithEventModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");

  const fetchData = async (status: StatusFilter = StatusFilter.Active) => {
    try {
      setIsLoading(true);
      const response = await Service.getDataList(status);
      if (response && !response.hasErrors) {
        setTableData(response.data);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setIsLoading(false);
    }
  };

  const requestDelete = async (id: number) => {
    try {
      setIsLoading(true);
      const response = await Service.requestDeleteDataById(id);
      if (response && !response.hasErrors) {
        setDeleteId(id);
        if (response.data.hasEvent) {
          setDeleteMessage(`${t("departaments.deleteMessage.withEvent",
            {
              directCompletion: response.data.directCompletion.length === 0
                ? `[${t("departaments.deleteMessage.none")}]`
                : response.data.directCompletion.join("<br />"),
              noCompletion: response.data.noCompletion.length === 0
                ? `[${t("departaments.deleteMessage.none")}]`
                : response.data.noCompletion.join("<br />")
            })}`)
          setShowDeleteWithEventModal(true);
        } else {
          setShowDeleteModal(true)
        }
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: TableColumn<Model>[] = [
    {
      name: `${t('departaments.tableHeaders.id')}`,
      selector: (row) => row.id || '',
      sortable: true,
      width: '60px'
    },
    {
      name: `${t('departaments.tableHeaders.name')}`,
      selector: (row) => row.name || '',
      sortable: true
    },
    {
      name: `${t('departaments.tableHeaders.description')}`,
      selector: (row) => row.description || '',
      sortable: true
    },
    {
      name: `${t('departaments.tableHeaders.color')}`,
      cell: (row) => (
        <div style={{ backgroundColor: row.color, width: 80, borderRadius: 15, textAlign: 'center' }}>&nbsp;</div>
      ),
      center: true
    },
    {
      name: `${t('departaments.tableHeaders.site')}`,
      selector: (row) => row?.site?.name || '',
      sortable: true
    },
    {
      name: `${t('departaments.tableHeaders.status')}`,
      cell: (row) =>
        row.isActive ? (
          <span className="badge badge-light-primary">{t('generalMessages.active')}</span>
        ) : (
          <span className="badge badge-light-danger">{t('generalMessages.inactive')}</span>
        ),
      center: true,
      width: '150px'
    },
    {
      name: `${t('departaments.tableHeaders.actions')}`,
      cell: (row) => (
        <div className="d-grid d-flex gap-2">
          <ActionButton type='edit' onClick={() => navigate(`/masterdata/departaments/${row.id}`)} roles={[RolesDictionary.MasterData.Departments_Update]} />
          <ActionButton type='deactivate' onClick={() => requestDelete(row.id!)} roles={[RolesDictionary.MasterData.Departments_Update]} disabled={!row.isActive} />
        </div>
      ),
      center: true,
      width: '100px'
    },
  ];

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const deleteDataById = async () => {
    try {
      setIsLoading(true);
      const result = await Service.deleteDataById(deleteId);
      if (result && !result.hasErrors) {
        toast.success(`${t('crud.deactivate.successMessage')}`);
      } else {
        toast.error(`${t('crud.deactivate.errorMessage')}`);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setToggleCleared(!toggleCleared);
      setShowDeleteModal(false);
      setShowDeleteWithEventModal(false);
      fetchData();
      setIsLoading(false);
    }
  };

  const contextActions = useMemo(() => {
    const deleteSelectedRows = () => {
      setShowDeleteModal(true);
      selectedRows.forEach((row) => {
        setDeleteId(row.id!);
      });
    }

    return (
      <button type="button" className="btn btn-sm btn-danger" onClick={deleteSelectedRows}>
        {t('crud.deactivate.buttonSave')}
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight>
          <div className="d-flex align-items-center gap-2">
            <StatusFilterDropdown handleFilterData={fetchData} />
            <ProtectedComponent action='hide' role={[RolesDictionary.MasterData.Departments_Update]}>
              <Link to="/masterdata/departaments/add" className="btn btn-sm btn-primary fw-bolder">
                {t('departaments.toolbar.addClient')}
              </Link>
            </ProtectedComponent>
          </div>
        </ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <div className="card card-flush">
          <div className="card-body">
            <DataTable
              title={selectedRows.length === 0 ? false : <></>}
              persistTableHead
              columns={columns}
              data={tableData}
              noDataComponent={tableHelper.getNoDataComponent()}
              selectableRows
              selectableRowsHighlight
              dense
              highlightOnHover
              pagination
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
              paginationPerPage={tableHelper.defaultRowsPerPage}
              paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              contextActions={contextActions}
              contextMessage={tableHelper.getContextMessage()}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
              selectableRowsVisibleOnly={true}
              customStyles={itLabTableStyle}
            />
          </div>
        </div>

        <DeleteModal
          type='deactivate'
          handleDelete={deleteDataById}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
        />

        <DeleteModal
          type='deactivate'
          handleDelete={deleteDataById}
          onClose={() => setShowDeleteWithEventModal(false)}
          open={showDeleteWithEventModal}
          text={deleteMessage}
          size='md'
        />
      </MainLayout>
    </MasterLayout>
  );
}
