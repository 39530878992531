import { Navigate, Outlet } from 'react-router-dom';
import { APP_ROUTES } from '../routes';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import { Content } from 'common/layout/components/Content';
import SignalRProvider from 'common/helpers/signalR/context/signalR.context';

function SecureRoute() {

  const content = (
    <SignalRProvider>
      <Content>
        <Outlet />
      </Content>
    </SignalRProvider>
  );

  const routeGuard = () => {
    if (new TokenHelper().HasToken()) {
      return content;
    }
    return <Navigate to={'/' + APP_ROUTES.login} />;
  };

  return routeGuard();
};

export default SecureRoute;
