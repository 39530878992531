import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import filterIcon from 'assets/images/icons/filter.svg';
import ITSVG from 'common/helpers/ITSVG';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import { ProcessStepsModel } from 'app/processes/components/childs/processes-steps/model/process-steps.model';
import { MachineModel } from 'app/machine/model/machine.model';
import { DepartamentsModel } from 'app/departaments/model/departaments.model';
import { ProcessStepsService } from 'app/processes/components/childs/processes-steps/services/process-steps.service';
import { ScheduleService } from 'app/schedule/services/schedule.service';
import { getStatusText } from 'app/dashboard/utils/dashboardUtils';
import { AgentLogStatus } from 'app/dashboard/model/dashboard.model';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routes/routes';
import { RobotExecutionsRequest } from '../models/robot-executions.model';
import { CustomAutocomplete } from '../../../common/partials/CustomAutocomplete';

interface Props {
  filters: RobotExecutionsRequest | undefined;
  setFilters: React.Dispatch<React.SetStateAction<RobotExecutionsRequest | undefined>>;
}

interface LogStatusOption {
  label: string;
  value?: AgentLogStatus;
}

interface LogResolvedOption {
  label: string;
  value?: boolean;
}

export function RobotExecutionsFilterDropdown({ filters, setFilters }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [departments, setDepartments] = useState<DepartamentsModel[]>([]);
  const [machines, setMachines] = useState<MachineModel[]>([]);
  const [steps, setSteps] = useState<ProcessStepsModel[]>([]);
  const [filterButtonName, setFilterButtonName] = useState<string>('');

  const helper = new TokenHelper();

  const [localFilters, setLocalFilters] = useState<RobotExecutionsRequest | undefined>(filters);

  const logStatusOptions: LogStatusOption[] = [
    { label: t('generalMessages.all'), value: undefined },
    { label: getStatusText(AgentLogStatus.NotRunning, t), value: AgentLogStatus.NotRunning },
    { label: getStatusText(AgentLogStatus.Running, t), value: AgentLogStatus.Running },
    { label: getStatusText(AgentLogStatus.Success, t), value: AgentLogStatus.Success },
    { label: getStatusText(AgentLogStatus.Stopped, t), value: AgentLogStatus.Stopped },
    { label: getStatusText(AgentLogStatus.Error, t), value: AgentLogStatus.Error },
    { label: getStatusText(AgentLogStatus.Suspect, t), value: AgentLogStatus.Suspect },
    { label: getStatusText(AgentLogStatus.Conflict, t), value: AgentLogStatus.Conflict },
  ]

  const logResolvedOptions: LogResolvedOption[] = [
    { label: t('robotExecutions.filters.options.errorReason.all'), value: undefined },
    { label: t('robotExecutions.filters.options.errorReason.yes'), value: true },
    { label: t('robotExecutions.filters.options.errorReason.no'), value: false },
  ];

  const fetchDepartments = async () => {
    setIsLoading(true);
    const response = await ScheduleService.getDepartamentsList();
    if (response && !response.hasErrors) {
      setDepartments(response.data);
    }
    setIsLoading(false);
  }

  const fetchMachines = async () => {
    setIsLoading(true);
    const response = localFilters?.departmentId
      ? await ScheduleService.getMachineListByDepartmentId(localFilters.departmentId)
      : await ScheduleService.getMachines();

    if (response && !response.hasErrors) {
      setMachines(response.data);
    }
    setIsLoading(false);
  };

  const fetchSteps = async () => {
    setIsLoading(true);
    const response = localFilters?.machineId
      ? await ScheduleService.getRobotListByMachineId(localFilters.machineId)
      : localFilters?.departmentId
        ? await ScheduleService.getRobotListByDepartmentId(localFilters.departmentId)
        : await ProcessStepsService.getDataList();

    if (response && !response.hasErrors) {
      setSteps(response.data);
    }
    setIsLoading(false);
  };

  const handleFilterChange = () => {
    setFilters({ ...localFilters });
    navigate(`/processes/${APP_ROUTES.robotExecutions.list}`);
  };

  const updateFilterButtonName = () => {
    const filterButtonName = [
      logStatusOptions.find(option => option.value === filters?.status)?.label,
      departments.find(option => option.id === filters?.departmentId)?.name,
      machines.find(option => option.id === filters?.machineId)?.name,
      steps.find(option => option.id === filters?.stepId)?.name,
    ]
      .filter(Boolean)
      .join(' | ');

    setFilterButtonName(filterButtonName);
  };

  const clearDepartmentFilter = () => {
    setDepartments([]);
    setMachines([]);
    setSteps([]);
    setLocalFilters(prevFilters => ({ ...prevFilters, departmentId: undefined, machineId: undefined, stepId: undefined }));
  };

  const clearMachineFilter = () => {
    setMachines([]);
    setSteps([]);
    setLocalFilters(prevFilters => ({ ...prevFilters, machineId: undefined, stepId: undefined }));
  };

  const clearStepFilter = () => {
    setSteps([]);
    setLocalFilters(prevFilters => ({ ...prevFilters, stepId: undefined }));
  };

  useEffect(() => {
    setLocalFilters(filters);
    updateFilterButtonName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <div className='me-4'>
      <button
        type='button'
        className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <ITSVG path={filterIcon} className='svg-icon-5 svg-icon-gray-500 me-1' />
        {filterButtonName === '' ? t('generalMessages.filter') : filterButtonName}
      </button>

      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{t('generalMessages.filterOptions')}</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
          <div className='mb-10'>
            <CustomAutocomplete
              id="logStatus"
              label={t('robotExecutions.filters.status')}
              options={logStatusOptions}
              value={logStatusOptions.find(logStatus => logStatus.value === localFilters?.status) || null}
              onChange={(e, value) => {
                setLocalFilters(prevFilters => ({ ...prevFilters, status: value?.value, errorReason: undefined }));
              }}
              isLoading={isLoading}
            />
          </div>

          {localFilters?.status === AgentLogStatus.Error && (
            <div className='mb-10'>
              <CustomAutocomplete
                id="logResolved"
                label={t('robotExecutions.filters.errorReason')}
                options={logResolvedOptions}
                value={logResolvedOptions.find(logResolvedValue => logResolvedValue.value === localFilters?.errorReason) || null}
                onChange={(e, value) => {
                  setLocalFilters(prevFilters => ({ ...prevFilters, errorReason: value?.value }));
                }}
                isLoading={isLoading}
              />
            </div>
          )}

          <div className='mb-10'>
            <CustomAutocomplete
              id="departments"
              label={t('robotExecutions.filters.department')}
              options={departments}
              value={departments.find(department => department.id === localFilters?.departmentId) || null}
              getOptionLabel={(option) => option?.name || ''}
              onChange={(e, value) => {
                if (!value) {
                  clearDepartmentFilter();
                } else {
                  clearMachineFilter();
                  clearStepFilter();
                  setLocalFilters(prevFilters => ({ ...prevFilters, departmentId: value?.id }));
                }
              }}
              onOpen={() => {
                if (departments.length === 0) {
                  fetchDepartments();
                }
              }}
              isLoading={isLoading}
            />
          </div>

          <div className='mb-10'>
            <CustomAutocomplete
              id="machines"
              label={t('robotExecutions.filters.machine')}
              options={machines}
              value={machines.find(machine => machine.id === localFilters?.machineId) || null}
              getOptionLabel={(option) => option?.name || ''}
              onChange={(e, value) => {
                if (!value) {
                  clearMachineFilter();
                } else {
                  clearStepFilter();
                  setLocalFilters(prevFilters => ({ ...prevFilters, machineId: value?.id }));
                }
              }}
              onOpen={() => {
                if (machines.length === 0) {
                  fetchMachines();
                }
              }}
              isLoading={isLoading}
              disabled={!helper.HasAccess([RolesDictionary.Process.Schedule_Read])}
            />
          </div>

          <div className='mb-10'>
            <CustomAutocomplete
              id="steps"
              label={t('robotExecutions.filters.step')}
              options={steps}
              value={steps.find(step => step.id === localFilters?.stepId) || null}
              getOptionLabel={(option) => option?.name || ''}
              onChange={(e, value) => {
                if (!value) {
                  clearStepFilter();
                } else {
                  setLocalFilters(prevFilters => ({ ...prevFilters, stepId: value?.id }));
                }
              }}
              onOpen={() => {
                if (steps.length === 0) {
                  fetchSteps();
                }
              }}
              isLoading={isLoading}
            />
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
            >
              {t('generalMessages.cancel')}
            </button>
            <button
              type='button'
              onClick={handleFilterChange}
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              disabled={isLoading}
            >
              {t('generalMessages.apply')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
