import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';
import filterIcon from 'assets/images/icons/filter.svg';
import ITSVG from 'common/helpers/ITSVG';
import { StatusFilter } from 'common/types/status-filter.model';

interface Props {
  handleFilterData: (statusFilter: StatusFilter) => void;
}

export function StatusFilterDropdown({ handleFilterData }: Props) {
  const { t } = useTranslation();
  const [statusFilter, setStatusFilter] = useState<StatusFilter>(StatusFilter.Active);

  const handleFilterSubmit = () => {
    handleFilterData(statusFilter);
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <ITSVG path={filterIcon} className='svg-icon-5 svg-icon-gray-500 me-1' />
        {t('generalMessages.filter')}
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{t('generalMessages.filterOptions')}</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>{t('generalMessages.status')}</label>
            <Autocomplete
              id='status'
              options={[
                { label: t('generalMessages.all'), value: StatusFilter.All },
                { label: t('generalMessages.active'), value: StatusFilter.Active },
                { label: t('generalMessages.inactive'), value: StatusFilter.Inactive }
              ]}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              defaultValue={{ label: t('generalMessages.active'), value: StatusFilter.Active }}
              onChange={(e, value) => {
                e.stopPropagation();
                if (!value) {
                  setStatusFilter(StatusFilter.Active);
                } else {
                  setStatusFilter(value.value);
                }
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  placeholder={`${t('generalMessages.select')}`}
                />
              }
              clearIcon={false}
              noOptionsText={t('generalMessages.noOptions')}
            />
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
            >
              {t('generalMessages.cancel')}
            </button>
            <button
              type='submit'
              onClick={handleFilterSubmit}
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
            >
              {t('generalMessages.apply')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
