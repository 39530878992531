import { TopBarModel } from '../model/Topbar.model';
import { http } from 'common/helpers/axios/axios-helper';
import { axiosInstance } from 'common/helpers/axios/axios-instance';

const basePath = '/Me';

async function getUserInfo() {
  return await axiosInstance.get(`${basePath}`);
}

async function getUserAvatar() {
  return await axiosInstance.get(`${basePath}/avatar`, { responseType: 'arraybuffer' });
}

async function putUserLanguage(data: TopBarModel) {
  return await http.put(`${basePath}/language`, data);
}

export const TopbarService = {
  getUserInfo,
  getUserAvatar,
  putUserLanguage,
};
