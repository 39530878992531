/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import usFlag from 'assets/images/flags/us.svg'
import brFlag from 'assets/images/flags/brazil.svg'
import spFlag from 'assets/images/flags/spain.svg'
import i18n from 'i18n'
import { useEffect, useState } from 'react'
import { TokenHelper, Language } from 'common/helpers/auth/token-helper'
import { TopBarModel } from '../model/Topbar.model'
import { TopbarService } from '../services/Topbar.service'
import { useTranslation } from 'react-i18next'

export function Languages() {

  const { t } = useTranslation();

  const helper = new TokenHelper();

  // const [currentLanguageI18n, setCurrentLanguageI18n] = useState(i18n.language);
  const [currentLanguageAPI, setCurrentLanguageAPI] = useState<Language>(helper.GetLanguage());

  const onSubmitButton = (value: Language) => {
    const putBody: TopBarModel = {
      language: value,
    };

    TopbarService.putUserLanguage(putBody);
  };

  const onLanguageChangeAPI = (language: Language, languageAPI: Language) => {
    setCurrentLanguageAPI(languageAPI);
    onLanguageChange(language);
  };

  const onLanguageChange = (language: string) => {
    // setCurrentLanguageI18n(language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    helper.SetLanguage(currentLanguageAPI);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguageAPI]);

  const languageDictionary = {
    flag: {
      'us': usFlag,
      'br': brFlag,
      'es': spFlag
    },
    name: {
      'us': 'English',
      'br': 'Português',
      'es': 'Español'
    }
  }

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {t('topbar.userMenu.language')}

          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            { languageDictionary.name[currentLanguageAPI] }
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={languageDictionary.flag[currentLanguageAPI]}
              alt='United States flag'
            />
          </span>

        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        <div
          className='menu-item px-3'
          onClick={() => onLanguageChangeAPI('us', 'us')}
        >
          <a
            href='#'
            className={clsx('menu-link d-flex px-5')}
            onClick={() => onSubmitButton('us')}
          >
            <span className='symbol symbol-20px me-4'>
              <img src={usFlag} alt='United States flag' />
            </span>
            English
          </a>
        </div>

        <div
          className='menu-item px-3'
          onClick={() => onLanguageChangeAPI('br', 'br')}
        >
          <a
            href='#'
            className={clsx('menu-link d-flex px-5')}
            onClick={() => onSubmitButton('br')}
          >
            <span className='symbol symbol-20px me-4'>
              <img src={brFlag} alt='Brazil flag' />
            </span>
            Português
          </a>
        </div>

        <div
          className='menu-item px-3'
          onClick={() => onLanguageChangeAPI('es', 'es')}
        >
          <a
            href='#'
            className={clsx('menu-link d-flex px-5')}
            onClick={() => onSubmitButton('es')}
          >
            <span className='symbol symbol-20px me-4'>
              <img src={spFlag} alt='Español flag' />
            </span>
            Español
          </a>
        </div>

      </div>
    </div>
  )
}