import { useTranslation } from "react-i18next";
import ITSVG from "common/helpers/ITSVG";
import filterIcon from 'assets/images/icons/filter.svg';
import { NextAndLastRunRequestModel } from "app/dashboard/model/dashboard.model";
import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

interface Props {
  handleFilterData: (request: NextAndLastRunRequestModel) => void;
}

interface TimeRangeOption {
  label: string;
  value: number;
}

export default function RunsFilterDropdown({ handleFilterData }: Props) {
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState<NextAndLastRunRequestModel>({ timeRange: 2 });

  const handleFilterSubmit = () => {
    handleFilterData(filterData);
  };

  const handleTimeRangeChange = (event: React.ChangeEvent<{}>, value: TimeRangeOption | null) => {
    event.stopPropagation();
    if (value) {
      setFilterData((prevData) => ({
        ...prevData,
        timeRange: value.value
      }));
    }
  };

  const timeRangeOptions: TimeRangeOption[] = [
    { label: t('Últimas 2 horas'), value: 2 },
    { label: t('Últimas 6 horas'), value: 6 },
    { label: t('Últimas 12 horas'), value: 12 },
    { label: t('Últimas 24 horas'), value: 24 },
  ];

  return (
    <>
      <button
        type='button'
        className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <ITSVG path={filterIcon} className='svg-icon-5 svg-icon-gray-500 me-1' />
        {t('generalMessages.filter')}
      </button>

      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{t('generalMessages.filterOptions')}</div>
        </div>
        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>{t('Período')}</label>
            <Autocomplete
              id='timeRange'
              options={timeRangeOptions}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              defaultValue={timeRangeOptions[0]}
              onChange={handleTimeRangeChange}
              renderInput={(params) =>
                <TextField
                  {...params}
                  placeholder={`${t('generalMessages.select')}`}
                />
              }
              noOptionsText={t('generalMessages.noOptions')}
              clearIcon={false}
            />
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
            >
              {t('generalMessages.cancel')}
            </button>
            <button
              type='submit'
              onClick={handleFilterSubmit}
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
            >
              {t('generalMessages.apply')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}