import { PagingOptionsModel } from "common/types/paging-options.model";

export function buildQueryString({ page, limit, sort, order }: PagingOptionsModel): string {
  const queryParams: string[] = [];

  if (page !== undefined) {
    queryParams.push(`_page=${page}`);
  }

  if (limit !== undefined) {
    queryParams.push(`_limit=${limit}`);
  }

  if (sort && order) {
    queryParams.push(`_sort=${sort}&_order=${order}`);
  }

  return queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
}

export function buildFilterString(filterParams: Record<string, string | number | boolean | undefined>): string {
  const filters: string[] = [];

  for (const [key, value] of Object.entries(filterParams)) {
    if (value !== undefined && value !== "") {
      filters.push(`${key}=${value}`);
    }
  }

  return filters.length > 0 ? `&${filters.join("&")}` : "";
}