import { useEffect } from 'react';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { AuthenticationType } from './childs/AuthTypeSettings';
import { SecuritySettings } from './childs/SecuritySettings';
import { ActiveDirectorySettings } from './childs/ActiveDirectorySettings';
import { SMTPSettings } from './childs/SMTPSettings';
import { UploadSettings } from './childs/UploadSettings';
import { Presets } from './childs/Presets';
import { useLoading } from '../../../common/loading/loading.hook';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { MasterLayout, MainLayout, ToolbarLayout, ToolbarLayoutLeft, ToolbarLayoutRight } from 'common/imports/content-layout';

export function GeneralSettings() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { isLoading } = useLoading();
  const { t } = useTranslation();

  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        isLoading(true);
      } catch (err) {
        toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
        isLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MasterLayout>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight></ToolbarLayoutRight>
        </ToolbarLayout>
        <MainLayout>
          <SecuritySettings></SecuritySettings>
          <AuthenticationType></AuthenticationType>
          <ActiveDirectorySettings></ActiveDirectorySettings>
          <SMTPSettings></SMTPSettings>
          <UploadSettings></UploadSettings>
          <Presets></Presets>
        </MainLayout>
      </MasterLayout>
    </>
  );
}
