import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ITSVG from "common/helpers/ITSVG";
import filterIcon from 'assets/images/icons/filter.svg';
import DashboardContext, { DashboardFilters } from "../context/dashboard.context";
import EnumDropdown from "app/shared/ui/components/EnumDropDown";
import { AgentLogStatus, AgentLogStatusLabel } from "../model/dashboard.model";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { DepartamentsModel } from "app/departaments/model/departaments.model";
import { ScheduleService } from "app/schedule/services/schedule.service";

export default function DashboardFilterDropdown() {
    const { t } = useTranslation();
    const { dashboardFilters, setFilter } = useContext(DashboardContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterButtonName, setFilterButtonName] = useState<string>('');
    const [statusValue, setStatusValue] = useState<AgentLogStatus>();
    const [departments, setDepartaments] = useState<DepartamentsModel[]>([]);
    const [departmentValue, setDepartmentValue] = useState<DepartamentsModel>();

    const fetchDepartments = async () => {
        setIsLoading(true);
        const response = await ScheduleService.getDepartamentsList();
        if (response && !response.hasErrors) {
            setDepartaments(response.data);
        }
        setIsLoading(false);
    }

    const clearDepartmentFilter = () => {
        setDepartaments([]);
        setDepartmentValue(undefined);
    }

    const handleApplyFilterClick = () => {
        const filters: DashboardFilters = {
            status: statusValue,
            department: departmentValue
        }
        setFilter(filters);
    }

    useEffect(() => {
        const filters = [];

        if (dashboardFilters.status) {
            filters.push(`${AgentLogStatusLabel[dashboardFilters.status]}`)
        }

        if (dashboardFilters.department) {
            filters.push(`${departments.find(d => d.id === dashboardFilters.department?.id)?.name}`)
        }

        setFilterButtonName(filters.join(' | '));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardFilters]);

    return <div className='me-4'>
        <button
            type='button'
            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
        >
            <ITSVG path={filterIcon} className='svg-icon-5 svg-icon-gray-500 me-1' />
            {filterButtonName === '' ? t('generalMessages.filter') : t(`stepLog.table.status.${filterButtonName}`)}
        </button>
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>{t('generalMessages.filterOptions')}</div>
            </div>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5'>
                <div className='mb-10'>
                    <EnumDropdown
                        enum={AgentLogStatus}
                        enumName={'AgentLogStatus'}
                        label={t('filterDropdown.status.label')}
                        placeholderText={`${t('generalMessages.select')}`}
                        value={dashboardFilters.status}
                        onChange={(val) => setStatusValue(val)}
                    />
                </div>
                <div className='mb-10'>
                    <label className='form-label fw-bold'>{t('filterDropdown.department.label')}</label>
                    <div>
                        <Autocomplete
                            id='departments'
                            options={departments}
                            getOptionLabel={option => option.name ? option.name : ''}
                            onChange={(e, value) => {
                                e.stopPropagation();
                                if (!value) {
                                    clearDepartmentFilter();
                                } else {
                                    setDepartmentValue(value);
                                }
                            }}
                            onOpen={() => {
                                if (departments.length === 0) {
                                    fetchDepartments();
                                }
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    placeholder={`${t('generalMessages.select')}`}
                                />
                            }
                            loading={isLoading}
                            loadingText={<div className='text-center'><CircularProgress size={14} /></div>}
                            noOptionsText={t('generalMessages.noOptions')}
                        />
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-white btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                    >
                        {t('generalMessages.cancel')}
                    </button>
                    <button
                        type='button'
                        onClick={handleApplyFilterClick}
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        disabled={false}
                    >
                        {t('generalMessages.apply')}
                    </button>
                </div>
            </div>
        </div>
    </div>
}