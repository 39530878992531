
interface AlertTypeIconProps {
  notificationTypeName: "Success" | "Error" | "Warning" | "Info";
  isLight?: boolean;
}

const defaultProps: AlertTypeIconProps = {
  isLight: false,
  notificationTypeName: "Info"
}

export function AlertTypeIcon(props: AlertTypeIconProps = defaultProps) {    
  const notificationTypeParse = {
    "Success": (<><span className={`symbol-label bg-${props.isLight ? 'light-' : ''}success`}><i className="fa-solid fa-circle-check text-white"></i></span></>),
    "Error": (<><span className={`symbol-label bg-${props.isLight ? 'light-' : ''}danger`}><i className="fa-solid fa-triangle-exclamation text-white"></i></span></>),
    "Warning": (<><span className={`symbol-label bg-${props.isLight ? 'light-' : ''}warning`}><i className="fa-solid fa-exclamation text-white"></i></span></>),
    "Info": (<><span className={`symbol-label bg-${props.isLight ? 'light-' : ''}info`}><i className="fa-solid fa-circle-info text-white"></i></span></>),
  };

  return (notificationTypeParse[props.notificationTypeName]);
}