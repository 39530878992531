/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AgentLogStatus, MachineAndStepStatisticsModel as Model } from 'app/dashboard/model/dashboard.model';
import LoadingSpinner from 'common/partials/LoadingSpinner';
import { DashboardService as Service } from '../../services/dashboard.service'
import i18next from 'i18next';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import DashboardContext from 'app/dashboard/context/dashboard.context';

type Props = {
  className: string;
}

export function StepsStatistics(props: Props) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();
  const { hubConnection, dashboardFilters, setFilter } = useContext(DashboardContext);
  const [isLoading, setIsLoading] = useState(false);

  const initialData: Model = {
    totalQuantity: 0,
    active: 0,
    inactive: 0,
    percentActive: 0,
    percentInactive: 0
  }

  const [stepsStatistics, setStepsStatistics] = useState<Model>(initialData);
  const [message, setMessage] = useState('');

  const fetchStepStatistics = async () => {
    setIsLoading(true);
    const response = await Service.getStepStatistics();
    if (response && !response.hasErrors) {
      setStepsStatistics(response.data);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const receiveMessages = async () => {
      if (hubConnection) {
        hubConnection.on("GetStepStatus", message => setMessage(message))
      }
    }
    receiveMessages();

    return (() => {
      if (hubConnection) {
        hubConnection.off("GetStepStatus");
      }
    })
  }, [hubConnection]);

  useEffect(() => {
    fetchStepStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <div className={`card ${props.className} ${dashboardFilters.status === AgentLogStatus.Running ? "shadow border border-3 border-primary" : ""} cursor-pointer`}
      onClick={() => setFilter({ ...dashboardFilters, status: dashboardFilters.status === AgentLogStatus.Running ? undefined : AgentLogStatus.Running })}
    >
      <div className="card-body">
        <div className=' d-flex justify-content-between align-items-start'>
          <div className="d-flex flex-column">
            {
              isLoading
                ? <LoadingSpinner loading={isLoading} align="start" />
                : <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{stepsStatistics.active}</span>
            }
            <div className="m-0">
              <span className="fw-semibold fs-6 text-gray-400">{t('stepStatistics.title')}</span>
            </div>
          </div>
          <div className="m-0">
            <i className={`fa-solid fa-robot fs-2x ${stepsStatistics.active > 0 ? 'text-primary' : undefined}`}></i>
          </div>
        </div>
        <div className="d-flex align-items-center flex-column mt-3 w-100">
          {
            isLoading
              ? <LoadingSpinner loading={isLoading} />
              : (
                <>
                  <div className="d-flex justify-content-between fw-bold fs-6 text-dark opacity-50 w-100 mt-auto mb-2">
                    <span>{stepsStatistics.active + '/' + stepsStatistics.totalQuantity}</span>
                    <span>{stepsStatistics.percentActive + '%'}</span>
                  </div>
                  <div className="progress h-8px mx-3 w-100 bg-light-primary rounded">
                    <div className="progress-bar bg-primary rounded h-8px" role="progressbar" style={{ width: `${stepsStatistics.percentActive}%` }}></div>
                  </div>
                </>
              )}
        </div>
      </div>
    </div>
  )
}