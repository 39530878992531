import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { UploadDirectorySettingsModel as Model } from '../../model/general-settings.model';
import { GeneralSettingsService as Service } from '../../services/general-settings.service';
import * as Yup from 'yup';
import { useFormik } from 'formik';

interface Props { }

export function UploadSettings(props: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = Yup.object().shape({
    uploadDirectoryTemp: Yup.string().required(t('validation.requiredField')),
  });

  let initialData: Model = {
    uploadDirectoryTemp: '',
    uploadMaxSizeInMb: 0,
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: Model = {
          uploadDirectoryTemp: values.uploadDirectoryTemp,
          uploadMaxSizeInMb: values.uploadMaxSizeInMb,
        };

        const result = await Service.putUploadSettings(body);
        if (result && !result?.hasErrors) {
          toast.success(`${t('crud.update.successMessage')}`);
        } else {
          toast.error(`${t(`error.${result?.errorCode}`)}`);
        }
      } catch (err) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await Service.getUploadSettings();
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      } catch (err) {
        toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form">
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_upload_settings"
            aria-expanded="true"
            aria-controls="kt_upload_settings"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{t('generalSettings.upload.title')}</h3>
            </div>
          </div>

          <div id="kt_upload_settings" className="collapse show">
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('generalSettings.upload.tempFolder')}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('generalSettings.upload.tempFolder')}
                        {...formik.getFieldProps('uploadDirectoryTemp')}
                      />
                      {formik.touched.uploadDirectoryTemp && formik.errors.uploadDirectoryTemp && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.uploadDirectoryTemp}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('generalSettings.upload.maxsize')}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="number"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('generalSettings.upload.maxsize')}
                        {...formik.getFieldProps('uploadMaxSizeInMb')}
                      />
                      {formik.touched.uploadMaxSizeInMb && formik.errors.uploadMaxSizeInMb && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.uploadMaxSizeInMb}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="reset" className="btn btn-white btn-active-light-primary me-2">{t('crud.create.buttonCancel')}</button>
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {!isLoading && t('crud.create.buttonSave')}
                {isLoading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    {t('crud.common.wait')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
