export const itLabTableStyle = {
  head: {
    style: {
      cursor: 'pointer',
      color: '#a1a5b7',
      fontWeight: '700',
      fontSize: '13px',
    },
  },
  header: {
    style: {
      paddingLeft: '0px',
    },
  },
  contextMenu: {
    style: {
      fontSize: '13px',
      '&:nth-of-type(2)': {
        '& > div': {
          fontWeight: '700',
        },
      },
    },
    activeStyle: {},
  },
  headCells: {
    style: {
      padding: '8px 0px'
    },
  },
  rows: {
    style: {
      minHeight: '70px',
      color: 'rgb(126, 130, 153)',
      fontWeight: '600',
      fontSize: '14px',
    },
    highlightOnHoverStyle: {
      color: '#3F4254',
    },
  },
  cells: {
    style: {
      padding: '8px 0px'
    },
  },
  pagination: {
    style: {
      fontSize: '1.075rem',
      fontWeight: '500',
    },
  },
};
