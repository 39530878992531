/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLayout } from "../core"

export function Footer() {

  const {classes} = useLayout()

  function openInNewTab(url: string) {
    window.open(url, '_blank')
  }

  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>

      <div className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}>

        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a onClick={() => openInNewTab('https://www.itlab.com.br')} className='text-gray-800 text-hover-primary cursor-pointer'>
            IT Lab
          </a>
        </div>

      </div>
      
    </div>
  )
}
