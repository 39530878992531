import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import closeModalIcon from 'assets/images/icons/close.svg';
import LoadingSpinner from 'common/partials/LoadingSpinner';
import ITSVG from 'common/helpers/ITSVG';
import { formatLocalizedDateTime } from 'common/helpers/dateUtils';
import { StepLogModel, StepLogActionByStepPostModel } from 'app/dashboard/model/dashboard.model';
import { AvailableMachinesRequestViewModel, MachineAvailableNowViewModel } from 'app/machine/model/machine.model';
import Tooltip from '@mui/material/Tooltip';
import { ScheduleService } from 'app/schedule/services/schedule.service';
import { HubConnection } from '@microsoft/signalr';

interface ExecutionLogAvailableMachinesModalProps {
  open: boolean;
  onClose: () => void;
  startUnscheduledStep: (body: StepLogActionByStepPostModel) => void;
  stepExecutionData: StepLogModel | null;
  hubConnection?: HubConnection | null;
}

export default function ExecutionLogAvailableMachinesModal({ open, onClose, stepExecutionData, startUnscheduledStep, hubConnection }: ExecutionLogAvailableMachinesModalProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availableMachines, setAvailableMachines] = useState<MachineAvailableNowViewModel[]>([]);
  const [selectedMachineIds, setSelectedMachineIds] = useState<number[]>([]);
  const [currentTime, setCurrentTime] = useState<moment.Moment>(moment.utc().add(3, 'minutes'));
  const [updateConnectionMessage, setUpdateConnectionMessage] = useState<string>("");

  const fetchMachines = async () => {
    if (stepExecutionData?.processId && stepExecutionData.processId !== null) {
      setIsLoading(true);
      try {
        const requestBody: AvailableMachinesRequestViewModel = {
          startDate: moment.utc().add(3, 'minute').toString(),
          endDate: moment.utc().add(3, 'minute').toString(),
          processId: stepExecutionData.processId
        };

        const response = await ScheduleService.getAvailableMachinesNowList(requestBody);
        if (response && !response.hasErrors) {
          setAvailableMachines(response.data);
        }
      } catch (error) {
        toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchMachines();

    return (() => {
      setAvailableMachines([]);
      setSelectedMachineIds([]);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepExecutionData, updateConnectionMessage]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment.utc().add(3, 'minutes'));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleMachineSelect = (machineId: number) => {
    const isSelected = selectedMachineIds.includes(machineId);
    setSelectedMachineIds(prev => (isSelected ? prev.filter(id => id !== machineId) : [...prev, machineId]));
  };

  const onConfirmModal = () => {
    if (!stepExecutionData?.isScheduled && stepExecutionData?.stepId) {
      const body: StepLogActionByStepPostModel = {
        stepId: stepExecutionData.stepId,
        machineIds: selectedMachineIds,
      }
      startUnscheduledStep(body);
    }
    onClose();
  }

  useEffect(() => {
    const receiveMessages = async () => {
      hubConnection?.on("UpdateConnection", message => setUpdateConnectionMessage(message));
    }

    receiveMessages();

    return (() => {
      hubConnection?.off("UpdateConnection");
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubConnection]);

  return (
    <Modal
      show={open}
      onHide={onClose}
      dialogClassName='modal-dialog-centered mw-500px h-auto'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3>{t('stepLog.availableMachinesModal.title')}</h3>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={onClose}>
            <ITSVG path={closeModalIcon} className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body d-grid gap-8'>
          <div className='row'>
            <h4>{t('stepLog.availableMachinesModal.details.robot')}</h4>
            <div className='fs-6 fw-bold'>
              {stepExecutionData?.name}
            </div>
          </div>
          <div className='row'>
            <h4>{t('stepLog.availableMachinesModal.details.process')}</h4>
            <div className='fs-6 fw-bold'>
              {stepExecutionData?.processName}
            </div>
          </div>
          <div className='row'>
            <h4>{t('stepLog.availableMachinesModal.details.department')}</h4>
            <div className='fs-6 fw-bold'>
              {stepExecutionData?.department}
            </div>
          </div>
          <div className='row'>
            <h4>Início</h4>
            <div className='fs-6 fw-bold'>
              <div>{formatLocalizedDateTime(currentTime.toString(), 'LLLL')}</div>
            </div>
          </div>
          <div className='row'>
            <h4>{t('stepLog.availableMachinesModal.details.availableMachines')}</h4>
            {isLoading
              ? <LoadingSpinner loading={isLoading} />
              : availableMachines.length === 0
                ? <div>{t('stepLog.availableMachinesModal.noMachinesFound')}</div>
                : availableMachines.map((availableMachine: MachineAvailableNowViewModel, index: number) => (
                  <div key={index} className='mb-3'>
                    <div className='d-flex align-items-center'>
                      <label className='form-check form-check-custom form-check-solid me-3'>
                        <input
                          className='form-check-input h-20px w-20px'
                          type='checkbox'
                          name='machineIds'
                          value={availableMachine.id}
                          id={availableMachine.id.toString()}
                          checked={selectedMachineIds.includes(availableMachine.id)}
                          onChange={() => handleMachineSelect(availableMachine.id)}
                          disabled={!availableMachine.isUp}
                        />
                      </label>
                      <Tooltip
                        title={!availableMachine.isUp ? t('stepLog.availableMachinesModal.inactiveMachine') : ''}
                        disableHoverListener={availableMachine.isUp}
                      >
                        <label
                          className={`form-label cursor-pointer mb-0 ${!availableMachine.isUp ? 'text-muted' : ''}`}
                          htmlFor={availableMachine.id.toString()}
                        >
                          {availableMachine.name}
                        </label>
                      </Tooltip>
                    </div>
                  </div>
                ))
            }
          </div>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-active-light-primary' onClick={onClose}>
            {t('crud.delete.buttonCancel')}
          </button>
          <button type='button' className='btn btn-primary' onClick={onConfirmModal} disabled={!selectedMachineIds.length}>
            {t('generalMessages.execute')}
          </button>
        </div>
      </div>
    </Modal>
  )
}