import { useContext, useState } from 'react';
import { AvatarContext } from './avatar.context';
import { AvatarContextModel } from './avatar.model';

export function useAvatarState(): AvatarContextModel {
  const [avatar, setAvatar] = useState(false);

  return { avatar, setAvatar };
}

export function useAvatar(): AvatarContextModel {
  return useContext(AvatarContext);
}
