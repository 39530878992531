import i18next from "i18next";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DataTable, { TableColumn } from "react-data-table-component";
import { Tooltip } from "@mui/material";
import SaveButton from "common/partials/SaveButton";
import { tableHelper } from "common/helpers/table/table-helper";
import { itLabTableStyle } from "common/helpers/table/table-style";
import { ProcessesService as Service } from "../../../../services/processes.service";
import { MachineSelectorModel, ProcessMachineUpdateViewModel } from "../model/machine-selector.model";
import ScheduledEventsModal from "./ScheduledEventsModal";

export function MachineSelectorComponent(props: { processId: number }) {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [machineLeftData, setMachineLeftData] = useState<MachineSelectorModel[]>([]);
  const [machineRightData, setMachineRightData] = useState<MachineSelectorModel[]>([]);
  const [selectedLeftRows, setSelectedLeftRows] = useState<MachineSelectorModel[]>([]);
  const [selectedRightRows, setSelectedRightRows] = useState<MachineSelectorModel[]>([]);
  const [showModal, setShowModal] = useState(false);

  const leftColumns: TableColumn<MachineSelectorModel>[] = [
    {
      name: t("generalMessages.selectAll"),
      selector: (row) => row.name
    }
  ];

  const rightColumns: TableColumn<MachineSelectorModel>[] = [
    {
      name: t("generalMessages.selectAll"),
      cell: (row) => (
        <Tooltip title={row.hasEvent ? t("machineSelector.hasEventMessage") : ""} onClick={() => handleRightRowClicked(row)}>
          <span>{row.name}</span>
        </Tooltip>
      )
    },
  ];

  function getDifference(array1: MachineSelectorModel[], array2: MachineSelectorModel[]) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.id === object2.id;
      });
    });
  }

  const handleLeftRowSelected = useCallback((state: any) => {
    setSelectedLeftRows(state.selectedRows as MachineSelectorModel[]);
  }, []);

  const handleRightRowSelected = useCallback((state: any) => {
    setSelectedRightRows(state.selectedRows);
  }, []);

  const handleRightRowClicked = (row: MachineSelectorModel) => {
    if (row.hasEvent) {
      setShowModal(true);
    }
  };

  function clearSelected() {
    setSelectedRightRows([]);
    setSelectedLeftRows([]);
  }

  function handleAddButton() {
    const array = getDifference(machineLeftData, selectedLeftRows);
    setMachineLeftData(array);
    setMachineRightData((previous) => [...previous, ...selectedLeftRows]);
    clearSelected();
    setToggleCleared(true);
  }

  function handleRemoveButton() {
    const a1 = getDifference(machineRightData, selectedRightRows);
    setMachineRightData(a1);
    setMachineLeftData((previous) => [...previous, ...selectedRightRows]);
    clearSelected();
    setToggleCleared(true);
  }

  async function fetchInitialData() {
    setToggleCleared(true);
    if (props.processId) {
      const allMachines = await Service.getProcessAndMachines(props.processId ?? 0);
      if (allMachines && !allMachines.hasErrors) {
        const availableMachines = allMachines.data.machines.filter((machine) => !machine.inProcess);
        const selectedMachines = allMachines.data.machines.filter((machine) => machine.inProcess);

        setMachineLeftData(availableMachines);
        setMachineRightData(selectedMachines);
      } else {
        toast.error(`${t("crud.read.errorMessage")}`);
      }
    }
  }

  async function handleSaveButtonClick() {
    setIsLoading(true);
    let machineIds: number[] = [];

    machineRightData.forEach((machine) => {
      machineIds.push(machine.id);
    });

    const data: ProcessMachineUpdateViewModel = {
      processId: props.processId,
      machineIds: machineIds
    };

    const response = await Service.updateProcessMachines(data);
    if (response && !response.hasErrors) {
      toast.success(`${t("crud.update.successMessage")}`);
    } else {
      toast.error(`${t(`error.${response?.errorCode}`)}`);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setToggleCleared(false);
  }, [toggleCleared]);

  useEffect(() => {
    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-5">
              <div className="card w-100 shadow-none">
                <div className="pt-0">
                  <DataTable
                    title={<h4 className="mb-10">{t("machineSelector.availableItems")}</h4>}
                    className="mh-500px overflow-auto"
                    columns={leftColumns}
                    data={machineLeftData}
                    noDataComponent={tableHelper.getNoDataComponent()}
                    contextMessage={tableHelper.getContextMessage()}
                    selectableRows
                    highlightOnHover
                    striped
                    dense
                    onSelectedRowsChange={handleLeftRowSelected}
                    clearSelectedRows={toggleCleared}
                    customStyles={itLabTableStyle}
                  />
                </div>
              </div>
            </div>
            <div className="col-2 text-center pt-20">
              <div className="row">
                <div className="col-12">&nbsp;</div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className={`btn ${selectedLeftRows.length > 0 ? 'btn-primary' : 'btn-light'} mt-2`}
                    onClick={handleAddButton}
                  >
                    <i className="fa-solid fa-angles-right" />
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className={`btn ${selectedRightRows.length > 0 ? 'btn-primary' : 'btn-light'} mt-2`}
                    onClick={handleRemoveButton}
                  >
                    <i className="fa-solid fa-angles-left" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="card w-100 py-4 shadow-none">
                <div className="pt-0">
                  <DataTable
                    title={<h4 className="mb-10">{t("machineSelector.selectedItems")}</h4>}
                    className="mh-500px overflow-auto"
                    columns={rightColumns}
                    data={machineRightData}
                    noDataComponent={tableHelper.getNoDataComponent()}
                    contextMessage={tableHelper.getContextMessage()}
                    selectableRows
                    selectableRowDisabled={(row) => row.hasEvent}
                    highlightOnHover
                    striped
                    dense
                    onSelectedRowsChange={handleRightRowSelected}
                    onRowClicked={handleRightRowClicked}
                    clearSelectedRows={toggleCleared}
                    customStyles={itLabTableStyle}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <SaveButton type="button" loading={isLoading} onClick={handleSaveButtonClick} />
        </div>
      </div>

      <ScheduledEventsModal open={showModal} onClose={() => setShowModal(false)} />
    </>
  );
}