import i18next from 'i18next';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { Modal } from "react-bootstrap"
import { Box, Chip, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { ChangeEvent, useEffect, useState } from "react";
import { DepartmentModel, NotificationModel, NotificationPostViewModel } from '../model/notification.model';
import { NotificationService as Service } from '../services/notification.service';
import { useTranslation } from 'react-i18next';
import { AlertTypeIcon } from 'common/helpers/alerts/alert-type-icon';
import { toast } from 'react-toastify';
import ProtectedComponent from 'common/partials/ProtectedComponent';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from 'common/partials/DeleteModal';
import { BaseHttpResponse } from 'common/interfaces/base-http-response.model';

interface NotificationAddProps {
  notification?: NotificationModel;
  IsOpen: boolean;
  Close: () => void;
}

export function NotificationAdd(props: NotificationAddProps) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const navigate  = useNavigate();
  const [departaments, setDepartaments] = useState<DepartmentModel[]>([]);
  const [toDepartments, setToDepartments] = useState<string[]>(props.notification ? props.notification.departments.map(x => x.id.toString()) : []);
  const [message, setMessage] = useState(props.notification ? props.notification.preview : "");
  const [title, setTitle] = useState(props.notification ? props.notification.title : "");
  const [pinned, setPinned] = useState(props.notification ? props.notification.pinned : false);
  const [type, setType] = useState<"Success" | "Error" | "Warning" | "Info">(props.notification ? props.notification.type : "Info");
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const modalColor = {
    "Success": "success",
    "Error": "danger",
    "Warning": "warning",
    "Info": "info"
  }

  const fetchData = async () => {
    try {
      const response = await Service.getDepartments();
      if (response) {
        if (!response.hasErrors) {
          setDepartaments(response.data);
        }
      }
    } catch (error) {
      toast.error(t("notifications.errors.noResponse"))
    }
  }

  const departmentsHandleChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value } } = event;
    const selected = typeof value === 'string' ? value.split(',') : value.map(x => x.toString());
    if (selected.indexOf("-1") > -1) {
      setToDepartments(departaments.map((x: DepartmentModel) => x.id.toString()));
    } else {
      setToDepartments(selected);
    }
  };

  const typeHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    const checked = e.target.checked;
    if (checked && id.indexOf("success") > -1) setType("Success");
    if (checked && id.indexOf("error") > -1) setType("Error");
    if (checked && id.indexOf("warning") > -1) setType("Warning");
    if (checked && id.indexOf("info") > -1) setType("Info");
  };

  const disabledLoadingOrEdit = function () {
    if (loading) return true;
    if (props.notification !== undefined) return true;
    return false;
  }

  const saveData = async () => {
    if (toDepartments.length === 0) {
      toast.error(`${t("notifications.errors.departments")}`);
      return;
    }
    if (title.length === 0) {
      toast.error(`${t("notifications.errors.title")}`);
      return;
    }
    if (message.length === 0) {
      toast.error(`${t("notifications.errors.message")}`);
      return;
    }

    const request: NotificationPostViewModel = {
      content: message,
      notificationContentType: 'Text',
      notificationType: type,
      pinned: pinned,
      rawContent: message,
      title: title,
      toDepartmentIds: toDepartments.map(x => parseInt(x)),
      toUserIds: []
    };

    setLoading(true);
    if (props.notification) {
      const response = await Service.putNotification(props.notification.id, request);
      handleResult(response);
    } else {
      const response = await Service.postNotification(request);
      handleResult(response);
    }
    setLoading(false);
    props.Close();
  }

  const handleResult = (response: BaseHttpResponse<unknown> | undefined) => {
    if(response && !response.hasErrors){
      toast.success(t("notifications.add.success"));
    } else {
      toast.success(t("notifications.errors.noResponse"));
    }
  }
  
  const handleDelete = async () => {
    try {
      if(props.notification){
        setLoading(true);
        await Service.deleteNotification(props.notification);
        navigate("/notifications/center");
        setDeleteModal(false);
        props.Close();
        setLoading(false);
      }
    } catch (error) {
      toast.error(`${t("notifications.errors.noResponse")}`);
    }
  }

  useEffect(() => {
    fetchData();

    if (props.notification) {
      Service.markAsRead(props.notification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Modal
      show={props.IsOpen}
      dialogClassName="modal-dialog-centered mw-500px mw-lg-900px h-auto">
      <div className="card">
        <div className={`card-header bg-${modalColor[type]} d-flex align-items-center justify-content-between py-3`}>
          <h2 className="card-title m-0">{props.notification ? `${t("notifications.view.to")} ${props.notification.author}` : t("notifications.add.title")}</h2>
          <button type="button" onClick={props.Close} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="card-body p-0">
          <DeleteModal open={deleteModal} handleDelete={handleDelete} onClose={() => setDeleteModal(false)} size='sm' text='' type='delete' />
          <form id="kt_inbox_compose_form">
            <ProtectedComponent action='hide' role={[RolesDictionary.Notifications.Notification_Create]}>
              <div className="my-4 px-8 min-h-50px">
                <div className="text-gray-900 fw-bold w-150px">{`${t("notifications.add.department.title")}:`}</div>{" "}
                <div className='w-100 m-1'>
                  <FormControl variant="filled" sx={{ width: "100%" }} disabled={loading}>
                    <Select
                      multiple
                      required
                      name="department"
                      value={toDepartments}
                      onChange={departmentsHandleChange}
                      renderValue={(selected: string[]) => (
                        <Box sx={{ display: "flex", flexWrap: "nowrap", gap: 0.5, overflow: 'auto' }}>
                          {selected.map((value: any) => (
                            <Chip
                              className={`bg-${modalColor[type]}`}
                              key={value}
                              label={departaments.find(x => x.id.toString() === value)?.name}
                              clickable={true}
                              deleteIcon={<Cancel onMouseDown={(e: any) => e.stopPropagation()} />}
                            />
                          ))}
                        </Box>
                      )}>
                      <MenuItem
                        key={"Todos"}
                        value={"-1"}>
                        {t("notifications.add.department.all")}
                      </MenuItem>
                      {departaments.map((departament) => (
                        <MenuItem
                          key={departament.name}
                          value={departament.id.toString()}>
                          {departament.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </ProtectedComponent>
            <div className="my-4 px-8 min-h-50px">
              <div className="text-gray-900 fw-bold w-150px">{`${t("notifications.add.subject")}:`}</div>{" "}
              <div className='w-100 m-1'>
                <TextField
                  className="form-control form-control-lg form-control-solid border-0 px-4 min-h-45px"
                  onChange={(e) => setTitle(e.target.value)}
                  onBlur={(e) => setTitle(e.target.value)}
                  value={title}
                  disabled={disabledLoadingOrEdit()}>
                </TextField>
              </div>
            </div>
            <div className='border-bottom mb-2'></div>
            <div className="bg-transparent h-200px px-3 mb-4">
              <div className="form-floating mb-3 editor-input">
                <textarea
                  className="form-control text-body-tertiary message"
                  placeholder="Leave a comment here"
                  maxLength={255}
                  onChange={(e) => setMessage(e.target.value)}
                  onBlur={(e) => setMessage(e.target.value)}
                  value={message}
                  disabled={disabledLoadingOrEdit()}>
                </textarea>
                <label>{t("notifications.add.placeholder")}</label>
              </div>
            </div>
            <ProtectedComponent action='hide' role={[RolesDictionary.Notifications.Notification_Create]}>
              <div className="d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top">
                <div className='d-flex align-items-center'>
                  {
                    props.notification &&
                    (
                      <button type='button' className='border-0 p-0 bg-transparent' onClick={() => setDeleteModal(true)} style={{cursor: 'pointer !important'}}>
                        <div className='symbol symbol-35px'>
                          <label className="symbol-label bg-secondary">
                            <i className="fa-solid fa-trash-can text-danger"></i>
                          </label>
                        </div>
                      </button>
                    )
                  }
                  <div className="form-chack ms-2 symbol symbol-35px" title={pinned ? t("notifications.add.pinned.yes") : t("notifications.add.pinned.no")} style={{cursor: 'pointer !important'}}>
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="pinnedCheck"
                      autoComplete="off"
                      checked={pinned}
                      onChange={(e) => setPinned(e.target.checked)}
                      onBlur={(e) => setPinned(e.target.checked)}
                      disabled={loading}
                    />
                    {
                      pinned ? (
                        <label className="symbol-label bg-dark" htmlFor="pinnedCheck">
                          <i className="fa-solid fa-thumbtack text-white"></i>
                        </label>
                      ) : (
                        <label className="symbol-label bg-secondary" htmlFor="pinnedCheck">
                          <i className="fa-solid fa-thumbtack text-dark"></i>
                        </label>
                      )
                    }
                  </div>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="symbol symbol-35px border-0 bg-transparent"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      disabled={loading}
                      title={`${t("notifications.add.options.title")}`}>
                      <AlertTypeIcon notificationTypeName={type} />
                    </button>
                    <div className="dropdown-menu p-2">
                      <div className='form-chack mb-2'>
                        <input type="radio" className="btn-check" name="types" id="infoType" autoComplete="off" onChange={typeHandleChange} />
                        <label className='d-flex align-items-center' htmlFor="infoType">
                          <span className='symbol symbol-35px'>
                            <AlertTypeIcon notificationTypeName='Info' isLight={type !== 'Info'} />
                          </span>
                          &nbsp;
                          {`${t("notifications.add.options.types.info")}`}
                        </label>
                      </div>
                      <div className='form-chack mb-2'>
                        <input type="radio" className="btn-check" name="types" id="successType" autoComplete="off" onChange={typeHandleChange} />
                        <label className='d-flex align-items-center' htmlFor="successType">
                          <span className='symbol symbol-35px'>
                            <AlertTypeIcon notificationTypeName='Success' isLight={type !== 'Success'} />
                          </span>
                          &nbsp;
                          {`${t("notifications.add.options.types.success")}`}
                        </label>
                      </div>
                      <div className='form-chack mb-2'>
                        <input type="radio" className="btn-check" name="types" id="warningType" autoComplete="off" onChange={typeHandleChange} />
                        <label className='d-flex align-items-center' htmlFor="warningType">
                          <span className='symbol symbol-35px'>
                            <AlertTypeIcon notificationTypeName='Warning' isLight={type !== 'Warning'} />
                          </span>
                          &nbsp;
                          {`${t("notifications.add.options.types.warning")}`}
                        </label>
                      </div>
                      <div className='form-chack mb-2'>
                        <input type="radio" className="btn-check" name="types" id="errorType" autoComplete="off" onChange={typeHandleChange} />
                        <label className='d-flex align-items-center' htmlFor="errorType">
                          <span className='symbol symbol-35px'>
                            <AlertTypeIcon notificationTypeName='Error' isLight={type !== 'Error'} />
                          </span>
                          &nbsp;
                          {`${t("notifications.add.options.types.danger")}`}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center me-3'>
                  <div className="btn-group me-4">
                    <button type='button' className={`btn btn-${modalColor[type]} btn-sm fs-bold px-6`} onClick={saveData} disabled={loading}>
                      {
                        !loading ? (
                          <span className="indicator-label">{t("notifications.add.send")}</span>
                        ) : (
                          <>
                            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                            <span className="visually-hidden" role="status">Loading...</span>
                          </>
                        )
                      }
                    </button>
                  </div>
                </div>
              </div>
            </ProtectedComponent>
          </form>
        </div>
      </div>
    </Modal>
  );

}
