import { TokenHelper } from "common/helpers/auth/token-helper";

interface ProtectedComponentProps {
  role: number[];
  action: 'hide' | 'none';
  children: JSX.Element;
}

export default function ProtectedComponent({ role, action, children }: ProtectedComponentProps) {
  const helper = new TokenHelper();
  const hasAccess = helper.HasAccess(role);

  let content: React.ReactNode;

  switch (action) {
    case 'hide':
      content = hasAccess ? children : null;
      break;
    case 'none':
      content = (
        <div>
          {children}
        </div>
      );
      break;
    default:
      content = children;
  }

  return content;
}