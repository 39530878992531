import { http } from 'common/helpers/axios/axios-helper';
import { APIClientsModel as Model } from '../model/api-clients.model';

const basePath = '/clientapi';

async function getDataList() {
  return await http.get<Model[]>(`${basePath}`);
}

async function getRandomSecret() {
  return await http.get<any>(`${basePath}/guid`);
}

async function getDataById(id: any) {
  return await http.get<Model>(`${basePath}/${id}`);
}

async function postData(data: Model) {
  return await http.post<Model>(`${basePath}`, data);
}

async function putData(data: Model) {
  return await http.put<Model>(`${basePath}/${data.id}`, data);
}

async function deleteDataById(id: any) {
  return await http.del(`${basePath}/${id}`);
}

export const APIClientsService = {
  getDataList,
  getRandomSecret,
  getDataById,
  postData,
  putData,
  deleteDataById
};
