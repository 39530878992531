import { http } from 'common/helpers/axios/axios-helper';
import { ProcessModel as Model } from '../model/process.model';
import { ProcessAndMachinesModel, ProcessMachineUpdateViewModel } from '../components/childs/machine-selector/model/machine-selector.model';

const basePath = '/process';

async function getDataList(departmentId?: number, status?: string, processName?: string) {
  const queryParams = [];

  if (departmentId !== undefined && departmentId > 0) {
    queryParams.push(`DepartmentId=${departmentId}`);
  }

  if (status !== undefined && status !== '') {
    queryParams.push(`IsActive=${status}`);
  }

  if (processName !== undefined && processName !== '') {
    queryParams.push(`Name=${processName}`);
  }

  const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  return await http.get<Model[]>(`${basePath}${queryString}`);
}

async function getDataById(id: any) {
  return await http.get<Model>(`${basePath}/${id}`);
}

async function postData(data: Model) {
  return await http.post<Model>(`${basePath}`, data);
}

async function putData(data: Model) {
  return await http.put<Model>(`${basePath}/${data.id}`, data);
}

async function deleteDataById(id: any) {
  return await http.del(`${basePath}/${id}`);
}

async function getProcessAndMachines(processId: number) {
  return await http.get<ProcessAndMachinesModel>(`${basePath}/${processId}/allMachines`);
}

async function updateProcessMachines(data: ProcessMachineUpdateViewModel) {
  return await http.put(`${basePath}/updateProcessMachines`, data);
}

export const ProcessesService = {
  getDataList,
  getDataById,
  getProcessAndMachines,
  postData,
  putData,
  updateProcessMachines,
  deleteDataById
};