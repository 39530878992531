import moment, { Moment } from "moment";
import "moment/locale/pt-br";

import i18next, { TFunction } from "i18next";

/**
 * Formats a date string according to the user's time zone and locale.
 *
 * You can find a list of available format tokens in the moment.js documentation: https://momentjs.com/
 *
 * @param dateTimeString The date string to format (preferably in UTC format).
 * @param formatString (Optional) The desired date format. Refer to the moment.js documentation for available tokens.
 * @returns The formatted date string.
 */
export function formatLocalizedDateTime(dateTimeString: string, formatString: string = "L") {
  if (!dateTimeString) return null;

  const formattedDate = getLocalizedDateTime(dateTimeString).format(formatString);

  return formattedDate;
};

export function formatRelativeTime(dateOrTimeString: string) {
  if (!dateOrTimeString) return null;

  const formattedDateFromNow = getLocalizedDateTime(dateOrTimeString).fromNow();

  return formattedDateFromNow;
};

function getLocalizedDateTime(dateOrTimeString: string) {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const userLocale = i18next.language === "br" ? "pt-br" : "en";

  return moment.utc(dateOrTimeString).tz(timeZone).locale(userLocale);
}

export function formatTimeSince(dateTimeString: string, t: TFunction<"translation", undefined>) {

  const moment: Moment = getLocalizedDateTime(dateTimeString)
  const date: Date = new Date(moment.toDate());
  const now: Date = new Date();
  const elipsed: number = ((now.getTime() - date.getTime()) / 1000);
  const seconds = Math.floor(elipsed);

  const numberOfSecondsInTheYear = 31536000;
  const numberOfSecondsInTheMonth = 2592000;
  const numberOfSecondsInTheDay = 86400;
  const numberOfSecondsInTheHour = 3600;
  const numberOfSecondsInTheMinute = 60;

  if (seconds >= numberOfSecondsInTheYear) {
      return Math.floor(seconds / numberOfSecondsInTheYear) + ` ${t("time.abbreviated.year")}`;
  } else if (seconds >= numberOfSecondsInTheMonth) {
      return Math.floor(seconds / numberOfSecondsInTheMonth) + ` ${t("time.abbreviated.month")}`;
  } else if (seconds >= numberOfSecondsInTheDay) {
      return Math.floor(seconds / numberOfSecondsInTheDay) + ` ${t("time.abbreviated.day")}`;
  } else if (seconds >= numberOfSecondsInTheHour) {
      return Math.floor(seconds / numberOfSecondsInTheHour) + ` ${t("time.abbreviated.hour")}`;
  } else if (seconds >= numberOfSecondsInTheMinute) {
      return Math.floor(seconds / numberOfSecondsInTheMinute) + ` ${t("time.abbreviated.minute")}`;
  } else {
      return Math.floor(seconds) + ` ${t("time.abbreviated.second")}`;
  }
}