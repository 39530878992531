import { http } from 'common/helpers/axios/axios-helper';
import { GroupAndRolesModel } from '../model/role-selector.model';

const basePath = '/group';

async function getGroupsAndRoles(groupId: number) {
  return await http.get<GroupAndRolesModel>(`/Group/${groupId}/roles`);
}

async function updateGroupRole(groupId: number, roles: number[]) {
  let data = { groupId: groupId, rolesId: roles };
  return await http.post(`${basePath}/updateGroupRole`, data);
}


export const RoleSelectorService = { getGroupsAndRoles, updateGroupRole };
