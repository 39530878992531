import FullCalendar, { CalendarApi, DatesSetArg } from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useContext, useEffect, useRef } from "react";
import ScheduleContext from "../context/schedule.context";
import en from '../../../i18n/en-us.json';
import pt from '../../../i18n/pt-br.json';
import es from '../../../i18n/es-es.json';
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import "../styles/CustomFullCalendar.css"

export default function ScheduleHeader() {
    i18next.addResourceBundle('us', 'translation', en);
    i18next.addResourceBundle("es", "translation", es);
    i18next.addResourceBundle('br', 'translation', pt);
    const { t } = useTranslation();

    const { startEndDate, setStartEndDate, contextOptions, setContextOptions } = useContext(ScheduleContext)

    const handleWeekendsToggle = () => {
        setContextOptions({ ...contextOptions!, isWeekendsVisible: !contextOptions?.isWeekendsVisible });
    }

    const handleMinMaxTime = () => {
        setContextOptions({ ...contextOptions!, isCompactMode: !contextOptions?.isCompactMode });
    }

    const customButtons = {
        weekends: {
            icon: `${contextOptions?.isWeekendsVisible ? ' fa fa-calendar' : ' fa fa-calendar'}`,
            hint: `${t('calendar.checkboxes.showWeekends')}`,
            click: () => handleWeekendsToggle()
        },
        compact: {
            icon: `${contextOptions?.isCompactMode ? ' fa fa-compress' : ' fa fa-expand'}`,
            hint: `${t('calendar.checkboxes.showCompactMode')}`,
            click: () => handleMinMaxTime()
        }
    }

    const calendarHeaderRef: any = useRef()
    const calendarApi = calendarHeaderRef?.current?.getApi() as CalendarApi;

    useEffect(() => {
        console.log(calendarApi?.view)
        if (calendarApi?.view) {
            setContextOptions({ ...contextOptions!, calendarViewType: calendarApi?.view.type });
        }
    }, [calendarApi?.view])


    return <FullCalendar
        ref={calendarHeaderRef}
        viewClassNames={"remove-body"}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        locale={`${t('locale')}`}
        headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'compact weekends dayGridMonth,timeGridWeek,customTimeGridDay'
        }}
        views={
            {
                "customTimeGridDay": {
                    type: 'timeGrid',
                    duration: { days: 1 }
                }
            }
        }
        customButtons={customButtons}
        datesSet={(dates: DatesSetArg) => {
            if (startEndDate?.startStr !== dates.startStr &&
                startEndDate?.endStr !== dates.endStr) {
                setStartEndDate(dates);
            }
        }}
    />

}