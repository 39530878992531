import { http } from 'common/helpers/axios/axios-helper';
import { MachineAndStepStatisticsModel, StepLogModel, NextAndLastRunModel, StepLogActionByEventPostModel, StepLogActionByStepPostModel, ConnectionsViewModel, NextAndLastRunRequestModel, StepLogActionScreenShotPostModel } from '../model/dashboard.model';

const basePath = '/dashboard';

async function getMachineStatistics() {
  return await http.get<MachineAndStepStatisticsModel>(`${basePath}/machine`);
}

async function getStepStatistics() {
  return await http.get<MachineAndStepStatisticsModel>(`${basePath}/stepStatus`);
}

async function getSuspiciousExecutions() {
  return await http.get<MachineAndStepStatisticsModel>(`${basePath}/suspicious`);
}

async function getFailedExecutions() {
  return await http.get<MachineAndStepStatisticsModel>(`${basePath}/failed`);
}

async function getStepLog() {
  return await http.get<StepLogModel[]>(`${basePath}/stepLog`);
}

async function getUnscheduledSteps() {
  return await http.get<StepLogModel[]>(`${basePath}/unscheduledSteps`);
}

async function getNextExecutions() {
  return await http.get<NextAndLastRunModel[]>(`${basePath}/nextrun`);
}

async function getLastExecutions(request: NextAndLastRunRequestModel) {
  const queryParams = [];

  if (request.timeRange !== undefined) {
    queryParams.push(`TimeRange=${request.timeRange}`);
  }

  const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

  return await http.get<NextAndLastRunModel[]>(`${basePath}/lastrun${queryString}`);
}

async function getConnections() {
  return await http.get<ConnectionsViewModel>(`${basePath}/connections`);
}

async function stopStep(data: StepLogActionByEventPostModel) {
  return await http.post<StepLogActionByEventPostModel>(`/agent/manualstop`, data);
}

async function startScheduledStep(data: StepLogActionByEventPostModel) {
  return await http.post<StepLogActionByEventPostModel>(`/agent/manualeventstart`, data);
}

async function startUnscheduleStep(data: StepLogActionByStepPostModel) {
  return await http.post<StepLogActionByStepPostModel>(`/agent/manualstepstart`, data);
}

async function refreshStep(data: StepLogActionByEventPostModel) {
  return await http.post<StepLogActionByEventPostModel>(`/agent/manualreload`, data);
}

async function machineScreenshot(data: StepLogActionScreenShotPostModel) {
  return await http.post<StepLogActionScreenShotPostModel>(`/agent/screenshot`, data);
}

export const DashboardService = {
  getMachineStatistics,
  getStepStatistics,
  getSuspiciousExecutions,
  getFailedExecutions,
  getStepLog,
  getUnscheduledSteps,
  getNextExecutions,
  getLastExecutions,
  getConnections,
  stopStep,
  startScheduledStep,
  startUnscheduleStep,
  refreshStep,
  machineScreenshot
};
