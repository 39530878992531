export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  const normalizedCurrent = current.endsWith('/') ? current : `${current}/`;
  const normalizedUrl = url.endsWith('/') ? url : `${url}/`;

  return normalizedCurrent === normalizedUrl || normalizedCurrent.startsWith(normalizedUrl);
}