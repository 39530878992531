import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import searchIcon from 'assets/images/icons/search.svg';
import { PresetsModel } from 'app/general-settings/model/general-settings.model';
import ITSVG from '../ITSVG';

const Translate = () => {
  const { t } = useTranslation();
  return t;
};

export const getStoredTableDefaultRowsPerPage = () => {
  let rowPerPage = 30;
  const savedDataAsString = sessionStorage.getItem('appPresets');
  if (savedDataAsString) {
    const savedData: PresetsModel = JSON.parse(savedDataAsString);
    if (savedData.tableDefaultRowsPerPage > 0) {
      rowPerPage = savedData.tableDefaultRowsPerPage;
    }
  }

  return rowPerPage;
}

const defaultRowsPerPage = getStoredTableDefaultRowsPerPage();
const paginationRowsPerPageOptions = [10, 20, 30, 50, 100];

const getTableHeader = (handleSearch: any) => {
  return (
    <div className="card-title mb-8 d-flex justify-content-between">
      <div className="d-flex align-items-center position-relative my-1">
        <ITSVG path={searchIcon} className={'svg-icon-1 position-absolute ms-6'} />
        <input
          type="text"
          className="form-control form-control-solid w-250px ps-14"
          placeholder={`${Translate()('searchInput.placeholder')}`}
          name="searchInput"
          onChange={handleSearch}
        />
      </div>
    </div>
  );
};

function getPaginationComponentOptions() {
  const paginationComponentOptions = {
    rowsPerPageText: `${Translate()('datatable.pagination.title')}`,
    rangeSeparatorText: Translate()('datatable.pagination.of'),
    selectAllRowsItem: true,
    selectAllRowsItemText: Translate()('datatable.selectedItems.all'),
  };

  return paginationComponentOptions;
}

function getContextMessage() {
  const contextMessage = {
    singular: `${Translate()('datatable.selectedItems.singular')}`,
    plural: `${Translate()('datatable.selectedItems.plural')}`,
  };

  return contextMessage;
}

function getNoDataComponent() {
  return (
    <div className="text-gray-600 fw-bolder my-10">
      <div className="d-flex text-center w-100 align-content-center justify-content-center">
        {Translate()('datatable.noData')}
      </div>
    </div>
  );
}

function getLoader() {
  return (
    <div className="w-100 overflow-hidden">
      <div className="row">
        <div className="col">
          <Skeleton variant="rounded" height={25} className="my-2" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Skeleton variant="rounded" height={25} className="my-2" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Skeleton variant="rounded" height={25} className="my-2" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Skeleton variant="rounded" height={25} className="my-2" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Skeleton variant="rounded" height={25} className="my-2" />
        </div>
      </div>
    </div>
  );
}

export const tableHelper = {
  getTableHeader,
  getPaginationComponentOptions,
  getContextMessage,
  getNoDataComponent,
  getLoader,
  defaultRowsPerPage,
  paginationRowsPerPageOptions
};
