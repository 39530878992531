import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AuthenticationMethodSettingsModel as Model } from '../../model/general-settings.model';
import { GeneralSettingsService as Service } from '../../services/general-settings.service';

interface Props { }

export function AuthenticationType(props: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = Yup.object().shape({
    authenticateActiveDirectory: Yup.boolean().required(t('validation.requiredField')),
    authenticateDataBase: Yup.boolean().required(t('validation.requiredField')),
  });

  const updateData = async (fieldsToUpdate: Partial<Model>): Promise<void> => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  let initialData: Model = {
    authenticateActiveDirectory: false,
    authenticateDataBase: false,
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: formSchema,

    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const putBody: Model = {
          authenticateActiveDirectory: data.authenticateActiveDirectory,
          authenticateDataBase: data.authenticateDataBase,
        };

        const result = await Service.putAuthTypeSettings(putBody);
        if (result && !result?.hasErrors) {
          toast.success(`${t('crud.update.successMessage')}`);
        } else {
          toast.error(`${t(`error.${result?.errorCode}`)}`);
        }
      } catch (err) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await Service.getAuthTypeSettings();
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      } catch (err) {
        toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className="form">
      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#authTypeSettings"
          aria-expanded="true"
          aria-controls="authTypeSettings"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{t('generalSettings.authType.title')}</h3>
          </div>
        </div>

        <div id="authTypeSettings" className="collapse show">
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {t('generalSettings.authType.activeDirectoryOpt')}
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12 fv-row ">
                    <div className="form-check form-check-solid form-switch">
                      <input
                        className="form-check-input w-45px h-30px "
                        type="checkbox"
                        id="authenticateActiveDirectory"
                        checked={data.authenticateActiveDirectory}
                        onChange={() => {
                          if (data.authenticateDataBase) {
                            updateData({
                              authenticateActiveDirectory: !data.authenticateActiveDirectory,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                {t('generalSettings.authType.databaseOpt')}
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12 fv-row ">
                    <div className="form-check form-check-solid form-switch">
                      <input
                        className="form-check-input w-45px h-30px "
                        type="checkbox"
                        id="authenticateDataBase"
                        checked={data.authenticateDataBase}
                        onChange={() => {
                          if (data.authenticateActiveDirectory) {
                            updateData({
                              authenticateDataBase: !data.authenticateDataBase,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="reset" className="btn btn-white btn-active-light-primary me-2">{t('crud.create.buttonCancel')}</button>
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {!isLoading && t('crud.create.buttonSave')}
              {isLoading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  {t('crud.common.wait')}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
