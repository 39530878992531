import appsettings from 'appsettings.json';
import AppLogo from 'common/partials/AppLogo';
import ItLabLogo from 'assets/images/logos/itlab-logo.png';
import * as Yup from 'yup';
import { useState } from 'react';
import { RecoveryPassword as Model } from '../model/recovery-password.model';
import { AuthService as Service } from 'common/helpers/auth/auth.service';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import i18next from 'i18next';

export default function RecoveryPassword() {
    i18next.addResourceBundle("us", "translation", en);
    i18next.addResourceBundle("es", "translation", es);
    i18next.addResourceBundle("br", "translation", pt);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);

    const formSchema = Yup.object().shape({
        newPassword: Yup.string().required(t('crud.validators.requiredField')),
        confirmNewPassword: Yup.string()
            .required(t('crud.validators.requiredField').toString())
            .oneOf([Yup.ref('newPassword'), null], t('crud.validators.passwordMatch').toString()),
        code: Yup.string().required(t('crud.validators.requiredField')),
    });

    const changePassword = async (values: Model) => {
        setLoading(true);
        try {
            const response = await Service.changeRequestPassword(values);
            if (response && !response.hasErrors) {
                toast.success(`${t('recoverypassword.form.success')}`);
                navigate("/login")
            } else {
                const errorCode = response?.errorCode;
                toast.error(`${t(`recoverypassword.form.errors.${errorCode}`)}`);
            }
        } catch (err) {
            toast.error(`${t(`recoverypassword.form.errors.undefined`)}`);
        }
        finally {
            setLoading(false);
        }
    };

    const formik = useFormik<Model>({
        initialValues: {
            newPassword: '',
            confirmNewPassword: '',
            code: searchParams.get('c') ?? ''
        },
        validationSchema: formSchema,
        onSubmit: changePassword,
    });

    return <>
        <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
            <div className="d-flex flex-center flex-column flex-column-fluid p-5 pb-lg-20">
                <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
                    <form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_recovery_form">
                        <div className="text-start">
                            <button
                                className='btn btn-link'
                                onClick={() => navigate("/login")}>
                                {`< Login`}
                            </button>
                        </div>
                        <div className="text-center mb-10">
                            <AppLogo height={70} />
                        </div>
                        <h3 className="text-center mb-3">{appsettings['application-name']}</h3>
                        <div className="text-end mb-5">
                            <h1 className="text-center mb-3">{t("recoverypassword.title")}</h1>
                            <span className="text-muted text-end fw-bolder fs-8">
                                <span>{t('topbar.system.version')}</span>
                                {appsettings['application-version']}
                            </span>
                        </div>

                        <div className="mb-8">
                            <input
                                placeholder={t("recoverypassword.form.placeholder.newPassword")}
                                {...formik.getFieldProps('newPassword')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
                                    { 'is-valid': formik.touched.newPassword && !formik.errors.newPassword },
                                )}
                                type="password"
                                name="newPassword"
                                autoComplete="off"
                            />
                            {formik.touched.newPassword && formik.errors.newPassword && (
                                <div className="mt-3 text-danger fw-bold">
                                    <span role="alert">{t("crud.validators.requiredField")}</span>
                                </div>
                            )}
                        </div>

                        <div className="mb-8">
                            <input
                                placeholder={t("recoverypassword.form.placeholder.confirmNewPassword")}
                                {...formik.getFieldProps('confirmNewPassword')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    { 'is-invalid': formik.touched.confirmNewPassword && formik.errors.confirmNewPassword },
                                    {
                                        'is-valid': formik.touched.confirmNewPassword && !formik.errors.confirmNewPassword,
                                    },
                                )}
                                type="password"
                                name="confirmNewPassword"
                                autoComplete="off"
                            />
                            {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
                                <div className="mt-3 text-danger fw-bold">
                                    <span role="alert">{t("crud.validators.requiredField")}</span>
                                </div>
                            )}
                        </div>

                        <div className="text-center">
                            <button
                                type="submit"
                                id="kt_recovery_submit"
                                className="btn btn-lg btn-primary w-100 mb-5"
                                disabled={formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && <span className="indicator-label">{t("recoverypassword.form.submit")}</span>}
                                {loading && (
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        {t("crud.common.wait")}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <span>{t("login.footer")}</span>
                        <img alt="IT Lab Logo" height={25} src={ItLabLogo} />
                    </div>
                </div>
            </div>
        </div>
    </>
}