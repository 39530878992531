import { ReactElement, useEffect, useState } from 'react';
import { DataBaseConfigModel } from '../../model/health-check.model';
import { HealthCheckService } from '../../services/health-check.service';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import { CardItemModel } from 'common/layout/components/card/model/card.model';
import { Card } from 'common/layout/components/card/Card';

let databaseArray: CardItemModel[] = [];

export function NoSQLDatabaseHealthCheck() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t, i18n } = useTranslation();

  const [databaseState, setDatabaseState] = useState<CardItemModel[]>([]);
  const [noSQLDataBaseData, setNoSQLDataBaseData] = useState<DataBaseConfigModel>();
  const [fragment, setFragment] = useState<ReactElement>(<></>);

  function buildDatabaseItems(data: DataBaseConfigModel | undefined): void {
    if (!data) {
      return;
    }

    databaseArray = [];
    databaseArray.push({ title: t('healthCheck.nosqldatabase.name'), value: data.dataBaseName });
    databaseArray.push({ title: t('healthCheck.nosqldatabase.instance'), value: data.serverInstance });

    setDatabaseState(databaseArray);
  }

  useEffect(() => {
    (async () => {
      buildDatabaseItems(noSQLDataBaseData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, noSQLDataBaseData]);

  useEffect(() => {
    (async () => {
      try {
        const databaseResponse = await HealthCheckService.getNoSQLDatabaseConfig();
        if (databaseResponse && !databaseResponse.hasErrors) {
          setNoSQLDataBaseData(databaseResponse.data);
        }
      } catch (err) {
        toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await HealthCheckService.testNoSQLDataBaseConfig();
        if (response) {
          setFragment(response.hasErrors
            ? <span className="badge badge-light-danger fs-6">{t('healthCheck.tests.failure')}</span>
            : <span className="badge badge-light-success fs-6">{t('healthCheck.tests.success')}</span>)
        }
      } catch (err) {
        toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card title={t('healthCheck.nosqldatabase.title')} items={databaseState} headerTopRightElement={fragment}></Card>
    </>
  );
}
