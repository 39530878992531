import i18next from 'i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { RobotTypeModel as Model } from '../model/robotType.model';
import { RobotTypeService as Service } from '../services/robotType.service';
import SaveButton from 'common/partials/SaveButton';

export function CreateEditRobotType() {
  const [isLoading, setIsLoading] = useState(false);
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('crud.validators.requiredField'))
      .max(70, t('crud.validators.maxLength_70')),
    description: Yup.string()
      .required(t('crud.validators.requiredField'))
      .max(1024, t('crud.validators.maxLength_1024')),
  });

  let initialData: Model = {
    id: parseInt(id ?? '0'),
    name: '',
    description: '',
    isActive: true,
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const body: Model = {
          id: data.id,
          name: values.name,
          description: values.description,
          isActive: values.isActive,
        };

        if (id) {
          const result = await Service.putData(body);
          if (result && !result?.hasErrors) {
            toast.success(`${t('crud.update.successMessage')}`);
          }
        } else {
          const result = await Service.postData(body);
          if (result && !result?.hasErrors) {
            toast.success(`${t('crud.create.successMessage')}`);
            navigate(`/masterdata/robotType`);
          } else {
            toast.error(`${t(`error.${result?.errorCode}`)}`);
          }
        }
      } catch (error) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (id) {
        const settingsResponse = await Service.getDataById(id);
        if (settingsResponse && !settingsResponse.hasErrors) {
          setData(settingsResponse.data);
        }
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MasterLayout>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight></ToolbarLayoutRight>
        </ToolbarLayout>
        <MainLayout>
          <form onSubmit={formik.handleSubmit}>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 mb-10">
                    <label className="required form-label">{t('form.label.name')}</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control form-control-lg form-control-solid"
                      placeholder={t('form.placeholder.name')}
                      value={formik.values.name ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                      maxLength={70}
                      autoComplete="off"
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.name}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-10">
                    <label className="required form-label">{t('form.label.description')}</label>
                    <input
                      type="text"
                      name="description"
                      className="form-control form-control-lg form-control-solid"
                      placeholder={t('form.placeholder.description')}
                      value={formik.values.description ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                      maxLength={512}
                      autoComplete="off"
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.description}</div>
                    )}
                  </div>
                  {id && (
                    <div className="col-md-6">
                      <label className="form-label">{t('form.label.status')}</label>
                      <div className="form-check form-check-solid form-switch">
                        <input
                          className="form-check-input w-45px h-30px"
                          type="checkbox"
                          id="isActive"
                          name="isActive"
                          checked={formik.values.isActive ?? false}
                          onChange={formik.handleChange}
                        />
                      </div>
                      {formik.values.isActive ? (
                        <div className="text-muted fs-7 mt-5">{t('generalMessages.active')}</div>
                      ) : (
                        <div className="text-muted fs-7 mt-5">{t('generalMessages.inactive')}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <Link className="btn btn-light btn-active-light-primary me-2" to="/masterdata/robotType">
                  {t('crud.create.buttonCancel')}
                </Link>
                <SaveButton type="submit" loading={isLoading} />
              </div>
            </div>
          </form>
        </MainLayout>
      </MasterLayout>
    </>
  );
}
