import ReactDOM from "react-dom/client";
import App from 'App';
import globalStyles from 'assets/global-styles/index.scss';
import 'assets/sass/style.scss';
import 'assets/sass/style.react.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import { ToastContainer } from 'react-toastify';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./app/login/sso/authConfig";

const GlobalStyle = createGlobalStyle`${globalStyles}`;
const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <>
    <GlobalStyle />
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
    <ToastContainer
      stacked
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="colored"
      pauseOnHover
      style={{ fontWeight: 700, fontStyle: 'Poppins' }}
    />
  </>
);