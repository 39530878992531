import { Cancel } from "@mui/icons-material";
import { Box, Chip, FormControl, MenuItem, Select, SelectChangeEvent, Theme, useTheme } from "@mui/material";
import { SchedulePostRequestViewModel } from "app/schedule/model/schedule.model";
import { FormikErrors, FormikTouched } from "formik";
import moment from "moment-timezone";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  recurringType: number;
  handleChange: (event: any) => void;
  values: SchedulePostRequestViewModel;
  touched: FormikTouched<SchedulePostRequestViewModel>;
  setTouched: (touched: FormikTouched<SchedulePostRequestViewModel>, shouldValidate?: boolean | undefined) => Promise<FormikErrors<SchedulePostRequestViewModel>> | Promise<void>;
  setFieldsValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<SchedulePostRequestViewModel>>;
  errors: any;
  monthOfYear: string[];
  setMonthOfYear: (event: any) => void;
  dayOfMonth: string[];
  setDayOfMonth: (event: any) => void;
  setEndDate: (event: any) => void;
  open: boolean;
}

export function Step3(props: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [dailyFrequency, setDailyFrequency] = useState(false);
  const [hasEndDate, setHasEndDate] = useState(false);
  const [frequencyType, setfrequencyType] = useState("dayOfMonth")
  const separationTime = [5, 10, 15, 20, 30, 60, 120]
  const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  const months = [
    {
      name: t("scheduleModal.fields.frequency.options.months.name.january"),
      value: "1"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.february"),
      value: "2"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.march"),
      value: "3"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.april"),
      value: "4"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.may"),
      value: "5"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.june"),
      value: "6"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.july"),
      value: "7"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.august"),
      value: "8"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.september"),
      value: "9"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.october"),
      value: "10"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.november"),
      value: "11"
    },
    {
      name: t("scheduleModal.fields.frequency.options.months.name.december"),
      value: "12"
    },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    disableScrollLock: true,
  };

  function getStyles(month: string, monthOfYear: readonly string[], theme: Theme) {
    return {
      fontWeight:
        monthOfYear.indexOf(month) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChangeMonthOfYear = (event: SelectChangeEvent<typeof props.monthOfYear>) => {
    const { target: { value } } = event;
    const selectedMonths = typeof value === "string" ? value.split(",") : value;
    props.setMonthOfYear(selectedMonths);
    props.setFieldsValue("monthOfYear", selectedMonths);
  }

  const handleDeleteMonthOfYear = (e: any, value: any) => {
    e.preventDefault();
    const deletedMonths = props.monthOfYear.filter((name: string) => name !== value);
    props.setMonthOfYear(deletedMonths);
    props.setFieldsValue("monthOfYear", deletedMonths);
  }

  const handleChangeDayOfMonth = (event: SelectChangeEvent<typeof props.dayOfMonth>) => {
    const { target: { value } } = event;
    const selectedDays = typeof value === "string" ? value.split(",") : value;
    props.setDayOfMonth(selectedDays);
    props.setFieldsValue("dayOfMonth", selectedDays);
  }

  const handleDeleteDayOfMonth = (e: any, value: any) => {
    e.preventDefault();
    const deletedDays = props.dayOfMonth.filter((name: string) => name !== value);
    props.setDayOfMonth(deletedDays);
    props.setFieldsValue("dayOfMonth", deletedDays);
  }

  const getExecutionDisclaimer = () => {
    return (
      <div className="col-12 mb-7">
        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
          <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
              <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
              <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
            </svg>
          </span>
          <div className="d-flex flex-stack flex-grow-1">
            <div className="fw-bold">
              <h4 className="text-gray-800 fw-bolder fs-6">{t('scheduleModal.executionDisclaimer.title')}</h4>
              <div className="fs-8 text-gray-600">{t('scheduleModal.executionDisclaimer.content')}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row">

      {props.recurringType === 1 && (
        <>
          <div className="col-12 mb-7">
            <h2 className="fw-bolder text-dark">{t("scheduleModal.fields.frequency.label.daily")}</h2>
            <span className="text-gray-400 fw-bold fs-6">{t("scheduleModal.fields.frequency.tooltip")}</span>
          </div>

          <div className="col-12 mb-7">
            <label className="form-label required">{t("scheduleModal.fields.frequency.options.repeatEvery.days")}</label>
            <input
              type="number"
              className="form-control form-control-solid me-3"
              name="separation"
              id="separation"
              defaultValue={0}
              onChange={props.handleChange}
              disabled={props.recurringType !== 1}
            />
            {props.errors.separation && (
              <div className="mt-2 text-danger fw-bold">
                {props.errors.separation}
              </div>
            )}
          </div>

          <div className="col-12 mb-7">
            <div className="d-flex align-items-center">
              <label className="form-check form-check-custom form-check-solid me-3">
                <input
                  className="form-check-input h-20px w-20px"
                  type="checkbox"
                  name="weekend"
                  id="weekend"
                  defaultChecked={props.values.weekend ?? false}
                  onChange={(e) => {
                    props.values.weekend = e.target.checked;
                    props.setFieldsValue("weekend", e.target.checked);
                  }}
                  disabled={props.recurringType !== 1}
                />
              </label>
              <label className="form-label mb-0 cursor-pointer" htmlFor="weekend">{t("scheduleModal.fields.frequency.options.executeOnWeekend")}</label>
            </div>
          </div>

          <div className="col-12 mb-7">
            <div className="d-flex align-items-center">
              <label className="form-check form-check-custom form-check-solid me-3">
                <input
                  className="form-check-input h-20px w-20px"
                  type="checkbox"
                  name="holiday"
                  id="holiday"
                  defaultChecked={props.values.holiday ?? false}
                  onChange={(e) => {
                    props.values.holiday = e.target.checked;
                    props.setFieldsValue("holyday", e.target.checked);
                  }}
                  disabled={props.recurringType !== 1}
                />
              </label>
              <label className="form-label mb-0 cursor-pointer" htmlFor="holiday">{t("scheduleModal.fields.frequency.options.executeOnHoliday")}</label>
            </div>
          </div>

          {(!props.values.holiday || !props.values.weekend) && (
            getExecutionDisclaimer()
          )}

          <div className="col-12 d-flex flex-stack mb-7">
            <label className="form-label mb-0">{t("scheduleModal.fields.frequency.options.dailyFrequency")}</label>
            <div className="d-flex align-items-center">
              <div className="form-check form-check-solid form-switch">
                <input
                  className="form-check-input w-45px h-30px"
                  type="checkbox"
                  id="dailyFrequency"
                  name="dailyFrequency"
                  checked={dailyFrequency ?? false}
                  onChange={(e) => {
                    props.handleChange(e)
                    setDailyFrequency(e.target.checked)
                  }}
                />
              </div>
            </div>
          </div>

          {dailyFrequency &&
            <>
              <div className="col-6 mb-7">
                <label className="form-label required">{t("scheduleModal.fields.startTime.label")}</label>
                <input
                  className="form-control form-control-solid"
                  type="time"
                  name="startTime"
                  value={props.values.startTime}
                  onChange={props.handleChange}
                  disabled={!dailyFrequency}
                />
                {props.errors.startTime && (
                  <div className="mt-3 text-danger fw-bold">
                    {props.errors.startTime}
                  </div>
                )}
              </div>

              <div className="col-6 mb-7">
                <label className="form-label required">{t("scheduleModal.fields.endTime.label")}</label>
                <input
                  className="form-control form-control-solid"
                  type="time"
                  name="endTime"
                  value={props.values.endTime}
                  onChange={props.handleChange}
                  disabled={!dailyFrequency}
                />
                {props.errors.endTime && (
                  <div className="mt-3 text-danger fw-bold">
                    {props.errors.endTime}
                  </div>
                )}
              </div>

              <div className="col-6 mb-7">
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <label className="form-label required me-3">{t("scheduleModal.fields.frequency.options.repeatEvery.minutes")}</label>
                  <Select
                    name="separationTime"
                    value={props.values.separationTime ?? 0}
                    onChange={props.handleChange}
                    MenuProps={{ disableScrollLock: true }}
                    disabled={!dailyFrequency}
                  >
                    {separationTime.map((value: number) => (
                      <MenuItem value={value} key={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {props.errors.timeInterval && (
                  <div className="ms-3 text-danger fw-bold">
                    {props.errors.timeInterval}
                  </div>
                )}
              </div>

            </>
          }

        </>
      )}

      {props.recurringType === 2 && (
        <>
          <div className="col-12 mb-7">
            <h2 className="fw-bolder text-dark">{t("scheduleModal.fields.frequency.label.weekly")}</h2>
            <span className="text-gray-400 fw-bold fs-6">{t("scheduleModal.fields.frequency.tooltip")}</span>
          </div>

          <div className="col-12 mb-7">
            <label className="form-label required">{t("scheduleModal.fields.frequency.options.repeatEvery.weeks")}</label>
            <input
              type="number"
              className="form-control form-control-solid me-3"
              name="separation"
              id="separation"
              defaultValue={0}
              onChange={props.handleChange}
              disabled={props.recurringType !== 2}
            />
            {props.errors.separation && (
              <div className="mt-2 text-danger fw-bold">
                {props.errors.separation}
              </div>
            )}
          </div>

          <div className="col-12 mb-7">
            <div className="d-flex align-items-center">
              <label className="form-check form-check-custom form-check-solid me-3">
                <input
                  className="form-check-input h-20px w-20px"
                  type="checkbox"
                  name="weekend"
                  id="weekend"
                  defaultChecked={props.values.weekend ?? false}
                  onChange={(e) => {
                    props.values.weekend = e.target.checked;
                    props.setFieldsValue("weekend", e.target.checked);
                  }}
                />
              </label>
              <label className="form-label mb-0 cursor-pointer" htmlFor="weekend">{t("scheduleModal.fields.frequency.options.executeOnWeekend")}</label>
            </div>
          </div>

          <div className="col-12 mb-7">
            <div className="d-flex align-items-center">
              <label className="form-check form-check-custom form-check-solid me-3">
                <input
                  className="form-check-input h-20px w-20px"
                  type="checkbox"
                  name="holiday"
                  id="holiday"
                  defaultChecked={props.values.holiday ?? false}
                  onChange={(e) => {
                    props.values.holiday = e.target.checked;
                    props.setFieldsValue("holyday", e.target.checked);
                  }}
                />
              </label>
              <label className="form-label mb-0 cursor-pointer" htmlFor="holiday">{t("scheduleModal.fields.frequency.options.executeOnHoliday")}</label>
            </div>
          </div>

          {(!props.values.holiday || !props.values.weekend) && (
            getExecutionDisclaimer()
          )}

          <div className="col-12 mb-7">
            <div className="d-flex flex-wrap">
              <div className="form-check form-check-solid mb-3 me-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="weeklyDayOfWeek"
                  id="occursSunday"
                  value={0}
                  onChange={props.handleChange}
                  disabled={props.recurringType !== 2}
                />
                <label className="form-label" htmlFor="occursSunday">
                  {t("scheduleModal.fields.frequency.options.weeklyDays.sunday")}
                </label>
              </div>
              <div className="form-check form-check-solid mb-3 me-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="weeklyDayOfWeek"
                  id="occursMonday"
                  value={1}
                  onChange={props.handleChange}
                  disabled={props.recurringType !== 2}
                />
                <label className="form-label" htmlFor="occursMonday">
                  {t("scheduleModal.fields.frequency.options.weeklyDays.monday")}
                </label>
              </div>
              <div className="form-check form-check-solid mb-3 me-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="weeklyDayOfWeek"
                  id="occursTuesday"
                  value={2}
                  onChange={props.handleChange}
                  disabled={props.recurringType !== 2}
                />
                <label className="form-label" htmlFor="occursTuesday">
                  {t("scheduleModal.fields.frequency.options.weeklyDays.tuesday")}
                </label>
              </div>
              <div className="form-check form-check-solid mb-3 me-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="weeklyDayOfWeek"
                  id="occursWednesday"
                  value={3}
                  onChange={props.handleChange}
                  disabled={props.recurringType !== 2}
                />
                <label className="form-label" htmlFor="occursWednesday">
                  {t("scheduleModal.fields.frequency.options.weeklyDays.wednesday")}
                </label>
              </div>
              <div className="form-check form-check-solid mb-3 me-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="weeklyDayOfWeek"
                  id="occursThursday"
                  value={4}
                  onChange={props.handleChange}
                  disabled={props.recurringType !== 2}
                />
                <label className="form-label" htmlFor="occursThursday">
                  {t("scheduleModal.fields.frequency.options.weeklyDays.thursday")}
                </label>
              </div>
              <div className="form-check form-check-solid mb-3 me-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="weeklyDayOfWeek"
                  id="occursFriday"
                  value={5}
                  onChange={props.handleChange}
                  disabled={props.recurringType !== 2}
                />
                <label className="form-label" htmlFor="occursFriday">
                  {t("scheduleModal.fields.frequency.options.weeklyDays.friday")}
                </label>
              </div>
              <div className="form-check form-check-solid mb-3 me-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="weeklyDayOfWeek"
                  id="occursSaturday"
                  value={6}
                  onChange={props.handleChange}
                  disabled={props.recurringType !== 2}
                />
                <label className="form-label" htmlFor="occursSaturday">
                  {t("scheduleModal.fields.frequency.options.weeklyDays.saturday")}
                </label>
              </div>
            </div>
            {props.errors.weeklyDayOfWeek && (
              <div className="mt-3 text-danger fw-bold">
                {props.errors.weeklyDayOfWeek}
              </div>
            )}
          </div>
        </>
      )}

      {props.recurringType === 3 && (
        <>
          <div className="col-12 mb-7">
            <h2 className="fw-bolder text-dark">{t("scheduleModal.fields.frequency.label.monthly")}</h2>
            <span className="text-gray-400 fw-bold fs-6">{t("scheduleModal.fields.frequency.tooltip")}</span>
          </div>

          <div className="col-12 mb-7">
            <label className="form-label required">{t("scheduleModal.fields.frequency.options.months.label")}</label>
            <FormControl variant="filled" sx={{ width: "100%" }}>
              <Select
                multiple
                required
                name="monthOfYear"
                value={props.monthOfYear}
                onChange={(e: any) => {
                  props.handleChange(e)
                  props.setFieldsValue("monthOfYear", e.target.value);
                  handleChangeMonthOfYear(e)
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value: any) => (
                      <Chip
                        key={value}
                        label={months[value - 1].name}
                        clickable={true}
                        deleteIcon={
                          <Cancel
                            onMouseDown={(e: any) => e.stopPropagation()}
                          />
                        }
                        onDelete={(e: any) => handleDeleteMonthOfYear(e, value)}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
                disabled={props.recurringType !== 3}
              >
                {months.map((month) => (
                  <MenuItem
                    key={month.name}
                    value={month.value}
                    style={getStyles(month.name, props.monthOfYear, theme)}
                  >
                    {month.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props.errors.monthOfYear && (
              <div className="mt-3 text-danger fw-bold">
                {props.errors.monthOfYear}
              </div>
            )}
          </div>

          <div className="col-12 mb-7">
            <div className="d-flex align-items-center">
              <label className="form-check form-check-custom form-check-solid me-3">
                <input
                  className="form-check-input h-20px w-20px"
                  type="checkbox"
                  name="weekend"
                  id="weekend"
                  defaultChecked={props.values.weekend ?? false}
                  onChange={(e) => {
                    props.values.weekend = e.target.checked;
                    props.setFieldsValue("weekend", e.target.checked);
                  }}
                />
              </label>
              <label className="form-label mb-0 cursor-pointer" htmlFor="weekend">{t("scheduleModal.fields.frequency.options.executeOnWeekend")}</label>
            </div>
          </div>

          <div className="col-12 mb-7">
            <div className="d-flex align-items-center">
              <label className="form-check form-check-custom form-check-solid me-3">
                <input
                  className="form-check-input h-20px w-20px"
                  type="checkbox"
                  name="holiday"
                  id="holiday"
                  defaultChecked={props.values.holiday ?? false}
                  onChange={(e) => {
                    props.values.holiday = e.target.checked;
                    props.setFieldsValue("holyday", e.target.checked);
                  }}
                />
              </label>
              <label className="form-label mb-0 cursor-pointer" htmlFor="holiday">{t("scheduleModal.fields.frequency.options.executeOnHoliday")}</label>
            </div>
          </div>

          {(!props.values.holiday || !props.values.weekend) && (
            getExecutionDisclaimer()
          )}

          <div className="col-12 mb-3">
            <div className="d-flex align-items-center">
              <div className="form-check form-check-solid me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="dayPattern"
                  id="dayOfMonth"
                  value={"dayOfMonth"}
                  defaultChecked={frequencyType === "dayOfMonth"}
                  onChange={(e: any) => {
                    props.handleChange(e)
                    setfrequencyType("dayOfMonth")
                  }}
                />
                <label className="form-label" htmlFor="dayOfMonth">
                  {t("scheduleModal.fields.frequency.options.dayOfMonth.label")}
                </label>
              </div>
              <FormControl variant="filled" sx={{ width: "100%" }}>
                <Select
                  multiple
                  required
                  name="dayOfMonth"
                  value={props.dayOfMonth}
                  onChange={(e: any) => {
                    props.handleChange(e);
                    handleChangeDayOfMonth(e);
                  }}
                  disabled={frequencyType !== "dayOfMonth"}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={days[value - 1]}
                          clickable={true}
                          deleteIcon={
                            <Cancel
                              onMouseDown={(e: any) => e.stopPropagation()}
                            />
                          }
                          onDelete={(e: any) => handleDeleteDayOfMonth(e, value)}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {days.map((day) => (
                    <MenuItem
                      key={day}
                      value={day}
                      style={getStyles((day).toString(), props.dayOfMonth, theme)}
                    >
                      {day}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {props.errors.dayOfMonth && (
              <div className="text-end mt-3 text-danger fw-bold">
                {props.errors.dayOfMonth}
              </div>
            )}
          </div>

          <div className="col-12 mb-3">
            <div className="d-flex align-items-center">
              <div className="form-check form-check-solid me-3 w-100">
                <input
                  className="form-check-input"
                  type="radio"
                  name="dayPattern"
                  id="weekDays"
                  value={"dayOfWeek"}
                  defaultChecked={frequencyType === "dayOfWeek"}
                  onChange={(e: any) => {
                    props.handleChange(e)
                    setfrequencyType("dayOfWeek")
                  }}
                />
                <label className="form-label" htmlFor="weekDays">
                  {t("scheduleModal.fields.frequency.options.weekDays.label")}
                </label>
              </div>

              <FormControl variant="filled" sx={{ width: "100%" }}>
                <Select
                  className="me-3"
                  name="position"
                  defaultValue={""}
                  onChange={props.handleChange}
                  disabled={frequencyType !== "dayOfWeek"}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={1}>
                    {t("scheduleModal.fields.frequency.options.weekDays.position.first")}
                  </MenuItem>
                  <MenuItem value={2}>
                    {t("scheduleModal.fields.frequency.options.weekDays.position.second")}
                  </MenuItem>
                  <MenuItem value={3}>
                    {t("scheduleModal.fields.frequency.options.weekDays.position.third")}
                  </MenuItem>
                  <MenuItem value={4}>
                    {t("scheduleModal.fields.frequency.options.weekDays.position.fourth")}
                  </MenuItem>
                  <MenuItem value={5}>
                    {t("scheduleModal.fields.frequency.options.weekDays.position.fifth")}
                  </MenuItem>
                  <MenuItem value={0}>
                    {t("scheduleModal.fields.frequency.options.weekDays.position.last")}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="filled" sx={{ width: "100%" }}>
                <Select
                  name="dayOfWeek"
                  onChange={props.handleChange}
                  defaultValue={""}
                  disabled={frequencyType !== "dayOfWeek"}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={0}>
                    {t("scheduleModal.fields.frequency.options.weekDays.dayOfWeek.sunday")}
                  </MenuItem>
                  <MenuItem value={1}>
                    {t("scheduleModal.fields.frequency.options.weekDays.dayOfWeek.monday")}
                  </MenuItem>
                  <MenuItem value={2}>
                    {t("scheduleModal.fields.frequency.options.weekDays.dayOfWeek.tuesday")}
                  </MenuItem>
                  <MenuItem value={3}>
                    {t("scheduleModal.fields.frequency.options.weekDays.dayOfWeek.wednesday")}
                  </MenuItem>
                  <MenuItem value={4}>
                    {t("scheduleModal.fields.frequency.options.weekDays.dayOfWeek.thursday")}
                  </MenuItem>
                  <MenuItem value={5}>
                    {t("scheduleModal.fields.frequency.options.weekDays.dayOfWeek.friday")}
                  </MenuItem>
                  <MenuItem value={6}>
                    {t("scheduleModal.fields.frequency.options.weekDays.dayOfWeek.saturday")}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {props.errors.position && (
              <div className="mt-3 text-end text-danger fw-bold">
                {props.errors.position}
              </div>
            )}
            {props.errors.dayOfWeek && (
              <div className="mt-3 text-end text-danger fw-bold">
                {props.errors.dayOfWeek}
              </div>
            )}
          </div>

          <div className="col-12 mb-3">
            <div className="d-flex flex-stack align-items-center">
              <div className="form-check form-check-solid me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="dayPattern"
                  id="workDay"
                  value={"workDay"}
                  defaultChecked={frequencyType === "workDay"}
                  onChange={(e: any) => {
                    props.handleChange(e);
                    setfrequencyType("workDay")
                  }}
                />
                <label className="form-label" htmlFor="workDay">
                  {t("scheduleModal.fields.frequency.options.workDay.label")}
                </label>
              </div>
              <FormControl variant="filled" sx={{ width: "100px" }}>
                <Select
                  className="custom-select"
                  name="workDayPosition"
                  defaultValue={""}
                  onChange={props.handleChange}
                  disabled={frequencyType !== "workDay"}
                  MenuProps={MenuProps}
                >
                  <MenuItem className="custom-option" value={1}>
                    1
                  </MenuItem>
                  <MenuItem className="custom-option" value={2}>
                    2
                  </MenuItem>
                  <MenuItem className="custom-option" value={3}>
                    3
                  </MenuItem>
                  <MenuItem className="custom-option" value={4}>
                    4
                  </MenuItem>
                  <MenuItem className="custom-option" value={5}>
                    5
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {props.errors.workDayPosition && (
              <div className="mt-3 text-end text-danger fw-bold">
                {props.errors.workDayPosition}
              </div>
            )}
          </div>
        </>
      )}

      {props.recurringType !== 0 && (
        <div className="col-12 mb-10 align-items-center">
          <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
            <span>{t("scheduleModal.fields.conclusion.label")}</span>
            <i
              className="fas fa-exclamation-circle ms-2 fs-7"
              data-bs-toggle="tooltip"
              title={t("scheduleModal.fields.conclusion.tooltip")}
            ></i>
          </label>

          <div className="d-flex align-items-center">
            <div className="form-check form-check-solid mb-4">
              <input
                className="form-check-input"
                type="checkbox"
                name="hasEndDate"
                id="hasEndDate"
                onChange={(e) => {
                  props.handleChange(e);
                  setHasEndDate(e.target.checked);
                  if (e.target.checked) {
                    props.setEndDate(props.values.endDate ? moment(props.values.endDate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"));
                  } else {
                    props.setEndDate("");
                  }
                }}
              />
              <label className="form-label" htmlFor="hasEndDate">
                {t("scheduleModal.fields.conclusion.options.endDate")}
              </label>
            </div>
            <div className="form-check form-check-solid mb-3">
              <input
                className="form-control form-control-solid"
                type="date"
                name="endDate"
                value={moment(props.values.endDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  props.handleChange(e);
                  props.setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                }}
                min={moment(new Date()).format("YYYY-MM-DD")}
                disabled={!hasEndDate}
              />
            </div>
          </div>
        </div>
      )}

    </div>
  )
}