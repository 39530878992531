import i18next from 'i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { tableHelper } from 'common/helpers/table/table-helper';
import { MasterLayout, MainLayout, ToolbarLayout, ToolbarLayoutLeft, ToolbarLayoutRight } from 'common/imports/content-layout';
import { DeleteModal } from 'common/partials/DeleteModal';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import ActionButton from 'common/partials/ActionButton';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { GroupManagementModel as Model } from '../model/group-management.model';
import { GroupManagementService as Service } from '../services/group-management.service';

export function GroupManagement() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Model[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<Model[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = (id: number) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const fetchUsers = async () => {
    setIsLoading(true);
    const response = await Service.getGroupManagementList();
    if (response && !response.hasErrors) {
      setTableData(response.data);
      setFilteredTableData(response.data);
    } else {
      toast.error(`${t('crud.read.errorMessage')}`);
    }
    setIsLoading(false);
  };

  function FilterByName(filter: string) {
    if (filter === '') {
      setFilteredTableData(tableData);
      return;
    }
    const normalizedFilter = filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    setFilteredTableData(
      tableData.filter((item) =>
        item.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(normalizedFilter.toLowerCase()),
      ),
    );
  }

  const columns: TableColumn<Model>[] = [
    {
      name: `${t('groupManagement.tableHeaders.id')}`,
      selector: (row) => row.id!,
      sortable: true,
      width: '60px',
    },
    {
      name: `${t('groupManagement.tableHeaders.name')}`,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: `${t('groupManagement.tableHeaders.actions')}`,
      cell: (row) => (
        <div className="d-grid d-flex gap-2">
          <ActionButton type='edit' onClick={() => navigate(`/security/groupmanagement/${row.id}`)} roles={[RolesDictionary.Security.GroupManagement]} />
          <ActionButton type='delete' onClick={() => handleShowDeleteModal(row.id!)} roles={[RolesDictionary.Security.GroupManagement]} />
        </div>
      ),
      center: true,
      width: '100px'
    },
  ];

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const deleteDataById = async () => {
    try {
      setIsLoading(true);
      const result = await Service.deleteGroupById(deleteId);
      if (result && !result.hasErrors) {
        toast.success(`${t('crud.delete.successMessage')}`);
      } else {
        toast.error(`${t('crud.delete.errorMessage')}`);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setToggleCleared(!toggleCleared);
      handleCloseDeleteModal();
      fetchUsers();
      setIsLoading(false);
    }
  };

  const contextActions = useMemo(() => {
    const deleteSelectedRows = () => {
      setShowDeleteModal(true);
      selectedRows.forEach((row) => {
        setDeleteId(row.id!);
      });
    }

    return (
      <button type="button" className="btn btn-sm btn-danger" onClick={deleteSelectedRows}>
        {t('crud.delete.buttonSave')}
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight>
          <div className="d-flex align-items-center">
            <Link to="/security/groupmanagement/add" className="btn btn-sm btn-primary fw-bolder">
              {t('groupManagement.toolbar.addClient')}
            </Link>
          </div>
        </ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <div className="card card-flush">
          <div className="card-body">
            <DataTable
              title={tableHelper.getTableHeader((e: any) => FilterByName(e.target.value))}
              persistTableHead={true}
              columns={columns}
              data={filteredTableData}
              noDataComponent={tableHelper.getNoDataComponent()}
              selectableRows
              selectableRowsHighlight
              dense
              pagination
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
              paginationPerPage={tableHelper.defaultRowsPerPage}
              paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
              highlightOnHover
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              contextActions={contextActions}
              contextMessage={tableHelper.getContextMessage()}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
              selectableRowsVisibleOnly={true}
              customStyles={itLabTableStyle}
            />
          </div>
        </div>

        <DeleteModal
          type='delete'
          handleDelete={deleteDataById}
          onClose={handleCloseDeleteModal}
          open={showDeleteModal}
        />
      </MainLayout>
    </MasterLayout>
  );
}
