import { createContext } from "react";
import { NotificationType } from "../model/notification.model";

export interface NotificationFilter {
    type?: NotificationType;
    read?: boolean
}

export interface NotificationContextValue {
    filter: NotificationFilter;
    setFilter(filter: NotificationFilter): void;
}

const NotificationContext = createContext<NotificationContextValue>({
    filter: {},
    setFilter: () => {}
});

export default NotificationContext;