import { http } from 'common/helpers/axios/axios-helper';
import { ProcessStepsModel as Model } from '../model/process-steps.model';

const basePath = '/Step';

async function getDataList() {
  return await http.get<Model[]>(`${basePath}`);
}

async function getDataById(id: any) {
  return await http.get<Model>(`${basePath}/${id}`);
}

async function allowUpload() {
  return await http.get<boolean>(`${basePath}/allowUpload`);
}

async function getDataByProcessId(id: any) {
  return await http.get<any>(`process/${id}/step`);
}

async function postData(data: Model) {
  return await http.post<Model>(`${basePath}`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function putData(data: Model) {
  return await http.put<Model>(`${basePath}/${data.id}`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function deleteDataById(id: any) {
  return await http.del(`${basePath}/${id}`);
}

export const ProcessStepsService = {
  getDataList,
  getDataById,
  getDataByProcessId,
  postData,
  putData,
  deleteDataById,
  allowUpload
};
