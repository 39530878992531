/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from 'yup';
import { useState } from 'react'
import { useFormik } from 'formik';
import ITSVG from 'common/helpers/ITSVG'
import { SchedulePutTimeViewModel } from '../../../model/schedule.model'
import { ScheduleService as Service } from '../../../services/schedule.service'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import closeModalIcon from 'assets/images/icons/close.svg'
import { EventClickArg } from '@fullcalendar/react';
import en from '../../../i18n/en-us.json';
import pt from '../../../i18n/pt-br.json';
import es from '../../../i18n/es-es.json';
import i18next from 'i18next'
import moment from 'moment-timezone';

interface Props {
    open: boolean;
    onClose: () => void;
    onCloseActionsModal: () => void;
    handleDelete?: (event: any) => void;
    event: EventClickArg;
    fetchEvents?: () => void;
}

export function EditScheduleModal(props: Props) {
    i18next.addResourceBundle('us', 'translation', en);
    i18next.addResourceBundle('br', 'translation', pt);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('editOccurrence');

    let newEventStartTimeToDate = moment.utc(props.event.event._def.extendedProps.eventStart).toDate()
    let newEventEndTimeToDate = moment.utc(props.event.event._def.extendedProps.eventStart).add(props.event.event._def.extendedProps.stepAverageTime, 'seconds').toDate()

    const initialData: SchedulePutTimeViewModel = {
        newStartDate: moment(props.event.event._instance?.range.start).format('YYYY-MM-DD'),
        newStartTime: moment.utc(props.event.event._instance?.range.start).format('HH:mm'),
        newEventStartDate: props.event.event._def.extendedProps.eventStart,
        newEventStartTime: moment(newEventStartTimeToDate).format('HH:mm'),
        newEventEndDate: props.event.event._def.extendedProps.eventEnd ? moment(props.event.event._def.extendedProps.eventEnd).format('YYYY-MM-DD') : '',
        newEventEndTime: moment(newEventEndTimeToDate).format('HH:mm'),
        allEvents: false
    }

    const [data] = useState<SchedulePutTimeViewModel>(initialData);

    const formSchema = Yup.object().shape({
        newStartDate: Yup.date().min(moment(new Date()).format('YYYY-MM-DD'), t('crud.validators.minStartDate')).required(t('crud.validators.requiredField')),
        newStartTime: Yup.string()
            .required(t('crud.validators.requiredField'))
            .test('minStartDate', `${t('crud.validators.minStartDate')}`, function (value) {
                const { newStartDate } = this.parent;
                let newStartDateMoment = moment(newStartDate).format('YYYY-MM-DD');
                let newStartDateFormated = moment(newStartDateMoment + ' ' + value, 'YYYY-MM-DD HH:mm').toDate();
                return moment(newStartDateFormated, "HH:mm").isSameOrAfter(moment(new Date(), "HH:mm"));
            })
    })

    const formik = useFormik<SchedulePutTimeViewModel>({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: formSchema,
        onSubmit: async (values) => {
            try {
                setIsLoading(true);
                let startDate = moment(values.newStartDate).format('YYYY-MM-DD');
                let startTime = values.newStartTime;
                const id = parseInt(props.event.event.id);
                const newStartDate = moment(startDate + ' ' + startTime).utc().format('YYYY-MM-DDTHH:mm');
                const newEndDate = moment(startDate + ' ' + startTime).utc().format('YYYY-MM-DDTHH:mm');
                const oldStartDate = moment.utc(props.event.event.start).format('YYYY-MM-DDTHH:mm');
                const oldEndDate = props.event.event.end ? moment.utc(props.event.event.end).format('YYYY-MM-DDTHH:mm:ss') : moment.utc(props.event.event.start).format('YYYY-MM-DDTHH:mm');
                const rescheduleId = props.event.event._def.extendedProps.rescheduleId ?? null;

                const body: SchedulePutTimeViewModel = {
                    id: id,
                    newStartDate: newStartDate,
                    newEndDate: newEndDate,
                    oldStartDate: oldStartDate,
                    oldEndDate: oldEndDate,
                    allEvents: false,
                    rescheduleId: rescheduleId
                }

                let newEventStartDate = moment(values.newEventStartDate).format('YYYY-MM-DD');
                let newEventStartTime = values.newEventStartTime;
                let newEventEndDate = moment(values.newEventEndDate).format('YYYY-MM-DD');
                let newEventEndTime = values.newEventEndTime;

                if (activeTab === 'editEvent') {
                    body.newStartDate = moment(newEventStartDate + ' ' + newEventStartTime).utc().format('YYYY-MM-DDTHH:mm');
                    body.newEndDate = values.newEventEndDate ? moment(newEventEndDate + ' ' + newEventEndTime).utc().format('YYYY-MM-DDTHH:mm') : '';
                    body.allEvents = true;
                }

                const result = await Service.putDataTime(body);
                if (result && !result?.hasErrors) {
                    toast.success(`${t('crud.update.successMessage')}`);
                } else {
                    toast.error(`${t(`error.${result?.message}`)}`);
                }

            } catch (error) {
                toast.error(`${t('crud.update.errorMessage')}`);
            } finally {
                props.fetchEvents?.();
                props.onClose();
                props.onCloseActionsModal();
                setIsLoading(false);
            }
        },
    });

    return (
        <Modal
            show={props.open}
            onHide={props.onClose}
            dialogClassName='modal-dialog-centered mw-900px h-auto'
        >
            <div className='modal-content'>
                <div className='modal-header'>
                    <h2>{t('scheduleModal.update')}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.onClose}>
                        <ITSVG path={closeModalIcon} className='svg-icon-1' />
                    </div>
                </div>
                <form className='form' onSubmit={formik.handleSubmit}>
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className='flex-row-fluid px-lg-15'>
                            <div className='row'>
                                <div className='fv-row mb-10'>
                                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                        <span>{t('scheduleModal.fields.processStep.label')}</span>
                                    </label>
                                    <input
                                        name='stepName'
                                        className='form-control form-control-solid'
                                        value={props.event.event._def.extendedProps.stepName}
                                        disabled={true}
                                    />
                                </div>
                                <div className="fv-row">
                                    <ul className="nav nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bolder mb-n2 ">
                                        <div className="d-flex flex-row justify-content-between ">
                                            <li className="nav-item cursor-pointer">
                                                <a
                                                    className={`nav-link text-active-primary pb-4 mb-10 ${activeTab === 'editOccurrence' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        setActiveTab('editOccurrence');
                                                    }}
                                                >
                                                    {t('editModal.editOccurrence')}
                                                </a>
                                            </li>
                                            {!props.event.event._def.extendedProps.singleEvent && (
                                                <li className="nav-item cursor-pointer">
                                                    <a
                                                        className={`nav-link text-active-primary pb-4 mb-10 ${activeTab === 'editEvent' ? 'active' : ''}`}
                                                        onClick={() => {
                                                            setActiveTab('editEvent');
                                                        }}
                                                    >
                                                        {t('editModal.editEvent')}
                                                    </a>
                                                </li>
                                            )}
                                        </div>
                                    </ul>
                                </div>
                                {activeTab === 'editOccurrence' && (
                                    <>
                                        <div className='col-6 mb-10'>
                                            <label className='fs-5 fw-bold mb-2 required'>{t('scheduleModal.fields.startDate.label')}</label>
                                            <input
                                                className='form-control form-control-solid'
                                                type='date'
                                                name='newStartDate'
                                                value={moment(formik.values.newStartDate).format('YYYY-MM-DD')}
                                                onChange={formik.handleChange}
                                                min={moment(new Date()).format('YYYY-MM-DD')}
                                            />
                                            {formik.touched.newStartDate && formik.errors.newStartDate && (
                                                <div className="mt-3 text-danger fw-bold">{formik.errors.newStartDate}</div>
                                            )}
                                        </div>
                                        <div className='col-6 mb-10'>
                                            <label className='fs-5 fw-bold mb-2 required'>{t('scheduleModal.fields.startTime.label')}</label>
                                            <input
                                                type='time'
                                                name='newStartTime'
                                                value={formik.values.newStartTime}
                                                onChange={formik.handleChange}
                                                className='form-control form-control-solid'
                                            />
                                            {formik.touched.newStartTime && formik.errors.newStartTime && (
                                                <div className="mt-3 text-danger fw-bold">{formik.errors.newStartTime}</div>
                                            )}
                                        </div>
                                    </>
                                )}
                                {activeTab === 'editEvent' && !props.event.event._def.extendedProps.singleEvent && (
                                    <>
                                        <div className='col-6 mb-10'>
                                            <label className='fs-5 fw-bold mb-2 required'>{t('scheduleModal.fields.eventStartDate')}</label>
                                            <input
                                                className='form-control form-control-solid'
                                                type='date'
                                                name='newEventStartDate'
                                                value={moment(formik.values.newEventStartDate).format('YYYY-MM-DD')}
                                                onChange={formik.handleChange}
                                                min={moment(new Date()).format('YYYY-MM-DD')}
                                            />
                                            {formik.touched.newEventStartDate && formik.errors.newEventStartDate && (
                                                <div className="mt-3 text-danger fw-bold">{formik.errors.newEventStartDate}</div>
                                            )}
                                        </div>
                                        <div className='col-6 mb-10'>
                                            <label className='fs-5 fw-bold mb-2'>{t('scheduleModal.fields.eventStartTime')}</label>
                                            <input
                                                className='form-control form-control-solid'
                                                type='time'
                                                name='newEventStartTime'
                                                value={formik.values.newEventStartTime}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className='col-6 mb-10'>
                                            <label className='fs-5 fw-bold mb-2 required'>{t('scheduleModal.fields.eventEndDate')}</label>
                                            <input
                                                className='form-control form-control-solid'
                                                type='date'
                                                name='newEventEndDate'
                                                value={moment(formik.values.newEventEndDate).format('YYYY-MM-DD')}
                                                onChange={formik.handleChange}
                                                min={moment(new Date()).format('YYYY-MM-DD')}
                                            />
                                            {formik.touched.newEventEndDate && formik.errors.newEventEndDate && (
                                                <div className="mt-3 text-danger fw-bold">{formik.errors.newEventEndDate}</div>
                                            )}
                                        </div>
                                        <div className='col-6 mb-10'>
                                            <label className='fs-5 fw-bold mb-2'>{t('scheduleModal.fields.eventEndTime')}</label>
                                            <input
                                                className='form-control form-control-solid'
                                                type='time'
                                                name='newEventEndTime'
                                                value={formik.values.newEventStartTime}
                                                disabled
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button type='submit' className='btn btn-lg btn-primary' disabled={isLoading}>
                                    <span className='indicator-label'>
                                        {t('crud.create.buttonSave')}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}