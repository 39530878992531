import { useContext, useState } from 'react';
import { LoadingContext } from './loading.context';
import { LoadingContextModel } from './loading.model';

export function useLoadingState(): LoadingContextModel {
  const [loading, isLoading] = useState(false);

  return { loading, isLoading };
}

export function useLoading(): LoadingContextModel {
  return useContext(LoadingContext);
}
