import { NotificationModel } from "./alerts.model";
import { formatTimeSince } from "../dateUtils";
import { useTranslation } from "react-i18next";
import { AlertTypeIcon } from "./alert-type-icon";
import { Link } from "react-router-dom";

interface AlertItemProps {
  notification: NotificationModel;
  viewed: (notification: NotificationModel) => void;
}

export function AlertItem(props: AlertItemProps) {
  const { t } = useTranslation();
  const departmentsCount = props.notification
                           .departments
                           .split("|")
                           .length;

  const viewed = props.notification.viewed;
  const classNameItem = `d-flex flex-stack py-4 mt-1 ${viewed ? "" : "bg-light"}`;

  return (
    <>
      <Link to={`/notifications/center/${props.notification.id}`}>
        <div className={classNameItem} onMouseEnter={() => {
            props.viewed(props.notification)
            props.notification.viewed = true;
          }
        }>
          <div className="d-flex align-items-left">
            {
              !props.notification.read &&
              <span className="bullet bullet-dot bg-success h-6px w-6px translate-middle start-50 animation-blink align-self-center"></span>
            }
            <div className="symbol symbol-35px me-4 ms-2">
              <AlertTypeIcon notificationTypeName={props.notification.type} />
            </div>
            <div className="mb-0 me-2" style={{"textAlign": "start"}}>
              <div className="fs-6 text-gray-800 text-hover-primary fw-bold">
                {
                  props.notification.read ? (
                    <>{props.notification.title}</>
                  ) : (
                    <strong>{props.notification.title}</strong>
                  )
                }
              </div>
              <div className="text-gray-500 fs-7" title={props.notification.departments}>{departmentsCount} {`${t("alerts.department")}`}</div>
            </div>
          </div>
          <span className="badge badge-light fs-8">{formatTimeSince(props.notification.creationDate, t)}</span>
        </div>
      </Link>
    </>
  );
}