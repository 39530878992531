/* eslint-disable jsx-a11y/control-has-associated-label */
import { CSSProperties } from "react";
import getEnumEntries from "app/shared/utility/functions/getEnumEntries";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    id?: string;
    label: string;
    enum: any;
    enumName:string;
    value?: number;
    width?: string;
    onChange(selected?: number): void;
    disabled?: boolean;
    placeholderText?: string;
    include?: number[];
    exclude?: number[];
    className?: string;
    style?: CSSProperties;
}

export default function EnumDropdown(props: Props) {
    const { t } = useTranslation();
    const exclude = props.exclude ?? [];
    const options = getEnumEntries(props.enum)
        .filter(kvp => !props.include || props.include.includes(kvp.value))
        .filter(kvp => !exclude.includes(kvp.value))
        .map(kvp => ({ label: t(`enum.${props.enumName.toLowerCase()}.${kvp.key}`), value: kvp.value }));

    return (
        <FormControl variant="filled" style={{ width: `${props.width ?? "100%"}` }}>
            <label className="form-label required me-3">{props.label}</label>
            <Autocomplete
                id={props.id}
                options={options}
                isOptionEqualToValue={(option) => option.value === props.value}
                onChange={(e, value) => {
                    e.stopPropagation();
                    props.onChange(value?.value);
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        placeholder={props.placeholderText}
                    />
                }
            />
        </FormControl>
    );
}