/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import doubleArrow from 'assets/images/icons/arr080.svg'
import AppLogo from 'common/partials/AppLogo'
import ITSVG from 'common/helpers/ITSVG'
import { useLayout } from '../../core'
import { AsideMenu } from './AsideMenu'


export function AsideDefault() {

  const { config, classes } = useLayout()
  const { aside } = config

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '), 'aside-dark')}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >

      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>

        <Link to='/dashboard'>
          <AppLogo className='h-35px logo' />
        </Link>

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <ITSVG path={doubleArrow} className='svg-icon-1 rotate-180' />
          </div>
        )}
      </div>

      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>

    </div>
  )
}
