import moment from "moment";
import { useTranslation } from "react-i18next";
import { SchedulePostRequestViewModel } from "../../../model/schedule.model";
import { EventApi } from "@fullcalendar/react";
import { useLoading } from "common/loading/loading.hook";
import { ScheduleService as Service } from "../../../services/schedule.service"
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Autocomplete, TextField } from "@mui/material";
import en from "../../../i18n/en-us.json";
import pt from "../../../i18n/pt-br.json";
import i18next from "i18next"
import { DepartamentsModel } from "app/departaments/model/departaments.model";
import { ProcessStepsModel } from "app/processes/components/childs/processes-steps/model/process-steps.model";
import { FormikErrors, FormikTouched } from "formik";

interface Props {
  selectedEvent?: EventApi;
  startTime?: string;
  setStartTime?: (event: any) => void;
  handleChange: (event: any) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  values: SchedulePostRequestViewModel;
  touched: FormikTouched<SchedulePostRequestViewModel>;
  setTouched: (touched: FormikTouched<SchedulePostRequestViewModel>, shouldValidate?: boolean | undefined) => Promise<FormikErrors<SchedulePostRequestViewModel>> | Promise<void>;
  errors?: any;
  setStepId: (event: any) => void;
  setProcessId: (event: any) => void;
}

export function Step1(props: Props) {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const { isLoading } = useLoading();
  const [departments, setDepartaments] = useState<DepartamentsModel[]>([]);
  const [processSteps, setProcessSteps] = useState<ProcessStepsModel[]>([]);

  const fetchDepartaments = () => {
    (async () => {
      try {
        isLoading(true);
        const response = await Service.getDepartamentsList();
        if (response && !response.hasErrors) {
          setDepartaments(response.data);
        }
      } catch (error) {
        toast.error(`${t("crud.read.errorMessage")}`);
      } finally {
        isLoading(false);
      }
    })();
  }

  const handleProcessSteps = (processId: number) => {
    (async () => {
      try {
        isLoading(true);
        const response = await Service.getProcessStepsList(processId);
        if (response && !response.hasErrors) {
          setProcessSteps(response.data);
        }
      } catch (error) {
        toast.error(`${t("crud.update.errorMessage")}`);
      } finally {
        isLoading(false);
      }
    })();
  }

  const handleStepId = (stepId: number) => {
    props.setStepId(stepId);
    const getProcessId = processSteps.find((item: any) => item.id === stepId)
    props.setProcessId(getProcessId?.processId);
  }

  useEffect(() => {
    fetchDepartaments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row">
      <div className="col-12 mb-8">
        <label className="d-flex align-items-center fw-bold fs-6 mb-2">
          <span className="required">{t("scheduleModal.fields.departament.label")}</span>
          <i
            className="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title={t("scheduleModal.fields.departament.tooltip")}
          ></i>
        </label>
        <Autocomplete
          id="department"
          options={departments}
          getOptionLabel={option => option.name ? option.name : ""}
          onChange={(_, value) => {
            handleProcessSteps(value?.id!);
            props.setFieldValue("department", value?.id)
          }}
          onInputChange={(_, value) => {
            if (!value) {
              props.setFieldValue("department", undefined)
            }
          }}
          onBlur={() => props.setTouched({ department: true })}
          renderInput={(params) =>
            <TextField
              {...params}
              placeholder={`${t("generalMessages.select")}`}
            />
          }
        />
        {props.touched.department && props.errors.department && (
          <div className="mt-3 text-danger fw-bold">{props.errors.department}</div>
        )}
      </div>
      <div className="col-12 mb-8">
        <label className="d-flex align-items-center fw-bold fs-6 mb-2">
          <span className="required">{t("scheduleModal.fields.processStep.label")}</span>
          <i
            className="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title={t("scheduleModal.fields.processStep.tooltip")}
          ></i>
        </label>
        <Autocomplete
          id="stepId"
          disabled={processSteps.length === 0}
          options={processSteps}
          getOptionLabel={option => option.name ? option.name : ""}
          onChange={(_, value) => {
            handleStepId(value?.id!);
            props.setFieldValue("stepId", value?.id)
          }}
          onInputChange={(_, value) => {
            if (!value) {
              props.setFieldValue("stepId", undefined)
            }
          }}
          onBlur={() => props.setTouched({ stepId: true })}
          renderInput={(params) =>
            <TextField
              {...params}
              placeholder={`${t("generalMessages.select")}`}
            />
          }
        />
        {props.touched.stepId && props.errors.stepId && (
          <div className="mt-3 text-danger fw-bold">{props.errors.stepId}</div>
        )}
      </div>
      <div className="col-6 mb-8">
        <label className="d-flex align-items-center fw-bold fs-6 mb-2">
          <span className="required">{t("scheduleModal.fields.startDate.label")}</span>
          <i
            className="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title={t("scheduleModal.fields.startDate.tooltip")}
          ></i>
        </label>
        <input
          className="form-control form-control-solid"
          type="date"
          name="startDate"
          value={moment(props.values.startDate).format("YYYY-MM-DD")}
          onChange={props.handleChange}
          min={moment(new Date()).format("YYYY-MM-DD")}
          required
        />
        {props.errors.startDate && (
          <div className="mt-3 text-danger fw-bold">
            {props.errors.startDate}
          </div>
        )}
      </div>
      <div className="col-6 mb-8">
        <label className="d-flex align-items-center fw-bold fs-6 mb-2">
          <span className="required">{t("scheduleModal.fields.startTime.label")}</span>
          <i
            className="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title={t("scheduleModal.fields.startTime.tooltip")}
          ></i>
        </label>
        <input
          className="form-control form-control-solid"
          type="time"
          name="startTime"
          value={props.values.startTime}
          onChange={(e) => props.handleChange(e)}
          required
        />

        {props.errors.startTime && (
          <div className="mt-3 text-danger fw-bold">
            {props.errors.startTime}
          </div>
        )}
      </div>
    </div>
  )
}
