interface Props {
    children?: React.ReactNode;
}

export function ToolbarLayout(props: Props) {

    return (
        <div className="toolbar flex-wrap" id="kt-toolbar">
            <div className="container-fluid d-flex flex-start flex-lg-stack">
                {props.children}
            </div>
        </div>
    );
}