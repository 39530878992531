import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { MachineAndStepStatisticsModel as Model } from "app/dashboard/model/dashboard.model"
import LoadingSpinner from "common/partials/LoadingSpinner"
import { DashboardService as Service } from "../../services/dashboard.service"
import i18next from "i18next"
import en from "../../i18n/en-us.json";
import pt from "../../i18n/pt-br.json";

type Props = {
  className: string
}

export function MachineStatistics(props: Props) {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const initialData: Model = {
    totalQuantity: 0,
    active: 0,
    inactive: 0,
    percentActive: 0,
    percentInactive: 0
  }

  const [machineStatistics, setMachineStatistics] = useState<Model>(initialData);

  const fetchMachineStatistics = async () => {
    setIsLoading(true);
    const response = await Service.getMachineStatistics();
    if (response && !response.hasErrors) {
      setMachineStatistics(response.data);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchMachineStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Link to={"/infrastructure/machineActivity"} className={`card ${props.className}`}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          <div className="d-flex flex-column">
            {
              isLoading
                ? <LoadingSpinner loading={isLoading} align="start" />
                : <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{machineStatistics.active}</span>
            }
            <div className="m-0">
              <span className="fw-semibold fs-6 text-gray-400">{t("machineStatistics.title")}</span>
            </div>
          </div>
          <div className="m-0">
            <i className="fa-solid fa-desktop fs-2x text-primary"></i>
          </div>
        </div>
        <div className="d-flex align-items-center flex-column mt-3 w-100" title={t("machineStatistics.message", { quantity: `${machineStatistics.totalQuantity - machineStatistics.active}` })}>
          {
            isLoading
              ? <LoadingSpinner loading={isLoading} />
              : (
                <>
                  <div className="d-flex justify-content-between fw-bold fs-6 text-dark opacity-50 w-100 mt-auto mb-2">
                    <span>{machineStatistics.active + "/" + machineStatistics.totalQuantity}</span>
                    <span>{machineStatistics.percentActive + "%"}</span>
                  </div>
                  <div className="progress h-8px mx-3 w-100 bg-light-primary rounded">
                    <div className="progress-bar bg-primary rounded h-8px" role="progressbar" style={{ width: `${machineStatistics.percentActive}%` }}></div>
                  </div>
                </>
              )}
        </div>
      </div>
    </Link>
  )
}