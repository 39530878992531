import { ConnectedAgents } from "./ConnectedAgents";
import { ExecutionLog } from "./ExecutionLog/ExecutionLog";
import { FailedExecutions } from "./FailedExecutions";
import { MachineStatistics } from "./MachineStatistics";
import { RobotLastExecutions } from "./RobotLastExecutions";
import { RobotNextExecutions } from "./RobotNextExecutions";
import { StepsStatistics } from "./StepStatistics";
import { SuspiciousExecutions } from "./SuspiciousExecutions";
import { PinnedNotifications } from "./PinnedNotifications";

export default function DashboardBody() {
  return (
    <div className="row gy-5 gy-xl-4">
      <div>
        <PinnedNotifications />
      </div>
      <div className="col-xl-6 col-xxl-3">
        <MachineStatistics
          className="card-xl-stretch mb-xl-8"
        />
      </div>
      <div className="col-xl-6 col-xxl-2">
        <ConnectedAgents
          className="card-xl-stretch mb-xl-8"
        />
      </div>
      <div className="col-xl-4 col-xxl-3">
        <StepsStatistics
          className="card-xl-stretch mb-xl-8"
        />
      </div>
      <div className="col-xl-4 col-xxl-2">
        <SuspiciousExecutions
          className="card-xl-stretch mb-xl-8"
        />
      </div>
      <div className="col-xl-4 col-xxl-2">
        <FailedExecutions
          className="card-xl-stretch mb-xl-8"
        />
      </div>
      <div className="col-xl-12 mb-xl-4">
        <ExecutionLog />
      </div>
      <div className="col-xl-6">
        <RobotLastExecutions
          className="card-xl-stretch mb-xl-8"
        />
      </div>
      <div className="col-xl-6">
        <RobotNextExecutions
          className="card-xl-stretch mb-xl-8"
        />
      </div>
    </div>
  );
}