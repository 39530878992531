import { http } from 'common/helpers/axios/axios-helper';
import { DepartmentSelectorModel, UserAndDepartmentsModel } from '../model/department-selector.model';

const basePath = '/department';

async function getDepartments() {
  return await http.get<DepartmentSelectorModel[]>(basePath);
}

async function getUserAndDepartments(userId: number) {
  return await http.get<UserAndDepartmentsModel>(`/User/${userId}/alldepartments`);
}

async function putDepartments(userId: number, departments: number[]) {
  let data = { userId: userId, departmentId: departments };
  return await http.put(`user/updateDepartmentUser`, data);
}

export const DepartmentSelectorService = { getDepartments, getUserAndDepartments, putDepartments };