import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import appsettings from 'appsettings.json';
import { API_URL } from 'config';
import { CardItemModel } from 'common/layout/components/card/model/card.model';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import { format } from 'common/helpers/number';
import { Card } from 'common/layout/components/card/Card';
import { HealthCheckService } from '../../services/health-check.service';
import { FrontEndConfigModel } from '../../model/health-check.model';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';

let frontendArray: CardItemModel[] = [];

export function FrontendHealthCheck() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t, i18n } = useTranslation();

  const [frontendState, setFrontendState] = useState<CardItemModel[]>([]);
  const [frontEndData, setFrontEndData] = useState<FrontEndConfigModel>();

  function buildFrontEndItems(data: FrontEndConfigModel | undefined): void {
    if (!data) {
      return;
    }

    const tokenHelper = new TokenHelper();

    frontendArray = [];
    frontendArray.push({ title: t('healthCheck.frontend.appName'), value: appsettings['application-name'] });
    frontendArray.push({ title: t('healthCheck.frontend.appVersion'), value: appsettings['application-version'] });
    frontendArray.push({ title: t('healthCheck.frontend.templateVersion'), value: 'v1.3' });
    frontendArray.push({
      title: t('healthCheck.frontend.apiAddress'),
      value: API_URL,
    });
    frontendArray.push({
      title: t('healthCheck.frontend.defaultLanguage'),
      value: data.defaultLanguage.toLowerCase() === 'us' ? 'English' : 'Português',
    });
    frontendArray.push({
      title: t('healthCheck.frontend.language'),
      // value: i18n.language.toLowerCase() === 'us' ? 'English' : 'Português',
      value: tokenHelper.GetLanguage().toLowerCase() === 'us' ? 'English' : 'Português',
    });
    frontendArray.push({
      title: t('healthCheck.frontend.cacheEnabled'),
      value: data.cacheEnabled ? t('generalMessages.yes') : t('generalMessages.no'),
    });
    frontendArray.push({ title: t('healthCheck.frontend.actualDatetime'), value: new Date().toString() });
    frontendArray.push({
      title: t('healthCheck.frontend.utcOffset'),
      value: new Date().getHours() - new Date().getUTCHours(),
    });
    frontendArray.push({ title: t('healthCheck.frontend.actualUtcDatetime'), value: new Date().toUTCString() });
    frontendArray.push({
      title: t('healthCheck.frontend.numericFormat'),
      value: format(i18n, 123456.789, 2),
    });

    setFrontendState(frontendArray);
  }

  useEffect(() => {
    (async () => {
      buildFrontEndItems(frontEndData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, frontEndData]);

  useEffect(() => {
    (async () => {
      try {
        const frontendResponse = await HealthCheckService.getFrontendConfig();
        if (frontendResponse && !frontendResponse.hasErrors) {
          setFrontEndData(frontendResponse.data);
        }
      } catch (err) {
        toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card title={t('healthCheck.frontend.title')} items={frontendState} headerTopRightElement={<span className="badge badge-light-success fs-6">{t('healthCheck.tests.success')}</span>}></Card>
    </>
  );
}
