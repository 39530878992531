import i18next from 'i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tableHelper } from 'common/helpers/table/table-helper';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import { DeleteModal } from 'common/partials/DeleteModal';
import ActionButton from 'common/partials/ActionButton';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { DepartamentsLinkModel as Model, DepartmentsLinkUpdateModel } from '../model/departaments-link.model';
import { DepartamentsLinkService as Service } from '../services/departaments-link.service';
import { useParams } from 'react-router-dom';

interface Props {
  handleOpen: (id: number) => void;
  isFetching: boolean;
}

export function DepartamentsLink(props: Props) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const { id } = useParams();
  const [tableData, setTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Model[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [deleteBody, setDeleteBody] = useState<DepartmentsLinkUpdateModel>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = (deleteBody: DepartmentsLinkUpdateModel) => {
    setShowDeleteModal(true);
    setDeleteBody(deleteBody);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await Service.getDataList(parseInt(id ?? '0'));
      if (response && !response.hasErrors) {
        setTableData(response.data);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: TableColumn<Model>[] = [
    {
      name: `${t('table.id')}`,
      selector: (row) => row.id || '',
      sortable: true,
      width: '60px',
    },
    {
      name: `${t('table.name')}`,
      selector: (row) => row.name || '',
      sortable: true,
    },
    {
      name: `${t('table.status')}`,
      cell: (row) =>
        row.isActive ? (
          <span className="badge badge-light-primary">{t('generalMessages.active')}</span>
        ) : (
          <span className="badge badge-light-danger">{t('generalMessages.inactive')}</span>
        ),
      center: true,
    },
    {
      name: `${t('table.actions')}`,
      cell: (row) => (
        <div className="d-grid d-flex gap-2">
          <ActionButton type='delete' onClick={() => handleShowDeleteModal({ processId: parseInt(id ?? '0'), departmentId: row.id! })} roles={[RolesDictionary.Infrastructure.Machines_Update]} />
        </div>
      ),
      center: true,
      width: '100px'
    },
  ];

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const deleteDataById = async () => {
    try {
      setIsLoading(true);
      const result = await Service.deleteDataById(deleteBody);
      if (result && !result.hasErrors) {
        toast.success(`${t('crud.delete.successMessage')}`);
      } else {
        toast.error(`${t('crud.delete.errorMessage')}`);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setToggleCleared(!toggleCleared);
      handleCloseDeleteModal();
      fetchData();
      setIsLoading(false);
    }
  };

  const contextActions = useMemo(() => {
    const deleteSelectedRows = () => {
      setShowDeleteModal(true);
      selectedRows.forEach((row) => {
        const deleteBody: DepartmentsLinkUpdateModel = {
          processId: parseInt(id ?? '0'),
          departmentId: row.id!
        }
        setDeleteBody(deleteBody);
      });
    }

    return (
      <button type="button" className="btn btn-sm btn-danger" onClick={deleteSelectedRows}>
        {t('crud.delete.buttonSave')}
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isFetching]);

  return (
    <div className="card card-flush shadow-none mt-10">
      <div className="card-body p-0">
        <DataTable
          title={selectedRows.length === 0 ? false : <></>}
          persistTableHead={true}
          columns={columns}
          data={tableData}
          noDataComponent={tableHelper.getNoDataComponent()}
          selectableRows
          pagination
          paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
          highlightOnHover
          pointerOnHover
          progressPending={isLoading}
          progressComponent={tableHelper.getLoader()}
          contextActions={contextActions}
          contextMessage={tableHelper.getContextMessage()}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleCleared}
          selectableRowsVisibleOnly={true}
          customStyles={itLabTableStyle}
          dense
        />
      </div>

      <DeleteModal
        type='delete'
        handleDelete={deleteDataById}
        onClose={handleCloseDeleteModal}
        open={showDeleteModal}
      />
    </div>
  );
}
