import { createContext, Dispatch, SetStateAction } from "react";
import { FetchEventsFilter, ScheduleViewModel } from "../model/schedule.model";
import { DatesSetArg } from "@fullcalendar/react";

export interface ContextOptions {
    currentView: "calendar" | "list";
    calendarViewType: string;
    isCompactMode: boolean;
    isWeekendsVisible: boolean;
}

interface ScheduleContextValue {
    fetchEvents(modalStepId?: number, fetchEventsFilter?: FetchEventsFilter): void;
    scheduleData: ScheduleViewModel[];
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    isFirstFetch: boolean;
    startEndDate?: DatesSetArg;
    setStartEndDate: Dispatch<SetStateAction<DatesSetArg | undefined>>;
    clickedDate: {};
    setClickedDate: Dispatch<SetStateAction<{}>>;
    setScheduleFilter: Dispatch<SetStateAction<FetchEventsFilter | undefined>>;
    setOpenCreateScheduleModal: Dispatch<SetStateAction<boolean>>;
    contextOptions?: ContextOptions;
    setContextOptions: Dispatch<SetStateAction<ContextOptions>>;
    
}

const ScheduleContext = createContext<ScheduleContextValue>({
    fetchEvents: (modalStepId?: number, fetchEventsFilter?: FetchEventsFilter) => { },
    scheduleData: [],
    isLoading: false,
    setIsLoading: () => { },
    isFirstFetch: false,
    startEndDate: undefined,
    setStartEndDate: () => { },
    clickedDate: {},
    setClickedDate: () => { },
    setScheduleFilter: () => { },
    setOpenCreateScheduleModal: () => { },
    contextOptions: undefined,
    setContextOptions: () => { }
});

export default ScheduleContext;