import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap'
import closeModalIcon from 'assets/images/icons/close.svg'
import ITSVG from 'common/helpers/ITSVG';
import { EventClickArg } from '@fullcalendar/react';
import { ScheduleService as Service } from 'app/schedule/services/schedule.service';
import { toast } from 'react-toastify';
import en from '../../../i18n/en-us.json';
import pt from '../../../i18n/pt-br.json';
import es from '../../../i18n/es-es.json';
import i18next from 'i18next'
import { useState } from 'react';
import { ScheduleDeleteViewModel } from 'app/schedule/model/schedule.model';
import moment from 'moment';
import 'moment/locale/pt-br';

interface Props {
  open: boolean;
  event: EventClickArg,
  onClose: () => void;
  onCloseActionsModal: () => void;
  fetchEvents: () => void;
}

export function DeleteScheduleModal(props: Props) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)

  const handleDelete = (deleteEvents: string) => {
    (async () => {
      try {
        setLoading(true);
        const rescheduleId = props.event.event._def.extendedProps.rescheduleId

        let body: ScheduleDeleteViewModel = {
          id: parseInt(props.event.event.id),
          exceptionId: props.event.event._def.extendedProps.exceptionId
        }

        if (rescheduleId > 0) {
          body.rescheduleId = rescheduleId
        }

        if (deleteEvents === 'from') {
          body.lastDateToRun = props.event.event.start!
        } else if (deleteEvents === 'single') {
          body.occurenceDateTime = props.event.event.start!
        }

        const result = await Service.deleteData(body)

        if (result && !result?.hasErrors) {
          toast.success(`${t('crud.update.successMessage')}`);
        } else {
          toast.error(`${t('crud.update.errorMessage')}`);
        }

      } catch (error) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        props.onClose();
        props.onCloseActionsModal();
        props.fetchEvents();
        setLoading(false);
      }
    })();
  }

  return (
    <Modal
      show={props.open}
      onHide={props.onClose}
      dialogClassName='modal-dialog-centered mw-500px h-auto'
    >
      <div className="card">
        <div className="modal-header">
          <h3>{t('deleteModal.title')}</h3>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.onClose}>
            <ITSVG path={closeModalIcon} className='svg-icon-1' />
          </div>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <span>{t('deleteConfirm')}</span>
          </div>
          <div className="row text-center">
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-danger my-3"
                onClick={() => handleDelete('single')}
                disabled={loading}
              >
                {`${t('deleteModal.deleteThis')} ${moment(props.event.event.start).locale(`${t('dateFormat.locale')}`).format('LLL')}`}
              </button>
            </div>
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-danger my-3"
                onClick={() => handleDelete('from')}
                disabled={loading}
              >
                {`${t('deleteModal.deleteFrom')} ${moment(props.event.event.start).locale(`${t('dateFormat.locale')}`).format('LLL')}`}
              </button>
            </div>
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-danger my-3"
                onClick={() => handleDelete('all')}
                disabled={loading}
              >
                {t('deleteModal.deleteAll')}
              </button>
            </div>
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-active-light-primary my-3 btn-block"
                onClick={props.onClose}
                disabled={loading}
              >
                {t('crud.delete.buttonCancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal >
  )
}
