import { Navigate, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../routes';
import { Login } from 'app/login/components/Login';
import { TokenHelper } from 'common/helpers/auth/token-helper';


const LoginRoute: React.FC = () => {
  const navigate = useNavigate();

  const content = (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Login onSucessfulLogin={() => navigate('/' + APP_ROUTES.dashboard)} />
        </div>
      </div>
    </div>
  );

  const userCheck = () => {
    if (new TokenHelper().HasToken()) {
      return <Navigate to={'/' + APP_ROUTES.dashboard} />;
    }
    return content;
  };

  return userCheck();
};

export default LoginRoute;
