import i18next from 'i18next';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { NotificationCenterFilter } from './notification-center-filter';
import { NotificationList } from './notification-list';
import { NotificationAdd } from './notification-add';
import { NotificationModel } from '../model/notification.model';
import ProtectedComponent from 'common/partials/ProtectedComponent';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationService as Service } from '../services/notification.service';
import { toast } from 'react-toastify';
import NotificationContext, { NotificationFilter } from '../context/notification.context';

export function NotificationCenter() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [selected, setSelected] = useState<NotificationModel | undefined>();
  const [filter, setFilter] = useState<NotificationFilter>({});

  const addNotification = () => {
    setSelected(undefined);
    setIsAddOpen(true);
  };

  const viewNotification = (model: NotificationModel) => {
    setSelected(model);
    setIsAddOpen(true);
  };

  const selectNotification = async (id: string) => {
    const response = await Service.getNotificationById(id);
    if (response && response.data) {
      setSelected(response.data)
      setIsAddOpen(true);
    } else {
      toast.error(t("notifications.errors.notFound"))
    }
  }

  const handleClose = () => {
    setSelected(undefined);
    setIsAddOpen(false);
    navigate("/notifications/center");
  }

  useEffect(() => {
    if (id) {
      selectNotification(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <MasterLayout>
      <NotificationContext.Provider value={{
        filter: filter,
        setFilter: setFilter
      }}>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight>
            <NotificationCenterFilter />
            <ProtectedComponent action='hide' role={[RolesDictionary.Notifications.Notification_Create]}>
              <button
                type='button'
                className='btn btn-sm btn-flex btn-light btn-primary fw-bolder ms-1'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                onClick={addNotification}>
                {t("notifications.newNotification")}
              </button>
            </ProtectedComponent>
          </ToolbarLayoutRight>
        </ToolbarLayout>
        <MainLayout>
          <NotificationList view={viewNotification} />
          {
            isAddOpen && (
              <NotificationAdd Close={handleClose} IsOpen={isAddOpen} notification={selected} />
            ) 
          }
        </MainLayout>
      </NotificationContext.Provider>
    </MasterLayout>
  );

}