/* eslint-disable jsx-a11y/anchor-is-valid */
import { MasterLayout, MainLayout, ToolbarLayout, ToolbarLayoutLeft, ToolbarLayoutRight } from "common/imports/content-layout";
import { useSignalR } from "common/helpers/signalR/context/signalR.hook";
import DashboardContext, { DashboardFilters, GroupedRobots } from "../context/dashboard.context";
import DashboardBody from "./childs/DashboardBody";
import { AgentLogStatus, OperationSystemSessionState, StepLogModel } from "../model/dashboard.model";
import { useCallback, useEffect, useState } from "react";
import { DashboardService as Service } from "../services/dashboard.service";
import DashboardFilterDropdown from "./DashboardFilterDropDown";

export function Dashboard() {
  const { hubConnection } = useSignalR();

  const [filteredGroupedRobots, setFilteredGroupedRobots] = useState<GroupedRobots[]>([]);
  const [groupedRobots, setGroupedRobots] = useState<GroupedRobots[]>([]);
  const [stepLogIsLoading, setStepLogIsLoading] = useState<boolean>(false);
  const [dashboardFilters, setDashboardFilters] = useState<DashboardFilters>({});
  const [IsFiltered, setIsFiltered] = useState<boolean>(false);

  const getStepLog = useCallback(async () => {
    setStepLogIsLoading(true);
    const response = await Service.getStepLog();
    if (response && !response.hasErrors) {
      const unscheduledStepsResponse = await Service.getUnscheduledSteps();
      if (unscheduledStepsResponse && !unscheduledStepsResponse.hasErrors) {
        const combinedData = [...response.data, ...unscheduledStepsResponse.data];
        const updatedGroupedRobots = mergeStepLogs([], combinedData);

        updatedGroupedRobots.forEach(robot => {
          robot.events.forEach(event => {
            if (event.realTimeLogViewModel) {
              event.percentage = event.realTimeLogViewModel.progress;
            }
          });
        });

        setFilteredGroupedRobots(updatedGroupedRobots)
        setGroupedRobots(updatedGroupedRobots);

        if (dashboardFilters) {
          setFilter(dashboardFilters)
        }
      }
    }
    setStepLogIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mergeStepLogs = (existingStepLogs: GroupedRobots[], newStepLogs: StepLogModel[]): GroupedRobots[] => {
    const existingEventsMap: Record<number, StepLogModel> = existingStepLogs.reduce((map, robot) => {
      robot.events.forEach((event) => {
        if (event.eventId !== null && event.eventId !== undefined) {
          map[event.eventId] = event;
        }
      });
      return map;
    }, {} as Record<number, StepLogModel>);

    newStepLogs.forEach((newEvent) => {
      if (newEvent.eventId !== null && newEvent.eventId !== undefined) {
        const existingEvent = existingEventsMap[newEvent.eventId];
        if (existingEvent) {
          Object.assign(existingEvent, newEvent);
        } else {
          const robotIndex = existingStepLogs.findIndex((robot) => robot.key === newEvent.department);
          if (robotIndex !== -1) {
            existingStepLogs[robotIndex].events.push(newEvent);
          } else {
            existingStepLogs.push({ key: newEvent.department, events: [newEvent] });
          }
        }
      } else {
        const robotIndex = existingStepLogs.findIndex((robot) => robot.key === newEvent.department);
        if (robotIndex !== -1) {
          existingStepLogs[robotIndex].events.push(newEvent);
        } else {
          existingStepLogs.push({ key: newEvent.department, events: [newEvent] });
        }
      }
    });

    return existingStepLogs;
  };

  const UpdateSingleItemStatusOnList = (status: AgentLogStatus, eventId: number) => {
    const updatedGroupedRobots = groupedRobots.map(robot => {
      const updatedEvents = robot.events.map((event: StepLogModel) => {
        if (event.eventId === eventId) {
          return { ...event, status: status };
        }
        return event;
      });
      return { ...robot, events: updatedEvents };
    });
    setGroupedRobots(updatedGroupedRobots);
  }

  const UpdateMachineStatusOnList = (machineName: string, sessionState: OperationSystemSessionState) => {
    const updatedGroupedRobots = groupedRobots.map(robot => {
      const updatedEvents = robot.events.map((event: StepLogModel) => {
        if (event.machineName === machineName) {
          return { ...event, operationSystemSessionState: sessionState };
        }
        return event;
      });
      return { ...robot, events: updatedEvents };
    });
    setGroupedRobots(updatedGroupedRobots);
  }

  useEffect(() => {
    getStepLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let toFilter = groupedRobots;

    if (dashboardFilters.status) {
      toFilter = toFilter.map((gr) => {
        return {
          key: gr.key,
          events: gr.events.filter((e) => e.status === dashboardFilters.status)
        }
      });
    }

    if (dashboardFilters.department) {
      toFilter = toFilter.map((gr) => {
        return {
          key: gr.key,
          events: gr.events.filter((e) => e.department === dashboardFilters.department?.name)
        }
      });
    }

    toFilter = toFilter.filter((gr) => gr.events.length > 0);

    setFilteredGroupedRobots(toFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardFilters]);

  const setFilter = (filters: DashboardFilters) => {
    setDashboardFilters(filters);
    setIsFiltered(filters.department !== undefined || filters.status !== undefined)
  }

  return (
    <MasterLayout>
      <DashboardContext.Provider value={{
        hubConnection,
        getStepLog,
        stepLogIsLoading,
        filteredGroupedRobots,
        dashboardFilters,
        UpdateSingleItemStatusOnList,
        UpdateMachineStatusOnList,
        setFilter,
        filtered: IsFiltered
      }}>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight>
            <DashboardFilterDropdown />
          </ToolbarLayoutRight>
        </ToolbarLayout>
        <MainLayout>
          <DashboardBody />
        </MainLayout>
      </DashboardContext.Provider>
    </MasterLayout>
  )
}