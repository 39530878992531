import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { PresetsModel as Model } from '../../model/general-settings.model';
import { GeneralSettingsService as Service } from '../../services/general-settings.service';
import * as Yup from 'yup';
import { useFormik } from 'formik';

export function Presets() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = Yup.object().shape({
    tableDefaultRowsPerPage: Yup.number().required(t('validation.requiredField')),
  });

  let initialData: Model = {
    tableDefaultRowsPerPage: 0
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: Model = {
          tableDefaultRowsPerPage: values.tableDefaultRowsPerPage
        };

        const result = await Service.putPresets(body);
        if (result && !result?.hasErrors) {
          toast.success(`${t('crud.update.successMessage')}`);
        } else {
          toast.error(`${t(`error.${result?.errorCode}`)}`);
        }
      } catch (err) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await Service.getPresets();
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      } catch (err) {
        toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form">
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_presets"
            aria-expanded="true"
            aria-controls="kt_presets"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{t('generalSettings.presets.title')}</h3>
            </div>
          </div>

          <div id="kt_presets" className="collapse show">
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('generalSettings.presets.tableDefaultRowsPerPage')}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="number"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('generalSettings.presets.tableDefaultRowsPerPage')}
                        {...formik.getFieldProps('tableDefaultRowsPerPage')}
                      />
                      {formik.touched.tableDefaultRowsPerPage && formik.errors.tableDefaultRowsPerPage && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.tableDefaultRowsPerPage}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="reset" className="btn btn-white btn-active-light-primary me-2">{t('crud.create.buttonCancel')}</button>
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {!isLoading && t('crud.create.buttonSave')}
                {isLoading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    {t('crud.common.wait')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
