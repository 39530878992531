import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SecurityPolicySettingsModel as Model } from '../../model/general-settings.model';
import { GeneralSettingsService as Service } from '../../services/general-settings.service';
import * as Yup from 'yup';
import { useFormik } from 'formik';

export function SecuritySettings() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = Yup.object().shape({
    passwordRequiredMinimumLength: Yup.number()
      .required(t('validation.requiredField'))
      .positive(t('validation.positiveNumber'))
      .integer(),
    passwordRequireDigit: Yup.boolean().required(t('validation.requiredField')),
    passwordRequireLowercase: Yup.boolean().required(t('validation.requiredField')),
    passwordRequireUppercase: Yup.boolean().required(t('validation.requiredField')),
    passwordRequireNonLetterOrDigit: Yup.boolean().required(t('validation.requiredField')),
    passwordHistoryLimit: Yup.number()
      .required(t('validation.requiredField'))
      .positive(t('validation.positiveNumber'))
      .integer(),
    passwordExpiresInDays: Yup.number()
      .required(t('validation.requiredField'))
      .positive(t('validation.positiveNumber'))
      .integer(),
    accessTokenExpireTimeSpanInMinutes: Yup.number()
      .required(t('validation.requiredField'))
      .positive(t('validation.positiveNumber'))
      .integer(),
    userLockoutEnabledByDefault: Yup.boolean().required(t('validation.requiredField')),
    defaultAccountLockoutTimeInMinutes: Yup.number()
      .required(t('validation.requiredField'))
      .positive(t('validation.positiveNumber'))
      .integer(),
    maxFailedAccessAttemptsBeforeLockout: Yup.number()
      .required(t('validation.requiredField'))
      .positive(t('validation.positiveNumber'))
      .integer(),
  });

  let initialData: Model = {
    passwordExpiresInDays: 0,
    passwordHistoryLimit: 0,
    passwordRequireDigit: true,
    passwordRequireLowercase: false,
    passwordRequireNonLetterOrDigit: false,
    passwordRequireUppercase: false,
    passwordRequiredMinimumLength: 0,
    accessTokenExpireTimeSpanInMinutes: 0,
    userLockoutEnabledByDefault: false,
    defaultAccountLockoutTimeInMinutes: 0,
    maxFailedAccessAttemptsBeforeLockout: 0,
  };

  const [data, setData] = useState<Model>(initialData);

  const updateData = async (fieldsToUpdate: Partial<Model>): Promise<void> => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: Model = {
          passwordExpiresInDays: values.passwordExpiresInDays,
          passwordHistoryLimit: values.passwordHistoryLimit,
          passwordRequireDigit: data.passwordRequireDigit,
          passwordRequireLowercase: data.passwordRequireLowercase,
          passwordRequireNonLetterOrDigit: data.passwordRequireNonLetterOrDigit,
          passwordRequireUppercase: data.passwordRequireUppercase,
          passwordRequiredMinimumLength: values.passwordRequiredMinimumLength,
          accessTokenExpireTimeSpanInMinutes: values.accessTokenExpireTimeSpanInMinutes,
          userLockoutEnabledByDefault: data.userLockoutEnabledByDefault,
          defaultAccountLockoutTimeInMinutes: values.defaultAccountLockoutTimeInMinutes,
          maxFailedAccessAttemptsBeforeLockout: values.maxFailedAccessAttemptsBeforeLockout,
        };

        const result = await Service.putSecuritySettings(body);
        if (result && !result?.hasErrors) {
          toast.success(`${t('crud.update.successMessage')}`);
        } else {
          toast.error(`${t(`error.${result?.errorCode}`)}`);
        }
      } catch (error) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const settingsResponse = await Service.getSecuritySettings(false);
        if (settingsResponse && !settingsResponse.hasErrors) {
          setData(settingsResponse.data);
        }
      } catch (err) {
        toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form">
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#securitySettings"
            aria-expanded="true"
            aria-controls="securitySettings"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{t('generalSettings.security.title')}</h3>
            </div>
          </div>

          <div id="securitySettings" className="collapse show">
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <div className="col-lg-12">
                  <label className="card-title fw-bolder text-muted text-hover-primary fs-4">
                    {t('generalSettings.security.token.title')}
                  </label>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('generalSettings.security.token.tokenExpiration')}
                </label>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('generalSettings.security.token.tokenExpiration')}
                        {...formik.getFieldProps('accessTokenExpireTimeSpanInMinutes')}
                      />
                      {formik.touched.accessTokenExpireTimeSpanInMinutes &&
                        formik.errors.accessTokenExpireTimeSpanInMinutes && (
                          <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">{formik.errors.accessTokenExpireTimeSpanInMinutes}</div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <div className="col-lg-12">&nbsp;</div>
              </div>

              <div className="row mb-6">
                <div className="col-lg-12">
                  <label className="card-title fw-bolder text-muted text-hover-primary fs-4">
                    {t('generalSettings.security.password.title')}
                  </label>
                </div>
              </div>

              <div className="row mb-6">
                <div className="col-lg-6">
                  <div className="row mb-6">
                    <label className="col-lg-8 col-form-label required fw-bold fs-6">
                      {t('generalSettings.security.password.requireNumber')}
                    </label>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-check form-check-solid form-switch">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id="chkPasswordRequireDigit"
                              name="passwordRequireDigit"
                              checked={data.passwordRequireDigit}
                              onChange={() =>
                                updateData({
                                  passwordRequireDigit: !data.passwordRequireDigit,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-8 col-form-label required fw-bold fs-6">
                      {t('generalSettings.security.password.requireLower')}
                    </label>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-check form-check-solid form-switch">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id="chkPasswordRequireLowercase"
                              name="passwordRequireLowercase"
                              checked={data.passwordRequireLowercase}
                              onChange={() =>
                                updateData({
                                  passwordRequireLowercase: !data.passwordRequireLowercase,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-8 col-form-label required fw-bold fs-6">
                      {t('generalSettings.security.password.requireUpper')}
                    </label>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-check form-check-solid form-switch">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id="chkPasswordRequireUppercase"
                              name="passwordRequireUppercase"
                              checked={data.passwordRequireUppercase}
                              onChange={() =>
                                updateData({
                                  passwordRequireUppercase: !data.passwordRequireUppercase,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-8 col-form-label required fw-bold fs-6">
                      {t('generalSettings.security.password.requireSpecial')}
                    </label>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-check form-check-solid form-switch">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id="chkPasswordRequireNonLetterOrDigit"
                              name="passwordRequireNonLetterOrDigit"
                              checked={data.passwordRequireNonLetterOrDigit}
                              onChange={() =>
                                updateData({
                                  passwordRequireNonLetterOrDigit: !data.passwordRequireNonLetterOrDigit,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      {t('generalSettings.security.password.minSize')}
                    </label>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-12">
                          <input
                            type="number"
                            className="form-control form-control-lg form-control-solid"
                            placeholder={t('generalSettings.security.password.minSize')}
                            {...formik.getFieldProps('passwordRequiredMinimumLength')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      {t('generalSettings.security.password.historical')}
                    </label>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-12">
                          <input
                            type="number"
                            className="form-control form-control-lg form-control-solid"
                            placeholder={t('generalSettings.security.password.historical')}
                            {...formik.getFieldProps('passwordHistoryLimit')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      {t('generalSettings.security.password.passwordExpiration')}
                    </label>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-12">
                          <input
                            type="number"
                            className="form-control form-control-lg form-control-solid"
                            placeholder={t('generalSettings.security.password.passwordExpiration')}
                            {...formik.getFieldProps('passwordExpiresInDays')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-6">
                <div className="col-lg-12">&nbsp;</div>
              </div>
              <div className="row mb-6">
                <div className="col-lg-12">
                  <label className="card-title fw-bolder text-muted text-hover-primary fs-4">
                    {t('generalSettings.security.accountBlock.title')}
                  </label>
                </div>
              </div>
              <div className="row mb-6">
                <div className="col-lg-6">
                  <div className="row mb-6">
                    <label className="col-lg-8 col-form-label required fw-bold fs-6">
                      {t('generalSettings.security.accountBlock.default')}
                    </label>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-check form-check-solid form-switch">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id="chkUserLockoutEnabledByDefault"
                              name="userLockoutEnabledByDefault"
                              checked={data.userLockoutEnabledByDefault}
                              onChange={() =>
                                updateData({
                                  userLockoutEnabledByDefault: !data.userLockoutEnabledByDefault,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      {t('generalSettings.security.accountBlock.maxTrying')}
                    </label>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-12">
                          <input
                            type="number"
                            className="form-control form-control-lg form-control-solid"
                            placeholder={t('generalSettings.security.accountBlock.maxTrying')}
                            {...formik.getFieldProps('maxFailedAccessAttemptsBeforeLockout')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      {t('generalSettings.security.accountBlock.block')}
                    </label>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-12">
                          <input
                            type="number"
                            className="form-control form-control-lg form-control-solid"
                            placeholder={t('generalSettings.security.accountBlock.block')}
                            {...formik.getFieldProps('defaultAccountLockoutTimeInMinutes')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mh-50px"
                  >
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                    <rect
                      x="11"
                      y="14"
                      width="7"
                      height="2"
                      rx="1"
                      transform="rotate(-90 11 14)"
                      fill="currentColor"
                    ></rect>
                    <rect
                      x="11"
                      y="17"
                      width="2"
                      height="2"
                      rx="1"
                      transform="rotate(-90 11 17)"
                      fill="currentColor"
                    ></rect>
                  </svg>
                </span>
                <div className="d-flex flex-stack flex-grow-1">
                  <div className="fw-bold">
                    <h4 className="text-gray-800 fw-bolder">{t('generalSettings.security.disclaimer.title')}</h4>
                    <div className="fs-6 text-gray-600">{t('generalSettings.security.disclaimer.subtitle')}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="reset" className="btn btn-white btn-active-light-primary me-2">{t('crud.create.buttonCancel')}</button>
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {!isLoading && t('crud.create.buttonSave')}
                {isLoading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    {t('crud.common.wait')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
