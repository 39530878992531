import { HttpAuthResponse, HttpRefreshTokenBody } from 'app/login/model/login.model';
import { AuthToken } from './auth-token';
import { http } from '../axios/axios-helper';
import { axiosInstance } from '../axios/axios-instance';
import { TokenHelper } from './token-helper';
import i18n from 'i18n';
import { ForgottenPassword } from 'app/recovery-password/model/forgotten-password.model';
import { RecoveryPassword } from 'app/recovery-password/model/recovery-password.model';

async function authenticate(username: string, password: string) {
  return await http.post<HttpAuthResponse>('/User/authenticate', { username, password })
}

async function authenticateSSO(token: string) {
  return await http.post<HttpAuthResponse>('/User/sso', { token: token })
}

function refreshToken() {
  const accessToken = AuthToken.getToken();
  const refreshToken = AuthToken.getRefreshToken();

  return axiosInstance.post<HttpRefreshTokenBody>('/User/refresh-token', { token: accessToken, refreshToken });
}

function logout() {
  sessionStorage.clear();
  localStorage.removeItem('i18nextLng');
  localStorage.removeItem('tokenLanguage');
};

function login(response: HttpAuthResponse) {
  AuthToken.setAuthData(response.accessToken, response.refreshToken);
  const tokenHelper = new TokenHelper();
  localStorage.setItem('i18nextLng', tokenHelper.GetLanguage());
  i18n.changeLanguage(tokenHelper.GetLanguage());
}

async function requestPassword(request: ForgottenPassword) {
  return await http.post<HttpAuthResponse>('/User/requestpassword', request)
}

async function changeRequestPassword(request: RecoveryPassword) {
  return await http.post<HttpAuthResponse>('/User/changerequestpassword', request)
}

export const AuthService = {
  authenticate,
  authenticateSSO,
  refreshToken,
  logout,
  login,
  requestPassword,
  changeRequestPassword
};
