import { AgentLogStatus } from "../model/dashboard.model"

export const getTimeLineBadge = (status: number) => {
  const backgroundColor = getStatusColor(status);

  switch (status) {
    case AgentLogStatus.NotRunning:
      return `fa-regular fa-circle text-${backgroundColor}`;
    case AgentLogStatus.Running:
      return `fa-solid fa-gears text-${backgroundColor}`;
    case AgentLogStatus.Success:
      return `fa-regular fa-circle-check text-${backgroundColor}`;
    case AgentLogStatus.Stopped:
      return `fa-regular fa-circle-stop text-${backgroundColor}`;
    case AgentLogStatus.Error:
      return `fa-regular fa-circle-xmark text-${backgroundColor}`;
    case AgentLogStatus.Suspect:
      return `fa-solid fa-circle-exclamation text-${backgroundColor}`;
    case AgentLogStatus.Conflict:
      return `fa-solid fa-calendar-xmark text-${backgroundColor}`;
    default:
      return `fa-solid fa-hourglass-half text-${backgroundColor}`;
  }
};

export const getStatusColor = (status: number): string => {
  switch (status) {
    case AgentLogStatus.NotRunning:
      return "bg-gray-600"
    case AgentLogStatus.Running:
      return "primary";
    case AgentLogStatus.Success:
      return "success";
    case AgentLogStatus.Stopped:
      return "warning";
    case AgentLogStatus.Error:
      return "danger";
    case AgentLogStatus.Suspect:
      return "warning";
    case AgentLogStatus.Conflict:
      return "warning";
    default:
      return "primary";
  }
};

export const getStatusText = (status: number, t: (key: string) => string): string => {
  switch (status) {
    case AgentLogStatus.NotRunning:
      return t('schedules.runningStatus.NotRunning');
    case AgentLogStatus.Running:
      return t('schedules.runningStatus.Running');
    case AgentLogStatus.Success:
      return t('schedules.runningStatus.Success');
    case AgentLogStatus.Stopped:
      return t('schedules.runningStatus.Stopped');
    case AgentLogStatus.Error:
      return t('schedules.runningStatus.Error');
    case AgentLogStatus.Suspect:
      return t('schedules.runningStatus.Suspect');
    case AgentLogStatus.Conflict:
      return t('schedules.runningStatus.Conflict');
    default:
      return t('schedules.runningStatus.Unknown');
  }
};