import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLoading } from 'common/loading/loading.hook';
import { useAvatar } from 'common/helpers/avatar/context/avatar.hook';
import { Languages } from './Languages';
import { TopbarService } from '../services/Topbar.service';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import { ITLabAvatar } from 'common/helpers/avatar/itlab-avatar';
// import { useMsal } from '@azure/msal-react';
import { AuthService } from 'common/helpers/auth/auth.service';
import { APP_ROUTES } from 'routes/routes';

export function HeaderUserMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading } = useLoading();
  const { avatar } = useAvatar();

  const tokenHelper = new TokenHelper();
  const userName = tokenHelper.GetName();
  const userEmail = tokenHelper.GetEmail();

  const [avatarBuffer, setAvatarBuffer] = useState<string>('');

  // const { instance } = useMsal();

  // const handleLogout = (logoutType: string) => {
  //   if (logoutType === "redirect") {
  //     instance.logoutRedirect({
  //       account: instance.getActiveAccount(),
  //       postLogoutRedirectUri: "/",
  //     });
  //   }
  // }

  const handleLogout = () => {
    AuthService.logout();
    navigate('/' + APP_ROUTES.login);
  };

  // const myProfile = () => {
  //   navigate('/security/userprofile');
  // };

  const fetchData = () => {
    (async () => {
      try {
        isLoading(true);
        const avatarDataResponse: any = await TopbarService.getUserAvatar();
        if (avatarDataResponse.data.byteLength > 0) {
          setAvatarBuffer(avatarDataResponse.data);
        }
      } catch (err) {
        // toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
        isLoading(false);
      }
    })();
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <ITLabAvatar buffer={avatarBuffer} fullName={new TokenHelper().GetName()} />
          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">{userName}</div>
            <span className="fw-bold text-muted fs-7">
              {userEmail}
            </span>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      {/* <div className="menu-item px-5">
        <a href="/#" onClick={myProfile} className="menu-link px-5">
          {t('topbar.userMenu.myProfile')}
        </a>
      </div> */}

      <Languages />

      <div className="menu-item px-5">
        <span onClick={handleLogout} className="menu-link px-5">
          {t('topbar.userMenu.signOut')}
        </span>
      </div>
    </div>
  );
}
