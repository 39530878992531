import { NotificationModel } from 'common/helpers/alerts/alerts.model';
import { Link } from 'react-router-dom';

interface NotificationItemProps {
  notification: NotificationModel;
}

export function NotificationItem(props: NotificationItemProps) {
  const levelType = {
    "Success": "list-group-item list-group-item-success mb-1 rounded  border-0 border-radius-5",
    "Error": "list-group-item list-group-item-danger mb-1 rounded  border-0 border-radius-5",
    "Warning": "list-group-item list-group-item-warning mb-1 rounded  border-0 border-radius-5",
    "Info": "list-group-item list-group-item-info mb-1 rounded  border-0 border-radius-5"
  };

  return (
    <li className={levelType[props.notification.type]}>
      <Link to={`/notifications/center/${props.notification.id}`} className='text-dark'>
        {props.notification.title}
      </Link>
    </li>
  );
}