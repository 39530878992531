import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import AppLogo from 'common/partials/AppLogo';
import en from '../../errors-page/i18n/en-us.json';
import pt from '../../errors-page/i18n/pt-br.json';
import es from '../../errors-page/i18n/es-es.json';

interface LoadingProps {
  username?: string;
}

export function Loading(props: LoadingProps) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20">
      <div className="mb-10 pt-lg-20">
        <AppLogo className="w-300px mb-5 img-fluid" />
      </div>
      <div className="pt-lg-10 mb-10">
        <h1 className="fw-bolder fs-2x text-gray-700 mb-10">{t('login.loading.title')} {props.username}</h1>
        <div className="fw-bold fs-3 text-gray-400 mb-15">{t('login.loading.subtitle')}</div>
        <div className="text-center">
          <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status"></div>
        </div>
      </div>
    </div>
  );
}
