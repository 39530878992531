import { HubConnection } from "@microsoft/signalr/dist/esm/HubConnection";
import { createContext } from "react";
import { AgentLogStatus, OperationSystemSessionState, StepLogModel } from "../model/dashboard.model";
import { DepartamentsModel } from "app/departaments/model/departaments.model";

interface DashboardContextValue {
    hubConnection: HubConnection | null;
    getStepLog(): void;
    stepLogIsLoading: boolean;
    filteredGroupedRobots: GroupedRobots[];
    dashboardFilters: DashboardFilters;
    UpdateSingleItemStatusOnList(status: AgentLogStatus, eventId: number): void;
    UpdateMachineStatusOnList(machineName: string, sessionState: OperationSystemSessionState): void;
    setFilter(filters: DashboardFilters): void;
    filtered: boolean;
}

const DashboardContext = createContext<DashboardContextValue>({
    hubConnection: null,
    getStepLog: () => { },
    stepLogIsLoading: false,
    filteredGroupedRobots: [],
    dashboardFilters: {},
    UpdateSingleItemStatusOnList: (status: AgentLogStatus, eventId: number) => { },
    UpdateMachineStatusOnList: (machineName: string, sessionState: OperationSystemSessionState) => {},
    setFilter: (filters: DashboardFilters) => { },
    filtered: false
});

export interface GroupedRobots {
    key: string;
    events: StepLogModel[];
};

export interface DashboardFilters {
    status?: AgentLogStatus;
    department?: DepartamentsModel;
};

export default DashboardContext;