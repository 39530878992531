import axios from 'axios';
import { API_URL } from 'config';
import { APP_ROUTES } from 'routes/routes';
import { AuthToken } from '../auth/auth-token';
import { AuthService } from '../auth/auth.service';
import { HttpCode } from './http-codes';

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = AuthToken.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (window.location.pathname === '/' + APP_ROUTES.login) {
      return Promise.reject(error);
    }
    if (error.response.status === HttpCode.Forbidden) {
      AuthService.logout();
      window.location.href = '/' + APP_ROUTES.login;
      return;
    }
    if (error.response.status === HttpCode.Unauthorized) {
      window.location.href = '/' + APP_ROUTES.unauthorizedpage;
      return;
    }
    return Promise.reject(error);
  },
);

export { axiosInstance };
