import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import filterIcon from 'assets/images/icons/filter.svg';
import ITSVG from 'common/helpers/ITSVG';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { SyntheticEvent, useContext, useState } from 'react';
import { NotificationType } from '../model/notification.model';
import NotificationContext from '../context/notification.context';

export function NotificationCenterFilter() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();
  const { setFilter } = useContext(NotificationContext);

  const [notificationType, setNotificationType] = useState<NotificationType>();
  const [isRead, setIsRead] = useState<boolean>();

  const pathToOptions = "notifications.filter.types.options"
  const notificationsTypeOptions = [t(`${pathToOptions}.all`), t(`${pathToOptions}.success`), t(`${pathToOptions}.danger`), t(`${pathToOptions}.warning`), t(`${pathToOptions}.info`)];
  const handleNotificationsTypeChange = (e: SyntheticEvent<Element, Event>, value: string | null) => {
    e.stopPropagation();
    if (value === t(`${pathToOptions}.success`)) {
      setNotificationType("Success");
    } else if (value === t(`${pathToOptions}.danger`)) {
      setNotificationType("Error");
    } else if (value === t(`${pathToOptions}.warning`)) {
      setNotificationType("Warning");
    } else if (value === t(`${pathToOptions}.info`)) {
      setNotificationType("Info");
    } else {
      setNotificationType(undefined);
    }
  }

  const pathToReadOptions = "notifications.filter.read.options";
  const readOptions = [t(`${pathToReadOptions}.all`), t(`${pathToReadOptions}.yes`), t(`${pathToReadOptions}.no`)]
  const handleReadChange = (e: SyntheticEvent<Element, Event>, value: string | null) => {
    e.stopPropagation();
    if (value === t(`${pathToReadOptions}.all`)) {
      setIsRead(undefined);
    } else if (value === t(`${pathToReadOptions}.yes`)) {
      setIsRead(true);
    } else {
      setIsRead(false);
    }
  }

  return (
    <div className='me-4'>
      <button
        type='button'
        className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'>
        <ITSVG path={filterIcon} className='svg-icon-5 svg-icon-gray-500 me-1' />
        {t("notifications.filter.title")}
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{t('generalMessages.filterOptions')}</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div>
            <FormControl variant="filled" style={{ width: "100%" }}>
              <label className="form-label required me-3">{t("notifications.filter.types.caption")}</label>
              <Autocomplete
                options={notificationsTypeOptions}
                onChange={handleNotificationsTypeChange}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    placeholder={t("notifications.filter.types.placeholder")}
                  />
                }
              />
            </FormControl>
          </div>
        </div>
        <div className='px-7 pt-5'>
          <div className='mb-10'>
            <FormControl variant="filled" style={{ width: "100%" }}>
              <label className="form-label required me-3">{t("notifications.filter.read.caption")}</label>
              <Autocomplete
                options={readOptions}
                onChange={handleReadChange}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    placeholder={t("notifications.filter.read.placeholder")}
                  />
                }
              />
            </FormControl>
          </div>
        </div>
        <div className='d-flex justify-content-end pb-7 px-7'>
          <button
            type='button'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
            disabled={false}
            onClick={() => setFilter({ read: isRead, type: notificationType})}
          >
            {t('generalMessages.apply')}
          </button>
        </div>
      </div>
    </div>
  );
}