import { useEffect, useState } from "react";
import { ScreenshotList as Model, ScreenshotDetailList } from '../../../model/machine.model';
import { ScreenshotLinkService } from "app/machine/services/screenshot-link";
import { toast } from "react-toastify";
import DataTable, { ExpanderComponentProps, TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { tableHelper } from "common/helpers/table/table-helper";
import { itLabTableStyle } from "common/helpers/table/table-style";
import ActionButton from "common/partials/ActionButton";
import download from "common/helpers/download/download";
import moment from "moment-timezone";

interface ScreenshotTabProps {
    machineId?: string;
}
export default function ScreenshotTab(props: ScreenshotTabProps) {
    const { t } = useTranslation();

    const [data, setData] = useState<Model[]>([]);
    const [pending, setPending] = useState(false);

    const fetchData = async () => {
        setPending(true)
        const response = await ScreenshotLinkService.getScreenshots(props.machineId);
        if (response && !response.hasErrors) {
            setData(response.data);
            setPending(false)
            return;
        }

        if(response?.errorCode === "503"){
            t(`generalMessages.errors.${response.errorCode}`);
            return;
        }

        toast.error("Error loading screenshots");
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDownloadButton = async (entity: ScreenshotDetailList) => {
        if (entity.fullName) {
            const blobResponse = await ScreenshotLinkService.downloadFile(props.machineId, entity.fullName);
            if (blobResponse) {
                download(blobResponse, `${entity.fileName}`);
            }
        }
    }

    const columns: TableColumn<Model>[] = [
        {
            name: `${t('screenshot.table.folderheader')}`,
            selector: (row) => t(`screenshot.table.content-${row.folder}`) || ''
        }
    ];

    const ExpandedComponent: React.FC<ExpanderComponentProps<Model>> = ({ data }) => {
        const expandedColumns: TableColumn<ScreenshotDetailList>[] = [
            {
                name: `${t('screenshot.table.fileNameHeader')}`,
                selector: (row) => row.fileName,
            },
            {
                name: `${t('screenshot.table.dateHeader')}`,
                cell: (row) => moment(row.createdDate).format("L LT")
            },
            {
                name: `${t('table.actions')}`,
                cell: (row) => (
                    <div className="d-grid d-flex gap-2">
                        <ActionButton type='download' onClick={() => handleDownloadButton(row)} />
                    </div>
                ),
                center: true,
                width: '100px'
            },
        ];

        return <>
            <DataTable
                columns={expandedColumns}
                data={data.details}
                pagination
                paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
                paginationPerPage={tableHelper.defaultRowsPerPage}
                paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
                highlightOnHover
                pointerOnHover
                dense
                striped
            />
        </>
    };


    return <>
        <div className="card card-flush shadow-none mt-10">
            <div className="card-body pt-0">
                <DataTable
                    persistTableHead={true}
                    columns={columns}
                    data={data}
                    noDataComponent={tableHelper.getNoDataComponent()}
                    progressPending={pending}
                    progressComponent={tableHelper.getLoader()}
                    contextMessage={tableHelper.getContextMessage()}
                    customStyles={itLabTableStyle}
                    dense
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                />
            </div>
        </div>
    </>
}