import i18next from 'i18next';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import SaveButton from 'common/partials/SaveButton';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { ProcessModel as Model } from '../model/process.model';
import { ProcessesService as Service } from '../services/processes.service';
import { ProcessSteps } from './childs/processes-steps/components/ProcessSteps';
import { DepartamentsLink } from './childs/departaments-link/components/DepartamentsLink';
import { Documents } from './childs/documents/components/Documents';
import { CreateEditProcessSteps } from './childs/processes-steps/components/CreateEditProcessSteps';
import { CreateEditDepartamentsLink } from './childs/departaments-link/components/CreateEditDepartamentsLink';
import { CreateEditDocuments } from './childs/documents/components/CreateEditDocuments';
import { MachineSelectorComponent } from './childs/machine-selector/components/MachineSelector';

export function CreateEditProcesses() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [editId, setEditId] = useState(0);
  const [activeTab, setActiveTab] = useState('basicInfoTab');
  const [isFetching, setIsFetching] = useState(false);
  const handleFetchModalData = () => setIsFetching(!isFetching);
  const [openAddStepModal, setOpenAddStepModal] = useState(false);
  const [openAddDepartmentModal, setOpenAddDepartmentModal] = useState(false);
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false);
  const handleCloseAddStepModal = () => setOpenAddStepModal(false);
  const handleCloseAddDepartmentModal = () => setOpenAddDepartmentModal(false);
  const handleCloseAddDocumentModal = () => setOpenAddDocumentModal(false);
  const handleOpenAddStepModal = (id: number) => {
    setOpenAddStepModal(true)
    setEditId(id)
  };
  const handleOpenAddDepartmentModal = (id: number) => {
    setOpenAddDepartmentModal(true)
    setEditId(id)
  };
  const handleOpenAddDocumentModal = (id: number) => {
    setOpenAddDocumentModal(true)
    setEditId(id)
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('crud.validators.requiredField'))
      .max(70, t('crud.validators.maxLength_70')),
    description: Yup.string()
      .required(t('crud.validators.requiredField'))
      .max(512, t('crud.validators.maxLength_512')),
  });

  let initialData: Model = {
    id: parseInt(id ?? '0'),
    name: '',
    description: '',
    isActive: true,
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: Model = {
          id: data.id,
          name: values.name,
          description: values.description,
          machineId: values.machineId,
          isActive: values.isActive,
        };
        if (id) {
          delete body.machineId;
          const response = await Service.putData(body);
          if (response && !response?.hasErrors) {
            toast.success(`${t('crud.update.successMessage')}`);
          }
        } else {
          const response = await Service.postData(body);
          if (response && !response?.hasErrors) {
            toast.success(`${t('crud.create.successMessage')}`);
            navigate(`/processes/process/${response.data.id}`);
          }
        }
      } catch (error) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (id) {
        const response = await Service.getDataById(id);
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight></ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between mb-2">
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                  <li className="nav-item cursor-pointer">
                    <div
                      className={`nav-link text-active-primary ${activeTab === 'basicInfoTab' ? 'active' : ''}`}
                      onClick={() => setActiveTab('basicInfoTab')}
                    >
                      {t('basicInfoTab')}
                    </div>
                  </li>
                  <li className={`nav-item ${id ? 'cursor-pointer' : ''}`}>
                    <div
                      className={`nav-link text-active-primary ${activeTab === 'processStepTab' ? 'active' : ''} ${!id ? 'disabled' : ''}`}
                      onClick={() => setActiveTab('processStepTab')}
                    >
                      {t('processStepTab')}
                    </div>
                  </li>
                  <li className={`nav-item ${id ? 'cursor-pointer' : ''}`}>
                    <div
                      className={`nav-link text-active-primary ${activeTab === 'departamentsTab' ? 'active' : ''} ${!id ? 'disabled' : ''}`}
                      onClick={() => setActiveTab('departamentsTab')}
                    >
                      {t('departamentsTab')}
                    </div>
                  </li>
                  <li className={`nav-item ${id ? 'cursor-pointer' : ''}`}>
                    <div
                      className={`nav-link text-active-primary ${activeTab === 'machinesTab' ? 'active' : ''} ${!id ? 'disabled' : ''}`}
                      onClick={() => setActiveTab('machinesTab')}
                    >
                      {t('machinesTab')}
                    </div>
                  </li>
                  <li className={`nav-item ${id ? 'cursor-pointer' : ''}`}>
                    <div
                      className={`nav-link text-active-primary0 ${activeTab === 'documentsTab' ? 'active' : ''} ${!id ? 'disabled' : ''}`}
                      onClick={() => setActiveTab('documentsTab')}
                    >
                      {t('documentsTab')}
                    </div>
                  </li>
                </ul>
                {activeTab === 'processStepTab' && (
                  <div>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => handleOpenAddStepModal(0)}>
                      {t('addProcessStep')}
                    </button>
                    <CreateEditProcessSteps id={editId} open={openAddStepModal} handleOpen={handleOpenAddStepModal} handleClose={handleCloseAddStepModal} handleFetchModalData={handleFetchModalData} />
                  </div>
                )}
                {activeTab === 'departamentsTab' && (
                  <div>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => handleOpenAddDepartmentModal(0)}>
                      {t('addDepartment')}
                    </button>
                    <CreateEditDepartamentsLink id={editId} open={openAddDepartmentModal} handleOpen={handleOpenAddDepartmentModal} handleClose={handleCloseAddDepartmentModal} handleFetchModalData={handleFetchModalData} />
                  </div>
                )}
                {activeTab === 'documentsTab' && (
                  <div>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => handleOpenAddDocumentModal(0)}>
                      {t('addDocument')}
                    </button>
                    <CreateEditDocuments id={editId} open={openAddDocumentModal} handleOpen={handleOpenAddDocumentModal} handleClose={handleCloseAddDocumentModal} handleFetchModalData={handleFetchModalData} />
                  </div>
                )}
              </div>
              {activeTab === 'basicInfoTab' && (
                <div id="basicInfoTab">
                  <div className="card shadow-none mt-10">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-md-6 mb-10">
                          <label className="required form-label">{t('form.label.name')}</label>
                          <input
                            type="text"
                            name="name"
                            maxLength={70}
                            className="form-control form-control-lg form-control-solid"
                            placeholder={t('form.placeholder.name')}
                            value={formik.values.name ?? ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className="mt-3 text-danger fw-bold">{formik.errors.name}</div>
                          )}
                        </div>
                        <div className="col-md-6 mb-10">
                          <label className="required form-label">{t('form.label.description')}</label>
                          <input
                            type="text"
                            name="description"
                            maxLength={512}
                            className="form-control form-control-lg form-control-solid"
                            placeholder={t('form.placeholder.description')}
                            value={formik.values.description ?? ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.description && formik.errors.description && (
                            <div className="mt-3 text-danger fw-bold">{formik.errors.description}</div>
                          )}
                        </div>
                        {id && (
                          <div className="col-md-6">
                            <label className="form-label">{t('form.label.status')}</label>
                            <div className="form-check form-check-solid form-switch">
                              <input
                                className="form-check-input w-45px h-30px"
                                type="checkbox"
                                id="isActive"
                                name="isActive"
                                checked={formik.values.isActive ?? false}
                                onChange={formik.handleChange}
                              />
                            </div>
                            {formik.values.isActive ? (
                              <div className="text-muted fs-7 mt-5">{t('generalMessages.active')}</div>
                            ) : (
                              <div className="text-muted fs-7 mt-5">{t('generalMessages.inactive')}</div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'processStepTab' && (
                <div id="processStepTab">
                  <ProcessSteps handleOpen={handleOpenAddStepModal} isFetching={isFetching} />
                </div>
              )}
              {activeTab === 'departamentsTab' && (
                <div id="departamentsTab">
                  <DepartamentsLink handleOpen={handleOpenAddDepartmentModal} isFetching={isFetching} />
                </div>
              )}
              {activeTab === 'documentsTab' && (
                <div id="documentsTab">
                  <Documents handleOpen={handleOpenAddDocumentModal} isFetching={isFetching} />
                </div>
              )}
            </div>
            {activeTab === 'basicInfoTab' && (
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <Link className="btn btn-light btn-active-light-primary me-2" to="/processes/process">
                  {t('crud.create.buttonCancel')}
                </Link>
                <SaveButton type="submit" loading={isLoading} />
              </div>
            )}
            {activeTab === 'machinesTab' && (
              <MachineSelectorComponent processId={id ? parseInt(id) : 0} />
            )}
          </div>
        </form>
      </MainLayout>
    </MasterLayout >
  );
}