import ScheduleContext from "app/schedule/context/schedule.context";
import { ScheduleViewModel } from "app/schedule/model/schedule.model";
import { tableHelper } from "common/helpers/table/table-helper";
import { itLabTableStyle } from "common/helpers/table/table-style";
import i18next from "i18next";
import en from '../../../i18n/en-us.json';
import pt from '../../../i18n/pt-br.json';
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import DataTable, { ExpanderComponentProps, TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";

import "../../../styles/CustomFullCalendar.css"

interface ScheduleDictionary {
    key: string,
    value: ScheduleViewModel[]
}

export default function ScheduleListView() {
    i18next.addResourceBundle('us', 'translation', en);
    i18next.addResourceBundle('br', 'translation', pt);
    const { t } = useTranslation();

    const { scheduleData } = useContext(ScheduleContext)

    const [data, setData] = useState<ScheduleDictionary[]>([]);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        setPending(true);
        const createDictionary: ScheduleDictionary[] = Object.values(
            scheduleData.reduce((acc, schedule) => {
                if (!acc[schedule.machineName!]) {
                    acc[schedule.machineName!] = { key: schedule.machineName!, value: [] };
                }
                acc[schedule.machineName!].value.push(schedule);
                return acc;
            }, {} as Record<string, ScheduleDictionary>)
        );
        setData(createDictionary.filter(x => x.key !== undefined));
        setPending(false);
    }, [scheduleData])

    const columns: TableColumn<ScheduleDictionary>[] = [
        {
            name: `${t("listview.table.header.machine")}`,
            style: { marginLeft: ".75em" },
            selector: (row) => row.key || ''
        }
    ];

    const ExpandedComponent: React.FC<ExpanderComponentProps<ScheduleDictionary>> = ({ data }) => {
        const expandedColumns: TableColumn<ScheduleViewModel>[] = [
            {
                name: `${t("listview.table.child.header.step")}`,
                selector: (row) => row.stepName ?? "",
            },
            {
                name: `${t("listview.table.child.header.department")}`,
                cell: (row) => row.departments?.map((dp, index) => <span className="badge badge-light-primary fs-8 fw-bold my-2 me-3" key={index}>{dp}</span>)
            },
            {
                name: `${t("listview.table.child.header.schedule")}`,
                cell: (row) => row.start! < new Date() ? <s>{moment(row.start).format("L LT")} - {moment(row.end).format("LT")}</s> : `${moment(row.start).format("L LT")} - ${moment(row.end).format("LT")}`,
                sortable: true
            },
            {
                name: `${t("listview.table.child.header.color")}`,
                cell: (row) => (
                    <div style={{ backgroundColor: row.color, width: 80, borderRadius: 15, textAlign: 'center' }}>&nbsp;</div>
                ),
                center: true,
                width: '150px'
            }
        ];

        return <>
            <DataTable
                keyField="id,start"
                columns={expandedColumns}
                data={data.value}
                pagination
                paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
                paginationPerPage={tableHelper.defaultRowsPerPage}
                paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
                dense
                striped
                highlightOnHover
                pointerOnHover
            />
        </>
    };

    return <>
        <DataTable
            persistTableHead={true}
            columns={columns}
            data={data}
            noDataComponent={tableHelper.getNoDataComponent()}
            progressPending={pending}
            progressComponent={tableHelper.getLoader()}
            contextMessage={tableHelper.getContextMessage()}
            customStyles={itLabTableStyle}
            dense
            expandableRows
            expandableRowsComponent={ExpandedComponent}
        />
    </>;
}