interface LoadingSpinnerProps {
  loading?: boolean;
  align?: "start" | "center" | "end";
  size?: "sm" | "md" | "lg";
}

export default function LoadingSpinner({ loading = true, align = "center", size = "md" }: LoadingSpinnerProps) {
  return (
    loading ? (
      <div className={`text-${align}`}>
        <span className="indicator-progress" style={{ display: "block" }}>
          <span className={`spinner-border spinner-border-${size} align-middle text-primary`}></span>
        </span>
      </div>
    ) : null
  );
}