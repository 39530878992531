interface Props {
    children?: React.ReactNode;
}

export function ToolbarLayoutRight(props: Props) {

    return (
        <>
            <div className="d-flex align-items-center">
                {props.children}
            </div>
        </>
    );
}