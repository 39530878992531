import { useState, useEffect } from "react";
import { useSignalR } from "common/helpers/signalR/context/signalR.hook";
import { NotificationCenterModel, NotificationModel } from "./alerts.model";
import { Alerts } from "./alerts";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";

export function AlertsIcon() {

  const vieweds: NotificationModel[] = [];
  const initialData: NotificationCenterModel = {
    type: 1,
    message: "",
    messageCode: "",
    existsNotificationNotRead: false,
    existsNotificationNotReceived: false,
    existsNotificationNotViewed: false,
    existsPinnedNotification: false,
    notifications: [],
    timestamp: ""
  }

  const { hubConnection } = useSignalR();
  const [model, setModel] = useState(initialData);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const alertsViewedPush = function (notification: NotificationModel) {
    if (vieweds.indexOf(notification) === -1 || !notification.viewed) {
      vieweds.push(notification);
    }
  }

  const closePopup = function () {
    setIsPopupOpen(false)
    if (!hubConnection || vieweds.length <= 0 || hubConnection.state !== HubConnectionState.Connected)
      return;
      hubConnection.send("viewed", vieweds.map(x => x.id))
  }

  const notify = (model: NotificationCenterModel, hubConnection: HubConnection) => {
    try {
      const notificationsReceivedForTheFirstTime = model.notifications
        .filter(x => !x.received)
        .map(x => x.id);

      if (notificationsReceivedForTheFirstTime.length > 0 && hubConnection.state === HubConnectionState.Connected)
        hubConnection.send("received", notificationsReceivedForTheFirstTime);

      setModel(model);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const receiveMessages = async () => {
      if (hubConnection) {
        hubConnection.on("notify", (model: NotificationCenterModel) => notify(model, hubConnection))
        if(hubConnection.state === HubConnectionState.Connected){
          hubConnection.send("myNotifications")
        } 
      }
    }

    receiveMessages();
    return (() => {
      if (hubConnection) {
        hubConnection.off("notify");
      }
    })
  }, [hubConnection]);

  return (
    <>
      <div
        className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px position-relative"
        id="kt_drawer_chat_toggle"
        onClick={() => setIsPopupOpen(true)}>
        <i className="fa-solid fa-bell"></i>
        {model.existsNotificationNotRead &&
          <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>}
        <Alerts
          show={isPopupOpen}
          notifications={model.notifications}
          closeAlerts={closePopup}
          viewed={alertsViewedPush} />
      </div>
      <div
        onClick={closePopup}
        style={{
          zIndex: 106,
          position: "fixed",
          margin: 0,
          display: isPopupOpen ? "block" : "none",
          width: "100vw",
          height: "100vh",
          inset: "0px 0px auto auto",
        }}
      ></div>
    </>
  );

}
