import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface DeleteModalProps {
    open: boolean;
    onClose: () => void;
    handleDelete: (event: any) => void;
    type: "delete" | "deactivate";
    text?: string;
    size?: "sm" | "md" | "lg"
}

export function DeleteModal(props: DeleteModalProps) {
    const { t } = useTranslation();

    const getSize = () => {
        switch (props.size) {
            case "md":
                return "mw-500px"
            case "lg":
                return "mw-700px"
            default:
                return "mw-300px"
        }
    }

    return (
        <Modal
            show={props.open}
            onHide={props.onClose}
            dialogClassName={`modal-dialog-centered ${getSize()} h-auto`}
        >
            <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                        {props.text
                            ? <span><div dangerouslySetInnerHTML={{ __html: props.text }} /></span>
                            : <span>{props.type === "delete" ? t('crud.delete.questionMessage') : t('crud.deactivate.questionMessage')}</span>}
                    </div>
                    <div className="row text-center">
                        <div className="col-md-6">
                            <button type="button" className="btn btn-danger my-3" onClick={props.handleDelete}>
                                {props.type === "delete" ? t('crud.delete.buttonSave') : t('crud.deactivate.buttonSave')}
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button type="button" className="btn btn-active-light-primary my-3" onClick={props.onClose}>
                                {t('crud.delete.buttonCancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
