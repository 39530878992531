import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { debounce } from '@mui/material';
import { tableHelper } from 'common/helpers/table/table-helper';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { DeleteModal } from 'common/partials/DeleteModal';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import ProtectedComponent from 'common/partials/ProtectedComponent';
import ActionButton from 'common/partials/ActionButton';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { ProcessModel as Model } from '../model/process.model';
import { ProcessesService as Service } from '../services/processes.service';
import { ProcessesFilterDropdown } from './childs/ProcessesFilterDropdown';

export function Processes() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Model[]>([]);
  // const [filteredTableData, setFilteredTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Model[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterStatus, setFilterStatus] = useState('true');
  const [deleteId, setDeleteId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [queryString, setQueryString] = useState<string>('');

  const handleShowDeleteModal = (id: number) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const fetchData = async (departmentId?: number, status?: string) => {
    setIsLoading(true);
    const response = await Service.getDataList(departmentId, status ?? filterStatus, queryString);
    if (response && !response.hasErrors) {
      setTableData(response.data);
    } else {
      toast.error(`${t('crud.read.errorMessage')}`);
    }
    setIsLoading(false);
  };

  const updateQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setFilterStatus('true');
    } else {
      setFilterStatus('');
    }
    setQueryString(e.target.value);
  };

  const debouncedChange = debounce(updateQuery, 500);

  const columns: TableColumn<Model>[] = [
    {
      name: `${t('table.id')}`,
      selector: (row) => row.id || '',
      sortable: true,
      width: '60px'
    },
    {
      name: `${t('table.name')}`,
      selector: (row) => row.name || '',
      sortable: true
    },
    {
      name: `${t('table.description')}`,
      selector: (row) => row.description || '',
      sortable: true
    },
    {
      name: `${t('table.departmentName')}`,
      selector: (row) =>
        row.departments && row.departments.length > 0
          ? row.departments.map(department => department.name).join(', ')
          : '-',
      sortable: true
    },
    {
      name: `${t('table.status')}`,
      cell: (row) =>
        row.isActive ? (
          <span className="badge badge-light-primary">{t('generalMessages.active')}</span>
        ) : (
          <span className="badge badge-light-danger">{t('generalMessages.inactive')}</span>
        ),
      center: true,
      width: '150px'
    },
    {
      name: `${t('table.actions')}`,
      cell: (row) => (
        <div className="d-grid d-flex gap-2">
          <ActionButton type='edit' onClick={() => navigate(`/processes/process/${row.id}`)} roles={[RolesDictionary.Process.Processes_Update]} />
          <ActionButton type='deactivate' onClick={() => handleShowDeleteModal(row.id!)} roles={[RolesDictionary.Process.Processes_Update]} disabled={!row.isActive} />
        </div>
      ),
      center: true,
      width: '100px'
    },
  ];

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const deleteDataById = async () => {
    try {
      setIsLoading(true);
      const result = await Service.deleteDataById(deleteId);
      if (result && !result.hasErrors) {
        toast.success(`${t('crud.deactivate.successMessage')}`);
      } else {
        toast.error(`${t('crud.deactivate.errorMessage')}`);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setToggleCleared(!toggleCleared);
      handleCloseDeleteModal();
      fetchData();
      setIsLoading(false);
    }
  };

  const contextActions = useMemo(() => {
    const deleteSelectedRows = () => {
      setShowDeleteModal(true);
      selectedRows.forEach((row) => {
        setDeleteId(row.id!);
      });
    }

    return (
      <button type="button" className="btn btn-sm btn-danger" onClick={deleteSelectedRows}>
        {t('crud.deactivate.buttonSave')}
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight>
          <div className="d-flex align-items-center gap-2">
            <ProcessesFilterDropdown fetchProcesses={fetchData} statusFilter={filterStatus} />
            <ProtectedComponent action='hide' role={[RolesDictionary.Process.Processes_Update]}>
              <Link to="/processes/process/add" className="btn btn-sm btn-primary fw-bolder">
                {t('addProcess')}
              </Link>
            </ProtectedComponent>
          </div>
        </ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <div className="card card-flush">
          <div className="card-body">
            <DataTable
              title={tableHelper.getTableHeader(debouncedChange)}
              persistTableHead={true}
              columns={columns}
              data={tableData}
              noDataComponent={tableHelper.getNoDataComponent()}
              selectableRows
              selectableRowsHighlight
              dense
              pagination
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
              paginationPerPage={tableHelper.defaultRowsPerPage}
              paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
              highlightOnHover
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              contextActions={contextActions}
              contextMessage={tableHelper.getContextMessage()}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
              selectableRowsVisibleOnly={true}
              customStyles={itLabTableStyle}
            />
          </div>
        </div>

        <DeleteModal
          type='deactivate'
          handleDelete={deleteDataById}
          onClose={handleCloseDeleteModal}
          open={showDeleteModal}
        />
      </MainLayout>
    </MasterLayout>
  );
}
