import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { TokenHelper } from "common/helpers/auth/token-helper";

interface ButtonProps {
  type: 'edit' | 'delete' | 'deactivate' | 'download' | 'copy' | 'details';
  onClick: () => void;
  disabled?: boolean;
  roles?: number[];
}

export default function ActionButton({ disabled, onClick, type, roles }: ButtonProps) {
  const { t } = useTranslation();
  const tokenHelper = new TokenHelper();
  const hasAccess = roles ? tokenHelper.HasAccess(roles) : true;

  let iconClassName = "";
  let hoverIconColor = "";
  let tooltipTitle = "";

  switch (type) {
    case "edit":
      iconClassName = "fa-solid fa-pen-to-square";
      hoverIconColor = "primary";
      tooltipTitle = "generalMessages.edit";
      break;
    case "delete":
      iconClassName = "fa-solid fa-trash";
      hoverIconColor = "danger";
      tooltipTitle = "generalMessages.delete";
      break;
    case "deactivate":
      iconClassName = "fa-solid fa-ban";
      hoverIconColor = "danger";
      tooltipTitle = "generalMessages.deactivate";
      break;
    case "download":
      iconClassName = "fa-solid fa-download";
      hoverIconColor = "primary";
      tooltipTitle = "generalMessages.download";
      break;
    case "copy":
      iconClassName = "fa-solid fa-clipboard";
      hoverIconColor = "primary";
      tooltipTitle = "generalMessages.copy";
      break
    case "details":
      iconClassName = "fa-solid fa-info";
      hoverIconColor = "primary";
      tooltipTitle = "generalMessages.copy";
      break
    default:
      break;
  }

  return (
    <Tooltip title={hasAccess ? t(tooltipTitle) : t("generalMessages.noPermission")} arrow>
      <span>
        <button
          type="button"
          className={`btn btn-icon btn-bg-light btn-sm btn-active-color-${hoverIconColor}`}
          onClick={onClick}
          disabled={disabled || !hasAccess}
        >
          <span className="svg-icon svg-icon-3">
            <i className={iconClassName}></i>
          </span>
        </button>
      </span>
    </Tooltip>
  );
};