import * as Yup from "yup";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmptyArray, useFormik } from "formik";
import ITSVG from "common/helpers/ITSVG";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap"
import closeModalIcon from "assets/images/icons/close.svg"
import arrowLeft from "assets/images/icons/arrow-left.svg"
import arrowRight from "assets/images/icons/arrow-right.svg"
import { ScheduleService as Service } from "../../../services/schedule.service"
import { SchedulePostRequestViewModel } from "app/schedule/model/schedule.model";
import { StepperComponent } from "assets/ts/components";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";

interface Props {
  open: boolean;
  onClose: () => void;
  event: any;
  fetchEvents: (stepId?: number) => void;
}

export function CreateScheduleModal(props: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [stepId, setStepId] = useState(0);
  const [recurringType, setRecurringType] = useState<number>(0);
  const [monthOfYear, setMonthOfYear] = useState<string[]>([]);
  const [dayOfMonth, setDayOfMonth] = useState<string[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [processId, setProcessId] = useState(0);

  const initialData: SchedulePostRequestViewModel = {
    title: "",
    description: "",
    startDate: props.event.start ? (props.event.start < new Date() ? new Date() : props.event.start) : new Date(),
    endDate: props.event.end ? (props.event.end < new Date() ? new Date() : props.event.end) : new Date(),
    stepId: undefined,
    machineIds: undefined,
    recurringType: 0,
    separation: 0,
    position: undefined,
    weekend: true,
    holiday: true,
    dayOfWeek: undefined,
    weekOfMonth: undefined,
    dayOfMonth: undefined,
    monthOfYear: undefined,
    isFullDay: false,
    rescheduleId: 0,
    recurringStartDate: new Date(),
    recurringEndDate: new Date(),
    separationTime: 5,
    startTime: moment(new Date()).add(15, "minutes").format("HH:mm"),
    endTime: moment(new Date()).add(60, "minutes").format("HH:mm"),
    dailyFrequency: false,
    hasEndDate: false,
    dayPattern: "dayOfMonth"
  }

  const [data, setData] = useState<SchedulePostRequestViewModel>(initialData);

  const formSchema = [
    Yup.object({
      department: Yup.string().required(t("crud.validators.requiredField")),
      stepId: Yup.string().required(t("crud.validators.requiredField")),
      startDate: Yup.date().min(moment(new Date()).format("YYYY-MM-DD"), t("crud.validators.minStartDate")).required(t("crud.validators.requiredField")),
      startTime: Yup.string()
        .required(t("crud.validators.requiredField"))
        .test("minStartDate", `${t("crud.validators.minStartDate")}`, function (value) {
          const { startDate } = this.parent;
          let startDateMoment = moment(startDate).format("YYYY-MM-DD");
          let startDateFormatted = moment(startDateMoment + " " + value, "YYYY-MM-DD HH:mm").toDate();
          return moment(startDateFormatted, "HH:mm").isSameOrAfter(moment(new Date(), "HH:mm"));
        })
    }),
    Yup.object({}),
    Yup.object({
      separation: Yup.number().when("recurringType",
        {
          is: (value: any) => value === "1" || value === "2",
          then: Yup.number().required(t("crud.validators.requiredField"))
        }),
      weeklyDayOfWeek: Yup.array().when("recurringType",
        {
          is: (value: any) => value === "2",
          then: Yup.array().min(1, t("crud.validators.requiredField")).required(t("crud.validators.requiredField")).nullable()
        }),
      monthOfYear: Yup.array().when("recurringType",
        {
          is: (value: any) => value === "3",
          then: Yup.array().min(1, t("crud.validators.requiredField")).required(t("crud.validators.requiredField")).nullable()
        }),
      dayOfMonth: Yup.array().when(["dayPattern", "recurringType"],
        {
          is: (value: any, recurring: any) => value === "dayOfMonth" && recurring === "3",
          then: Yup.array().min(1, t("crud.validators.requiredField")).required(t("crud.validators.requiredField")).nullable()
        }),
      position: Yup.string().when(["dayPattern", "recurringType"],
        {
          is: (value: any, recurring: any) => value === "dayOfWeek" && recurring === "3",
          then: Yup.string().required(t("crud.validators.requiredField"))
        }),
      dayOfWeek: Yup.string().when(["dayPattern", "recurringType"],
        {
          is: (value: any, recurring: any) => value === "dayOfWeek" && recurring === "3",
          then: Yup.string().required(t("crud.validators.requiredField"))
        }),
      workDayPosition: Yup.number().when(["dayPattern", "recurringType"],
        {
          is: (value: any, recurring: any) => value === "workDay" && recurring === "3",
          then: Yup.number().required(t("crud.validators.requiredField"))
        }),
      dailyFrequency: Yup.boolean(),
      endTime: Yup.string()
        .required(t("crud.validators.requiredField")),
      // endTime: Yup.string().when("dailyFrequency",
      //   {
      //     is: true,
      //     then: Yup.string().required(t("crud.validators.requiredField"))
      //       .test("minEndTime", `${t("crud.validators.minEndTime")}`, function (value) {
      //         const { startTime } = this.parent;
      //         return moment(value, "HH:mm").isSameOrAfter(moment(startTime, "HH:mm"));
      //       })
      //   })
      startTime: Yup.string()
        .required(t("crud.validators.requiredField"))
        .test("minStartDate", `${t("crud.validators.minStartDate")}`, function (value) {
          const { startDate } = this.parent;
          let startDateMoment = moment(startDate).format("YYYY-MM-DD");
          let startDateFormatted = moment(startDateMoment + " " + value, "YYYY-MM-DD HH:mm").toDate();
          return moment(startDateFormatted, "HH:mm").isSameOrAfter(moment(new Date(), "HH:mm"));
        })
    }),
    Yup.object({
      machineIds: Yup.array().min(1, t("crud.validators.requiredField")).required(t("crud.validators.requiredField")).nullable(),
    }),
  ]

  const [currentSchema, setCurrentSchema] = useState(formSchema[0])

  const formik = useFormik<SchedulePostRequestViewModel>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: currentSchema,
    onSubmit: async (values) => {
      let startDate = moment(values.startDate).format("YYYY-MM-DD");
      let startDateFormatted = moment(startDate + " " + values.startTime, "YYYY-MM-DD HH:mm").toDate();
      let endDateFormatted = moment(startDate + " " + values.endTime, "YYYY-MM-DD HH:mm").toDate();

      if (stepper.current?.currentStepIndex === 2) {
        setStartDate(moment.utc(startDateFormatted).format("YYYY-MM-DDTHH:mm"));
      }

      if (!stepper.current) {
        return
      }

      setCurrentSchema(formSchema[stepper.current.currentStepIndex])
      if (recurringType === 0 && stepper.current.currentStepIndex === 2) {
        stepper.current.currentStepIndex = 3
        setCurrentSchema(formSchema[3])
      }

      if (stepper.current?.currentStepIndex !== stepper.current?.totalStepsNumber) {
        stepper.current?.goNext()
      } else {
        try {
          setIsLoading(true);
          let body: SchedulePostRequestViewModel = {};

          if (recurringType === 0) {
            body = {
              startDate: startDateFormatted,
              stepId: values.stepId,
              machineIds: values.machineIds,
              recurringType: recurringType,
              separation: values.separation,
              position: values.position,
            }
          } else if (recurringType === 1) {
            body = {
              startDate: startDateFormatted,
              stepId: values.stepId,
              machineIds: values.machineIds,
              recurringType: recurringType,
              weekend: values.weekend,
              holiday: values.holiday,
              separation: values.separation,
              position: values.position
            }
            if (values.dailyFrequency) {
              body.separationTime = values.separationTime
            }
            if (values.dailyFrequency && values.endTime) {
              body.recurringStartDate = startDateFormatted
              body.recurringEndDate = endDateFormatted
            }
          } else if (recurringType === 2) {
            body = {
              startDate: startDateFormatted,
              stepId: values.stepId,
              machineIds: values.machineIds,
              recurringType: recurringType,
              dayOfWeek: Array.isArray(values.weeklyDayOfWeek) ? values.weeklyDayOfWeek : [values.weeklyDayOfWeek!],
              separation: values.separation,
              position: values.position,
              weekend: values.weekend,
              holiday: values.holiday,
            }
          } else if (recurringType === 3) {
            body = {
              startDate: startDateFormatted,
              stepId: values.stepId,
              machineIds: values.machineIds,
              recurringType: recurringType,
              monthOfYear: monthOfYear,
              dayOfMonth: dayOfMonth,
              separation: values.separation,
              position: values.position,
              dayOfWeek: Array.isArray(values.dayOfWeek) ? values.dayOfWeek : [values.dayOfWeek!],
              weekend: values.weekend,
              holiday: values.holiday,
            }
            if (isEmptyArray(dayOfMonth) || (values.dayOfWeek))
              delete body.dayOfMonth

            if (values.dayPattern === "dayOfMonth") {
              delete body.dayOfWeek
            }

            if (values.dayPattern === "weekend") {
              body.weekend = true
              delete body.dayOfWeek
            }

            if (values.dayPattern === "holiday") {
              body.holiday = true
              delete body.dayOfWeek
            }

            if (values.workDayPosition) {
              body.position = values.workDayPosition
            }

          }
          if (values.endDate && values.hasEndDate) {
            const endDateFormatted = moment(values.endDate).format("YYYY-MM-DD");
            const formattedEndDate = moment(endDateFormatted + " " + values.endTime, "YYYY-MM-DD HH:mm");
            body.endDate = formattedEndDate.toDate();
          }
          
          const result = await Service.postData(body);

          if (result && !result?.hasErrors) {
            toast.success(`${t("crud.create.successMessage")}`);
          } else {
            toast.error(`${t(`error.${result?.message}`)}`);
          }
          
        } catch (error) {
          toast.error(`${t("crud.update.errorMessage")}`);
        } finally {
          props.onClose();
          formik.resetForm();
          props.fetchEvents(stepId);
          setIsLoading(false);
        }
      }
    },
  });

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
    setCurrentSchema(formSchema[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef, props.open])

  useEffect(() => {
    
    return (() => {
      setCurrentSchema(formSchema[0])
      formik.resetForm();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  useEffect(() => {
    if (!props.open) {
      setMonthOfYear([]);
      setDayOfMonth([]);
      setStartDate("");
      setEndDate("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if (props.event) {
      setData(initialData);
      setRecurringType(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Modal
      show={props.open}
      onHide={props.onClose}
      dialogClassName="modal-dialog-centered mw-500px mw-lg-900px h-auto"
    >
      <div className="modal-content">
        <div className="modal-header p-6">
          <h4>{t("scheduleModal.create")}</h4>

          <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={props.onClose}>
            <ITSVG path={closeModalIcon} className="svg-icon-1" />
          </div>
        </div>

        <form className="form" noValidate id="kt_modal_create_app_form" onSubmit={formik.handleSubmit}>

          <div className="modal-body py-lg-10 px-lg-10">
            <div
              ref={stepperRef}
              className="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row flex-row-fluid"
              id="kt_modal_create_app_stepper"
            >
              <div className="d-flex justify-content-lg-start flex-row-auto w-100 w-lg-300px">
                <div className="stepper-nav ps-lg-10">
                  <div className="stepper-item current" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">{t("scheduleModal.steps.step1.title")}</h3>

                      <div className="stepper-desc">{t("scheduleModal.steps.step1.description")}</div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">{t("scheduleModal.steps.step2.title")}</h3>

                      <div className="stepper-desc">{t("scheduleModal.steps.step2.description")}</div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">3</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">{t("scheduleModal.steps.step3.title")}</h3>

                      <div className="stepper-desc">{t("scheduleModal.steps.step3.description")}</div>
                    </div>
                  </div>

                  <div className="stepper-item" data-kt-stepper-element="nav">
                    <div className="stepper-line w-40px"></div>

                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">4</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">{t("scheduleModal.steps.step4.title")}</h3>

                      <div className="stepper-desc">{t("scheduleModal.steps.step4.description")}</div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="flex-row-fluid px-lg-12">

                <div className="current" data-kt-stepper-element="content">
                  <Step1
                    handleChange={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                    values={formik.values}
                    touched={formik.touched}
                    setTouched={formik.setTouched}
                    errors={formik.errors}
                    setStepId={setStepId}
                    setProcessId={setProcessId}
                  />
                </div>

                <div data-kt-stepper-element="content">
                  <Step2
                    recurringType={recurringType}
                    setRecurringType={setRecurringType}
                    handleChange={formik.handleChange}
                  />
                </div>

                <div data-kt-stepper-element="content">
                  <Step3
                    recurringType={recurringType}
                    setEndDate={setEndDate}
                    handleChange={formik.handleChange}
                    values={formik.values}
                    setFieldsValue={formik.setFieldValue}
                    touched={formik.touched}
                    setTouched={formik.setTouched}
                    errors={formik.errors}
                    monthOfYear={monthOfYear}
                    setMonthOfYear={setMonthOfYear}
                    dayOfMonth={dayOfMonth}
                    setDayOfMonth={setDayOfMonth}
                    open={props.open}
                  />
                </div>

                <div data-kt-stepper-element="content">
                  <Step4
                    startDate={startDate}
                    endDate={endDate}
                    recurringType={recurringType}
                    processId={processId}
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    setTouched={formik.setTouched}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    step={stepper.current?.currentStepIndex}
                    setFieldsValue={formik.setFieldValue}
                  />
                </div>

                <div className="d-flex flex-stack">
                  <div className="me-2">
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <ITSVG
                        path={arrowLeft}
                        className="svg-icon-4 me-1"
                      />
                      {t("scheduleModal.buttons.back")}
                    </button>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-lg btn-primary"
                    disabled={isLoading || !formik.isValid || !formik.dirty}
                  >
                    <span className="indicator-label">
                      {stepper.current?.currentStepIndex !==
                        stepper.current?.totalStepsNumber! && t("scheduleModal.buttons.next")}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totalStepsNumber! && t("scheduleModal.buttons.save")}
                      <ITSVG
                        path={arrowRight}
                        className="svg-icon-4 ms-1 me-0"
                      />
                    </span>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}
