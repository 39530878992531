import React, { createContext, useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder, HttpTransportType, HubConnectionState } from '@microsoft/signalr';
import { API_URL } from 'config';
import { AuthToken } from 'common/helpers/auth/auth-token';

interface SignalRProviderProps {
  children: React.ReactNode;
}

interface SignalRContextType {
  hubConnection: HubConnection | null;
  closeHubConnection: () => void
}

export const SignalRContext = createContext<SignalRContextType>({ hubConnection: null, closeHubConnection: () => {} });

const SignalRProvider = ({ children }: SignalRProviderProps) => {
  const [hubConnection, setHubConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    const token = AuthToken.getToken();
    if (!hubConnection || hubConnection.state !== HubConnectionState.Connected) {
      const newHubConnection = new HubConnectionBuilder()
        .withUrl(`${API_URL}/uinotification?token=${token}`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .build();

      setHubConnection(newHubConnection);
    }

    return () => {
      if (hubConnection && hubConnection.state === HubConnectionState.Connected) {
        hubConnection.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hubConnection && hubConnection.state !== HubConnectionState.Connected) {
      hubConnection.start().catch(err => console.error(err));
    }
  }, [hubConnection]);

  const closeHubConnection = () => {
    if (hubConnection && hubConnection.state === HubConnectionState.Connected) {
      hubConnection.stop();
    }
  };

  return (
    <SignalRContext.Provider value={{ hubConnection, closeHubConnection }}>
      {children}
    </SignalRContext.Provider>
  );
};

export default SignalRProvider;