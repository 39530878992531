enum FeatureFlagEnum {
    Screenshot = 1,
    DownloadAgent = 2
}

interface FeatureFlagMappingType {
    [key: number]: FeatureFlagEnum;
}

export const FeatureFlagMapping: FeatureFlagMappingType = {
    1: FeatureFlagEnum.Screenshot,
    2: FeatureFlagEnum.DownloadAgent,
};

export default FeatureFlagEnum
