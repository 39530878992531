import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { MasterLayout, MainLayout, ToolbarLayout, ToolbarLayoutLeft, ToolbarLayoutRight } from 'common/imports/content-layout';
import ProtectedComponent from 'common/partials/ProtectedComponent';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { useEffect, useState } from 'react';
import Scheduleontext, { ContextOptions } from '../context/schedule.context';
import moment from 'moment-timezone'
import { toast } from 'react-toastify';
import { FetchEventsFilter, ScheduleViewModel } from '../model/schedule.model';
import { ScheduleService as Service } from '../services/schedule.service';
import { ScheduleFilterDropdown } from './views/shared/ScheduleFilterDropdown';
import { DatesSetArg } from '@fullcalendar/react';
import SchedulePage from './SchedulePage';
import { ButtonGroup } from 'react-bootstrap';
import { CreateScheduleModal } from './views/shared/CreateScheduleModal';

export function Schedule() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();

  const initialOptions: ContextOptions = {
    currentView: "calendar",
    calendarViewType: "dayGridMonth",
    isCompactMode: false,
    isWeekendsVisible: true
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scheduleData, setScheduleData] = useState<ScheduleViewModel[]>([]);
  const [isFirstFetch, setIsFirstFetch] = useState<boolean>(false);
  const [startEndDate, setStartEndDate] = useState<DatesSetArg>()
  const [scheduleFilter, setScheduleFilter] = useState<FetchEventsFilter>()
  const [clickedDate, setClickedDate] = useState({})
  const [contextOptions, setContextOptions] = useState<ContextOptions>(initialOptions)
  const [view, setView] = useState<"calendar" | "list">("calendar")
  const [openCreateScheduleModal, setOpenCreateScheduleModal] = useState(false);

  const handleClickOpenCreateScheduleModal = () => { setOpenCreateScheduleModal(true); };
  const handleCloseCreateScheduleModal = () => { setOpenCreateScheduleModal(false); };

  function ConvertToDateTimezone(date: Date): Date {
    const timezonedDate = moment.utc(date).format();
    return moment(timezonedDate).toDate();
  }

  const fetchEvents = async (modalStepId?: number) => {
    setIsLoading(true);
    try {
      if (startEndDate) {
        const startDate = moment(startEndDate?.start).format('YYYY-MM-DD');
        const endDate = moment(startEndDate?.end).format('YYYY-MM-DD');

        const response = await Service.getDataList({
          start: startDate,
          end: endDate,
          department: scheduleFilter?.departmentId ?? undefined,
          machine: scheduleFilter?.machineId ?? undefined,
          step: modalStepId ? modalStepId : scheduleFilter?.stepId ?? undefined
        });
        if (response && !response.hasErrors) {
          let calendarEvents: ScheduleViewModel[] = []
          response.data.forEach(data => {
            data.isHoliday = false;
            data.start = ConvertToDateTimezone(data.start!);
            data.end = ConvertToDateTimezone(data.end!);
            if (data.start < new Date()) {
              data.color = `${data.color}99`
            }

            calendarEvents.push(data)
          })

          const holidaysResponse = await Service.getHolidayList(startDate, endDate);
          if (holidaysResponse && !holidaysResponse.hasErrors) {
            holidaysResponse.data.forEach(data => {
              data.isHoliday = true;
              data.allDay = true;
              data.start = data.start!;
              data.end = data.end!;
              data.title = data.description;
              data.display = 'background'
              calendarEvents.push(data)
            })
          }

          setScheduleData(calendarEvents)
          setIsFirstFetch(true);
        } else {
          toast.error(`${t('crud.read.errorMessage')}`);
        }
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (scheduleFilter) {
      fetchEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startEndDate, scheduleFilter])

  useEffect(() => {
    setContextOptions({ ...contextOptions!, currentView: view });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  return (
    <>
      <MasterLayout>
        <Scheduleontext.Provider value={{
          fetchEvents,
          scheduleData,
          isLoading,
          setIsLoading,
          isFirstFetch,
          startEndDate,
          setStartEndDate,
          clickedDate,
          setClickedDate,
          setScheduleFilter,
          setOpenCreateScheduleModal,
          contextOptions,
          setContextOptions
        }}>
          <ToolbarLayout>
            <ToolbarLayoutLeft />
            <ToolbarLayoutRight>
              {/* <ButtonGroup className='me-4'>
                <button type='button' className='btn btn-light btn-sm btn-flex' onClick={() => { setView("calendar") }}>Calendar</button>
                <button type='button' className='btn btn-light btn-sm btn-flex' onClick={() => { setView("list") }}>List</button>
              </ButtonGroup> */}
              <ScheduleFilterDropdown />
              <ProtectedComponent action='hide' role={[RolesDictionary.Process.Schedule_Update]}>
                <button
                  type="button"
                  onClick={() => handleClickOpenCreateScheduleModal()}
                  className="btn btn-sm btn-primary fw-bolder"
                >
                  {t('calendar.create')}
                </button>
              </ProtectedComponent>
            </ToolbarLayoutRight>
          </ToolbarLayout>
          <MainLayout>
            <SchedulePage />
            <CreateScheduleModal
              open={openCreateScheduleModal}
              onClose={handleCloseCreateScheduleModal}
              event={clickedDate}
              fetchEvents={fetchEvents}
            />
          </MainLayout>
        </Scheduleontext.Provider>
      </MasterLayout>
    </>
  )
}