import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { Autocomplete, TextField } from '@mui/material';
import { MachineTypeService } from 'app/machine-type/services/machineType.service';
import { SitesService } from 'app/sites/services/sites.service';
import { OperationSystemService } from 'app/operation-system/services/operation-system.service';
import { MachineTypeModel } from 'app/machine-type/model/machineType.model';
import { OperationSystemModel } from 'app/operation-system/model/operation-system.model';
import { SitesModel } from 'app/sites/model/sites.model';
import SaveButton from 'common/partials/SaveButton';
import { StatusFilter } from 'common/types/status-filter.model';
import { MachineModel as Model } from '../model/machine.model';
import { MachineService as Service } from '../services/machine.service';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { TechnologiesLink } from './childs/technologies-link/TechnologiesLink';
import { ProcessLink } from './childs/processes-link/ProcessesLink';
import { AddProcessesLink } from './childs/processes-link/AddProcessesLink';
import { AddTechnologiesLink } from './childs/technologies-link/AddTechnologiesLink';
import ScreenshotTab from './childs/screenshot-link/Screenshot';
import FeatureFlag from 'common/partials/FeatureFlag';
import FeatureFlagEnum from 'common/enums/FeatureFlagEnum';

export function CreateEditMachine() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('basicInfoTab');
  const [openAddTechnologyModal, setOpenAddTechnologyModal] = useState(false);
  const [openAddProcessModal, setOpenAddProcessModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const handleCloseAddTechnologyModal = () => setOpenAddTechnologyModal(false);
  const handleCloseAddProcessModal = () => setOpenAddProcessModal(false);
  const handleOpenAddTechnologyModal = () => setOpenAddTechnologyModal(true);
  const handleOpenAddProcessModal = () => setOpenAddProcessModal(true);
  const handleFetchModalData = () => setIsFetching(!isFetching)

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('crud.validators.requiredField'))
      .max(70, t('crud.validators.maxLength_70')),
    operationSystemId: Yup.string()
      .required(t('crud.validators.requiredField')).nullable(),
    siteId: Yup.string()
      .required(t('crud.validators.requiredField')).nullable(),
    machineTypeId: Yup.string()
      .required(t('crud.validators.requiredField')).nullable()
  });

  let initialData: Model = {
    id: parseInt(id ?? '0'),
    name: '',
    ip: '',
    isActive: true,
    isKeepSessionActive: false
  };

  const [data, setData] = useState<Model>(initialData);
  const [machineTypes, setMachineTypes] = useState<MachineTypeModel[]>([]);
  const [machineTypeDefaultValue, setMachineTypeDefaultValue] = useState<MachineTypeModel | undefined | null>(null);
  const [operationSystems, setOperationSystems] = useState<OperationSystemModel[]>([]);
  const [operationSystemDefaultValue, setOperationSystemDefaultValue] = useState<MachineTypeModel | undefined | null>(null);
  const [sites, setSites] = useState<SitesModel[]>([]);
  const [siteDefaultValue, setSiteDefaultValue] = useState<SitesModel | undefined | null>(null);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: Model = {
          id: data.id,
          name: values.name,
          siteId: values.siteId,
          machineTypeId: values.machineTypeId,
          operationSystemId: values.operationSystemId,
          isActive: values.isActive,
          isKeepSessionActive: values.isKeepSessionActive
        };
        if (id) {
          const response = await Service.putData(body);
          if (response && !response?.hasErrors) {
            toast.success(`${t('crud.update.successMessage')}`);
          }
        } else {
          const response = await Service.postData(body);
          if (response && !response?.hasErrors) {
            toast.success(`${t('crud.create.successMessage')}`);
            navigate(`/infrastructure/machine/${response.data.id}`);
          } else {
            toast.error(`${t(`error.${response?.errorCode}`)}`);
          }
        }
      } catch (error) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await Service.getDataById(id);
        if (response && !response.hasErrors) {
          setData(response.data);
        };
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((data && data !== initialData) || (data && !id)) {
      const fetchMachineTypes = async () => {
        const response = await MachineTypeService.getDataList(StatusFilter.Active);
        if (response && !response.hasErrors) {
          setMachineTypes(response.data);
          setMachineTypeDefaultValue(response?.data.find(value => value.id === data.machineType?.id));
        }
      };
      fetchMachineTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if ((data && data !== initialData) || (data && !id)) {
      const fetchOperationSystems = async () => {
        const response = await OperationSystemService.getDataList(StatusFilter.Active);
        if (response && !response.hasErrors) {
          setOperationSystems(response.data);
          setOperationSystemDefaultValue(response?.data.find(value => value.id === data.operationSystem?.id));
        }
      };
      fetchOperationSystems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if ((data && data !== initialData) || (data && !id)) {
      const fetchSites = async () => {
        const response = await SitesService.getDataList(StatusFilter.Active);
        if (response && !response.hasErrors) {
          setSites(response.data);
          setSiteDefaultValue(response?.data.find(value => value.id === data.siteId));
        }
      };
      fetchSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight></ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between mb-2">
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                  <li className={`nav-item ${id ? 'cursor-pointer' : ''}`}>
                    <div
                      className={`nav-link text-active-primary ${activeTab === 'basicInfoTab' ? 'active' : ''} ${!id ? 'disabled' : ''}`}
                      onClick={() => setActiveTab('basicInfoTab')}
                    >
                      {t('basicInfoTab')}
                    </div>
                  </li>
                  <li className={`nav-item ${id ? 'cursor-pointer' : ''}`}>
                    <div
                      className={`nav-link text-active-primary ${activeTab === 'technologiesTab' ? 'active' : ''} ${!id ? 'disabled' : ''}`}
                      onClick={() => setActiveTab('technologiesTab')}
                    >
                      {t('technologiesTab')}
                    </div>
                  </li>
                  <li className={`nav-item ${id ? 'cursor-pointer' : ''}`}>
                    <div
                      className={`nav-link text-active-primary ${activeTab === 'processTab' ? 'active' : ''}  ${!id ? 'disabled' : ''}`}
                      onClick={() => setActiveTab('processTab')}
                    >
                      {t('processTab')}
                    </div>
                  </li>
                  <FeatureFlag feature={FeatureFlagEnum.Screenshot}>
                    <li className={`nav-item ${id ? 'cursor-pointer' : ''}`}>
                      <div
                        className={`nav-link text-active-primary ${activeTab === 'screenshotTab' ? 'active' : ''}  ${!id ? 'disabled' : ''}`}
                        onClick={() => setActiveTab('screenshotTab')}
                      >
                        {t('screenshotTab')}
                      </div>
                    </li>
                  </FeatureFlag>
                </ul>
                {activeTab === 'technologiesTab' && (
                  <div>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => handleOpenAddTechnologyModal()}>
                      {t('addTechnology')}
                    </button>
                    <AddTechnologiesLink open={openAddTechnologyModal} handleClose={handleCloseAddTechnologyModal} handleFetchData={handleFetchModalData} />
                  </div>
                )}
                {activeTab === 'processTab' && (
                  <div>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => handleOpenAddProcessModal()}>
                      {t('addProcess')}
                    </button>
                    <AddProcessesLink open={openAddProcessModal} handleClose={handleCloseAddProcessModal} handleFetchData={handleFetchModalData} />
                  </div>
                )}
              </div>
              {activeTab === 'basicInfoTab' && (
                <div id="basicInfoTab">
                  <div className="card shadow-none mt-10">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-md-6 mb-10">
                          <label className="required form-label">{t('form.label.name')}</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control form-control-lg form-control-solid"
                            placeholder={t('form.placeholder.name')}
                            value={formik.values.name ?? ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={formik.values.isAutoCreated}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className="mt-3 text-danger fw-bold">{formik.errors.name}</div>
                          )}
                        </div>
                        <div className="col-md-6 mb-10">
                          <label className="required form-label">{t('form.label.os')}</label>
                          <Autocomplete
                            id="operationSystemId"
                            disablePortal
                            options={operationSystems}
                            value={operationSystemDefaultValue ?? null}
                            getOptionLabel={option => option.name ? option.name : ""}
                            onChange={(_, value) => {
                              setOperationSystemDefaultValue(value)
                              formik.setFieldValue("operationSystemId", value?.id)
                            }}
                            onInputChange={(_, value) => {
                              if (!value) {
                                setOperationSystemDefaultValue(null)
                                formik.setFieldValue("operationSystemId", undefined)
                              }
                            }}
                            onBlur={() => formik.setTouched({ ...formik.touched, operationSystemId: true })}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                name="machineTypeId"
                                placeholder={`${t('generalMessages.select')}`}
                              />
                            }
                          />
                          {formik.touched.operationSystemId && formik.errors.operationSystemId && (
                            <div className="mt-3 text-danger fw-bold">{formik.errors.operationSystemId}</div>
                          )}
                        </div>
                        <div className="col-md-6 mb-10">
                          <label className="required form-label">{t('form.label.machineType')}</label>
                          <Autocomplete
                            id="machineTypeId"
                            disablePortal
                            options={machineTypes}
                            value={machineTypeDefaultValue ?? null}
                            getOptionLabel={option => option.name ? option.name : ""}
                            onChange={(_, value) => {
                              setMachineTypeDefaultValue(value)
                              formik.setFieldValue("machineTypeId", value?.id)
                            }}
                            onInputChange={(_, value) => {
                              if (!value) {
                                setMachineTypeDefaultValue(null)
                                formik.setFieldValue("machineTypeId", undefined)
                              }
                            }}
                            onBlur={() => formik.setTouched({ ...formik.touched, machineTypeId: true })}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                name="machineTypeId"
                                placeholder={`${t('generalMessages.select')}`}
                              />
                            }
                          />
                          {formik.touched.machineTypeId && formik.errors.machineTypeId && (
                            <div className="mt-3 text-danger fw-bold">{formik.errors.machineTypeId}</div>
                          )}
                        </div>
                        <div className="col-md-6 mb-10">
                          <label className="required form-label">{t('form.label.site')}</label>
                          <Autocomplete
                            id="siteId"
                            disablePortal
                            options={sites}
                            value={siteDefaultValue ?? null}
                            getOptionLabel={option => option.name ? option.name : ""}
                            onChange={(_, value) => {
                              setSiteDefaultValue(value)
                              formik.setFieldValue("siteId", value?.id)
                            }}
                            onInputChange={(_, value) => {
                              if (!value) {
                                setSiteDefaultValue(null)
                                formik.setFieldValue("siteId", undefined)
                              }
                            }}
                            onBlur={() => formik.setTouched({ ...formik.touched, siteId: true })}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                placeholder={`${t('generalMessages.select')}`}
                              />
                            }
                          />
                          {formik.touched.siteId && formik.errors.siteId && (
                            <div className="mt-3 text-danger fw-bold">{formik.errors.siteId}</div>
                          )}
                        </div>
                        {id && (
                          <div className="col-md-6 mb-10">
                            <label className="form-label">{t('form.label.status')}</label>
                            <div className="form-check form-check-solid form-switch">
                              <input
                                className="form-check-input w-45px h-30px"
                                type="checkbox"
                                id="isActive"
                                name="isActive"
                                checked={formik.values.isActive ?? false}
                                onChange={formik.handleChange}
                              />
                            </div>
                            {formik.values.isActive ? (
                              <div className="text-muted fs-7 mt-5">{t('generalMessages.active')}</div>
                            ) : (
                              <div className="text-muted fs-7 mt-5">{t('generalMessages.inactive')}</div>
                            )}
                          </div>
                        )}
                        <div className="col-md-6 mb-10">
                          <label className="form-label">{t('form.label.isKeepSessionActive')}</label>
                          <div className="form-check form-check-solid form-switch">
                            <input
                              className="form-check-input w-45px h-30px"
                              type="checkbox"
                              id="isKeepSessionActive"
                              name="isKeepSessionActive"
                              checked={formik.values.isKeepSessionActive ?? false}
                              onChange={formik.handleChange}
                            />
                          </div>
                          {formik.values.isKeepSessionActive ? (
                            <div className="text-muted fs-7 mt-5">{t('generalMessages.active')}</div>
                          ) : (
                            <div className="text-muted fs-7 mt-5">{t('generalMessages.inactive')}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'technologiesTab' && (
                <div id="technologiesTab">
                  <TechnologiesLink isFetching={isFetching} />
                </div>
              )}
              {activeTab === 'processTab' && (
                <div id="processTab">
                  <ProcessLink isFetching={isFetching} />
                </div>
              )}
              {activeTab === 'basicInfoTab' && (
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <Link className="btn btn-light btn-active-light-primary me-2" to="/infrastructure/machine">
                    {t('crud.create.buttonCancel')}
                  </Link>
                  <SaveButton type="submit" loading={isLoading} />
                </div>
              )}
              {activeTab === "screenshotTab" && (
                <ScreenshotTab machineId={id} />
              )}
            </div>
          </div>
        </form>
      </MainLayout>
    </MasterLayout>
  );
}
