import i18next from 'i18next';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { useTranslation } from 'react-i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { NotificationModel as Model, NotificationRequest } from '../model/notification.model';
import { NotificationService as Service } from '../services/notification.service';
import { useContext, useEffect, useState } from 'react';
import { formatLocalizedDateTime, formatTimeSince } from 'common/helpers/dateUtils';
import { tableHelper } from 'common/helpers/table/table-helper';
import { AlertTypeIcon } from 'common/helpers/alerts/alert-type-icon';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import NotificationContext from '../context/notification.context';

interface NotificationListProps {
  view: (notification: Model) => void;
}

export function NotificationList(props: NotificationListProps) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const { filter } = useContext(NotificationContext);

  const [models, setModels] = useState<Model[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [request, setRequest] = useState<NotificationRequest>({
    isRead: undefined,
    order: "desc",
    pageIndex: 0,
    pageSize: 10,
    sort: "id",
  });

  const fetchData = async function (request: NotificationRequest) {
    try {
      const response = await Service.getDataList(request);
      if (response) {
        if (!response.hasErrors) {
          setModels(response.data);
          setTotalRows(response.totalItems);
        } else {
          toast.error(`${t("notifications.errors.noResponse")}`);
          console.error(response.errorCode);
        }
      } else {
        toast.error(`${t("notifications.errors.noResponse")}`);
      }
    } catch (error) {
      toast.error(`${t("notifications.errors.noResponse")}`);
      console.error(error)
    }
  }

  const getInitials = function (name: string) {
    const names = name.split(" ").map(x => x.toUpperCase());
    const first = names[0];
    if (names.length < 2)
      return first[0];

    const last = names.reverse()[0];
    return `${first[0]}${last[0]}`;

  }

  const columns: TableColumn<Model>[] = [
    {
      name: `${t('notifications.table.columns.author')}`,
      width: '250px',
      cell: (row) => (
        <div className="d-flex align-items-center text-gray-900" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          <div className="symbol symbol-35px me-3">
            <div className="symbol-label bg-primary">
              <span className="text-white">{getInitials(row.author)}</span>
            </div>
          </div>
          <span className="fw-semibold">{row.author}</span>
        </div>
      )
    },
    {
      name: `${t('notifications.table.columns.title')}`,
      width: "600px",
      cell: (row: Model) => (
        <Link
          className="d-flex align-items-center text-gray-900 gap-1 pt-2 " style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          to={`/notifications/center/${row.id}`}>
          <div className="symbol symbol-35px me-4 ms-2">
            <AlertTypeIcon notificationTypeName={row.type} />
          </div>
          <div className="text-gray-900">
            <span className="fw-bold">
              {row.title}
            </span>
            <span className="fw-bold d-md-inine">
              {" "}
              -{" "}
            </span>
            <span className="text-muted">
              {row.preview}
            </span>
          </div>
        </Link>
      )
    },
    {
      name: `${t('notifications.table.columns.since')}`,
      width: "auto",
      right: true,
      cell: (row) => (
        <span>{`${formatTimeSince(row.date, t)} - (${formatLocalizedDateTime(row.date)})`}</span>
      )
    }
  ]

  const handleRowsPerPage = (pageSize: number) => {
    if (pageSize <= 0)
      return;

    setModels([]);
    setRequest(getRequestUpdated({pageSize: pageSize}));
  };

  const handlePageChange = (newPage: number) => {
    if (newPage <= 0)
      return;

    setModels([]);
    setRequest(getRequestUpdated({pageIndex: newPage - 1}));
  };

  async function FilterByName(filter: string) {
    request.search = filter;
    setRequest({
      order: request.order,
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      sort: request.sort,
      notificationType: request.notificationType,
      isRead: request.isRead,
      search: request.search
    });
  }

  const getRequestUpdated = (parameters: NotificationRequest) => {
    return {
      order: parameters.order ?? request.order,
      pageIndex: parameters.pageIndex ?? request.pageIndex,
      pageSize: parameters.pageSize ?? request.pageSize,
      sort: parameters.sort ?? request.sort,
      notificationType: parameters.notificationType ?? request.notificationType,
      isRead: parameters.isRead ?? request.isRead,
      search: request.search
    }
  }

  useEffect(() => {
    fetchData(getRequestUpdated({
      isRead: filter.read,
      notificationType: filter.type
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, filter]);

  return (
    <div className="card card-flush">
      <div className="card-body">
        <DataTable
          title={tableHelper.getTableHeader((e: any) => FilterByName(e.target.value))}
          persistTableHead
          columns={columns}
          data={models}
          noDataComponent={tableHelper.getNoDataComponent()}
          selectableRowsHighlight
          dense
          pagination
          paginationTotalRows={totalRows}
          paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
          paginationPerPage={tableHelper.paginationRowsPerPageOptions.indexOf(request.pageSize ?? 10)}
          paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
          highlightOnHover
          progressComponent={tableHelper.getLoader()}
          contextMessage={tableHelper.getContextMessage()}
          selectableRowsVisibleOnly
          paginationServer
          sortServer
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPage}
        />
      </div>
    </div>
  );
}