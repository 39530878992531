interface Props {
    children?: React.ReactNode;
}

export function MainLayout(props: Props) {

    return (
        <>
            {props.children}
        </>
    );
}