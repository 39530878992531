import { DepartamentsModel } from 'app/departaments/model/departaments.model';
import { ProcessStepsModel } from 'app/processes/components/childs/processes-steps/model/process-steps.model';
import { http } from 'common/helpers/axios/axios-helper';
import { AvailableMachinesRequestViewModel, MachineAvailableNowViewModel, MachineBaseViewModel, MachineModel } from 'app/machine/model/machine.model';
import {
  ScheduleViewModel,
  SchedulePostRequestViewModel,
  SchedulePutTimeViewModel,
  ScheduleDeleteViewModel,
  ScheduleFilterRequestViewModel
} from '../model/schedule.model';

const basePath = '/schedulev2';

async function getDataList({
  start,
  end,
  department,
  step,
  machine
}: ScheduleFilterRequestViewModel) {
  let searchByDepartment = '';
  let searchByStep = '';
  let searchByMachine = '';

  if (department) {
    searchByDepartment = `&Departments=${department}`;
  }
  if (step) {
    searchByStep = `&Steps=${step}`;
  }
  if (machine) {
    searchByMachine = `&Machines=${machine}`;
  }

  return await http.get<ScheduleViewModel[]>(`${basePath}?StartDate=${start}&EndDate=${end}${searchByDepartment}${searchByStep}${searchByMachine}`);
}

async function getDepartamentsList() {
  return await http.get<DepartamentsModel[]>('/department?IsActive=true');
}

async function getRobotListByDepartmentId(departmentId: number) {
  return await http.get<ProcessStepsModel[]>(`/step/department/${departmentId}`);
}

async function getRobotListByMachineId(machineId: number) {
  return await http.get<ProcessStepsModel[]>(`/step/machine/${machineId}`);
}

async function getMachineListByDepartmentId(departmentId: number) {
  return await http.get<MachineModel[]>(`/machine/department/${departmentId}`);
}

async function getProcessStepsList(stepId: number) {
  return await http.get<ProcessStepsModel[]>(`/step/department/${stepId}`);
}

async function getSteps() {
  return await http.get<ProcessStepsModel[]>(`/step`);
}

async function getMachines() {
  return await http.get<MachineModel[]>(`/machine/minimal`);
}

async function getAvailableMachinesList(requestBody: AvailableMachinesRequestViewModel) {
  let endDateQuery = "";
  if (requestBody.endDate) {
    endDateQuery += `&EndDate=${requestBody.endDate}`;	
  }
  return await http.get<MachineBaseViewModel[]>(`/machine/available?StartDate=${requestBody.startDate}&ProcessId=${requestBody.processId}${endDateQuery}`);
}

async function getAvailableMachinesNowList(requestBody: AvailableMachinesRequestViewModel) {
  let endDateQuery = "";
  if (requestBody.endDate) {
    endDateQuery += `&EndDate=${requestBody.endDate}`;	
  }
  return await http.get<MachineAvailableNowViewModel[]>(`/machine/availableNow?StartDate=${requestBody.startDate}&ProcessId=${requestBody.processId}${endDateQuery}`);
}

async function postData(data: SchedulePostRequestViewModel) {
  return await http.post<SchedulePostRequestViewModel>(`${basePath}`, data);
}

async function putDataTime(data: any) {
  return await http.put<SchedulePutTimeViewModel>(`schedulev2/update/${data.id}`, data);
}

async function deleteData(data: ScheduleDeleteViewModel) {
  return await http.post(`schedulev2/delete/${data.id}`, data);
}

async function getHolidayList(start: string, end: string) {
  return await http.get<ScheduleViewModel[]>(`${basePath}/holidays?StartDate=${start}&EndDate=${end}`);
}

export const ScheduleService = {
  getDataList,
  getDepartamentsList,
  getProcessStepsList,
  getSteps,
  getMachines,
  getAvailableMachinesList,
  getAvailableMachinesNowList,
  postData,
  putDataTime,
  deleteData,
  getHolidayList,
  getRobotListByDepartmentId,
  getRobotListByMachineId,
  getMachineListByDepartmentId
};
