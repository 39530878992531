import { BreadcrumbsComponent } from '../header/BreadcrumbsComponent';

interface Props {
    children?: React.ReactNode;
    hideBreadcrumbs?: boolean;
    hideTitle?: boolean;
}

export function ToolbarLayoutLeft(props: Props) {

    return (
        <>
            <div className='d-none d-lg-flex'>
                <BreadcrumbsComponent hideTitle={props.hideTitle} hideBreadcrumbs={props.hideBreadcrumbs} />
            </div>

            <div>
                {props.children}
            </div>
        </>
    );
}