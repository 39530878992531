import { http } from 'common/helpers/axios/axios-helper';
import { AgentVersionModel as Model } from '../model/agent-versions.model';

const basePath = '/agent/versions';

async function getEnabledDownload() {
  return await http.get<boolean>(`${basePath}/download/enabled`);
}

async function getDataList() {
  return await http.get<Model[]>(`${basePath}`);
}

async function downloadFile(versionName: String) {
  return await http.download(`${basePath}/${versionName}/download`, {});
}

export const AgentVersionService = {
  getDataList,
  downloadFile,
  getEnabledDownload
};