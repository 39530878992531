export interface EventRecurring {
  recurringType: EventRecurringType;
  separation?: number;
  position?: Position;
  weekend: boolean;
  holiday: boolean;
  daysOfWeek?: DayOfWeek[];
  weekOfMonth?: WeekOfMonth;
  daysOfMonth?: number[];
  monthOfYear?: MonthOfYear;
  startTime?: string;
  endTime?: string;
  separationTime?: number;
}

export enum EventRecurringType {
  Single,
  Daily,
  Weekly,
  Monthly
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export enum WeekOfMonth {
  Last = 0,
  First,
  Second,
  Third,
  Fourth
}

export enum MonthOfYear {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December
}

export enum Position {
  Last,
  First,
  Second,
  Third,
  Fourth,
  Fifth
}