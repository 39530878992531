import { BaseHttpResponse } from '../../interfaces/base-http-response.model';
import { toast } from 'react-toastify';
import { axiosInstance } from './axios-instance';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

function get<T>(url: string, config?: AxiosRequestConfig): Promise<BaseHttpResponse<T> | undefined> {
  return axiosInstance
    .get<BaseHttpResponse<T>>(url, config)
    .then((response) => {
      return handleSuccessResponse<T>(response);
    })
    .catch((error) => {
      return handleErrorResponse(error);
    });
}

function download(url: string, config?: AxiosRequestConfig) {
  config = { ...config, responseType: 'blob' }
  return axiosInstance
    .get<Blob>(url, config)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return undefined;
    });
}

function downloadpost(url: string, data: any, config?: AxiosRequestConfig) {
  config = { ...config, responseType: 'blob' }
  return axiosInstance
    .post<Blob>(url, data, config)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return undefined;
    });
}

function post<TResponse>(
  url: string,
  data: any,
  config?: AxiosRequestConfig,
): Promise<BaseHttpResponse<TResponse> | undefined> {
  return axiosInstance
    .post<BaseHttpResponse<TResponse>>(url, data, config)
    .then((response: AxiosResponse) => {
      return handleSuccessResponse<TResponse>(response);
    })
    .catch((error: AxiosError) => {
      return handleErrorResponse(error);
    });
}

function put<TResponse>(
  url: string,
  data: any,
  config?: AxiosRequestConfig,
): Promise<BaseHttpResponse<TResponse> | undefined> {
  return axiosInstance
    .put<BaseHttpResponse<TResponse>>(url, data, config)
    .then((response: AxiosResponse) => {
      return handleSuccessResponse<TResponse>(response);
    })
    .catch((error: AxiosError) => {
      return handleErrorResponse(error);
    });
}

function del<TResponse>(url: string): Promise<BaseHttpResponse<TResponse> | undefined> {
  return axiosInstance
    .delete<BaseHttpResponse<TResponse>>(url)
    .then((response: AxiosResponse) => {
      return handleSuccessResponse<TResponse>(response);
    })
    .catch((error: AxiosError) => {
      return handleErrorResponse(error);
    });
}

function handleSuccessResponse<T>(response: AxiosResponse): BaseHttpResponse<T> | undefined {
  if (response && response.data) {
    //toast.success('crud.create.successMessage');
    const baseResponse = response.data as unknown as BaseHttpResponse<T>;
    if (baseResponse.hasErrors) {
      toast.error(baseResponse.message);
    }

    return baseResponse;
  }
  return undefined;
}

function handleErrorResponse<T>(error: AxiosError) {
  if (error.isAxiosError && error.response) {
    const response = error.response.data as any;
    const err: BaseHttpResponse<T> = {
      data: {} as T,
      errorCode: response.ErrorCode,
      hasErrors: true,
      message: response.Message,
      totalItems: 0,
    };

    if (error.status === 503) {
      err.errorCode = "503";
      err.message = "disabledFeature";
    }

    return err;
  }
  return undefined;
}

export const http = { get, post, put, del, download, downloadpost };
