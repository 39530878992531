import { useContext, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { NotificationCenterModel as Model, NotificationModel } from 'common/helpers/alerts/alerts.model';
import { NotificationItem } from './notifications/NotificationItem';
import DashboardContext from 'app/dashboard/context/dashboard.context'
import i18next from 'i18next'
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';

export function PinnedNotifications() {
  const initialData: Model = {
    type: 0,
    message: "",
    messageCode: "",
    existsNotificationNotRead: false,
    existsNotificationNotReceived: false,
    existsNotificationNotViewed: false,
    existsPinnedNotification: false,
    notifications: [],
    timestamp: ""
  }

  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();
  const { hubConnection } = useContext(DashboardContext);
  const [model, setModel] = useState<Model>(initialData);

  const notify = (model: Model, hubConnection: HubConnection) => {
    try {
      setModel(model);
      const notificationVieweds = model.notifications.filter(x => x.pinned && !x.viewed).map(x => x.id);
      if(notificationVieweds.length > 0) {
        hubConnection.send("viewed", notificationVieweds);
      }
    } catch (error) {
      console.error(error);
    }

  }

  useEffect(() => {
    const receiveMessages = async () => {
      if (hubConnection) {
        hubConnection.on("notify", (model: Model) => notify(model, hubConnection));
        if (hubConnection.state === HubConnectionState.Connected)
        hubConnection.send("myNotifications");
      }
    }

    receiveMessages();
    return (() => {
      if (hubConnection) {
        hubConnection.off("notify");
      }
    })
  }, [hubConnection]);

  const pinnedNotifications = model.notifications.filter(x => x.pinned);
  const pinnedNotificationsCount = model.notifications.filter(x => x.pinned).length;

  return (
    <>
      {
        model.existsPinnedNotification ?
          (
            <div className="card">
              <div className="card-body">
                <div className="alert m-0 p-0" role="alert">
                  <h4 className="alert-heading">{`${t("notifications.title")}`}</h4>
                  <ol className="list-group list-group-numbered  list-group-flush" style={{
                    "maxHeight": "100px",
                    "overflow": "auto"
                  }}>
                    {
                      pinnedNotifications
                        .map((n: NotificationModel) => <NotificationItem notification={n} key={n.id} />)
                    }
                  </ol>
                  <p className="text-body-tertiary text-end mt-1">
                    {
                      `${t("notifications.listInfo", { "notificationCount": pinnedNotificationsCount })}`
                    }
                  </p>
                </div>
              </div>
            </div>
          ) :
          (<></>)
      }
    </>
  );
}