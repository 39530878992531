import { http } from "common/helpers/axios/axios-helper";
import { buildFilterString, buildQueryString } from "common/helpers/queryUtils";
import { PagingOptionsModel } from "common/types/paging-options.model";
import { RobotExecutions, RobotExecutionsRequest } from "../models/robot-executions.model";
import { ErrorReason } from "../models/error-reason.model";
import { ErrorReasonRequest } from "../models/error-reason-request.model";
import { ErrorReasonResponse } from "../models/error-reason-response.model";

const basePath = '/agentlog';

async function getErrorReasons() {
  return await http.get<ErrorReason[]>(`/ErrorReason?IsActive=true`);
}

async function postMarkAsResolved(errorReasonRequest: ErrorReasonRequest) {
  return await http.post<ErrorReasonResponse>(`${basePath}/markAsResolved`, errorReasonRequest);
}

async function getExecutionLog({
  page,
  limit,
  sort,
  order,
  ...params }: RobotExecutionsRequest & PagingOptionsModel) {
  const queryString = buildQueryString({ page, limit, sort, order });
  const filterParams = buildFilterString(params);
  const url = `${basePath}${queryString}${filterParams}`;
  return await http.get<RobotExecutions[]>(url);
}

export const RobotExecutionsService = { getExecutionLog, getErrorReasons, postMarkAsResolved };