import i18next from 'i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import { ChangePasswordModel as Model } from '../../model/user-management.model';
import { UserManagementService as Service } from '../../services/user-management.service';
import { Modal } from 'react-bootstrap';
import ITSVG from 'common/helpers/ITSVG';
import closeModalIcon from 'assets/images/icons/close.svg'
import SaveButton from 'common/partials/SaveButton';

interface ChangePasswordModalProps {
  open: boolean;
  userName: string;
  name: string;
  id: number;
  handleClose: () => void;
}

export function ChangePassword(props: ChangePasswordModalProps) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const formSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t('crud.validators.requiredField').toString()),
    newPassword: Yup.string().required(t('crud.validators.requiredField').toString()),
    confirmNewPassword: Yup.string()
      .required(t('crud.validators.requiredField').toString())
      .oneOf([Yup.ref('newPassword'), null], t('crud.validators.passwordMatch').toString()),
  });

  let initialData: Model = {
    userName: '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values: any) => {
      setIsLoading(true);
      const body: Model = {
        userName: props.userName,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      };

      const response = await Service.changePasswordUser(body);
      if (response && !response.hasErrors) {
        toast.success(`${t('crud.update.successMessage')}`);
        setData(response.data);
        props.handleClose();
      } else if (response) {
        const errors = response.message.split('/');
        errors.forEach((err) => {
          const regex: RegExp = /(?<=\bCode:\s)(\w+)/gi;
          const result = regex.exec(err);
          if (result && result?.length > 0) {
            toast.error(`${t(`error.${result[0]}`)}`);
          } else {
            toast.error(`${t('crud.update.errorMessage')}`);
          }
        });
      }
      if (response) {
        if (response.hasErrors) {
        } else {
        }
      }
      setIsLoading(false);
    },
  });

  return (
    <Modal
      show={props.open}
      onHide={props.handleClose}
      dialogClassName='modal-dialog-centered mw-600px h-auto'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3>{t('form.passwordComponent.title')}</h3>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.handleClose}>
            <ITSVG path={closeModalIcon} className='svg-icon-1' />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <div className="row">
              <div className="col-12 mb-10">
                <label className="required form-label">{t('form.passwordComponent.oldPassword')}</label>
                <input
                  type="password"
                  name="oldPassword"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={t('form.passwordComponent.messages.oldPassword').toString()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <div className="mt-3 text-danger fw-bold">
                    <div className="fv-help-block">{formik.errors.oldPassword}</div>
                  </div>
                )}
              </div>
              <div className="col-12 mb-10">
                <label className="required form-label">{t('form.passwordComponent.newPassword')}</label>
                <input
                  type="password"
                  name="newPassword"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={t('form.passwordComponent.messages.newPassword').toString()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className="mt-3 text-danger fw-bold">
                    <div className="fv-help-block">{formik.errors.newPassword}</div>
                  </div>
                )}
              </div>
              <div className="col-12">
                <label className="required form-label">{t('form.passwordComponent.confirmNewPassword')}</label>
                <input
                  type="password"
                  name="confirmNewPassword"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={t('form.passwordComponent.messages.confirmNewPassword').toString()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
                  <div className="mt-3 text-danger fw-bold">
                    <div className="fv-help-block">{formik.errors.confirmNewPassword}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-end py-6 px-9">
            <button type="button" className="btn btn-light btn-active-light-primary me-2" onClick={props.handleClose}>
              {t('crud.update.buttonCancel')}
            </button>
            <SaveButton type="submit" loading={isLoading} />
          </div>
        </form>
      </div>
    </Modal>
  );
}
