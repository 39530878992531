import i18next from 'i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import en from '../../../../i18n/en-us.json';
import pt from '../../../../i18n/pt-br.json';
import es from '../../../../i18n/es-es.json';
import { ProcessModel as Model } from '../../../../model/process.model';
import { documentsService as Service } from '../services/documents.service';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap'
import ITSVG from 'common/helpers/ITSVG';
import closeModalIcon from 'assets/images/icons/close.svg'
import SaveButton from 'common/partials/SaveButton';

interface Props {
  id: number;
  open: boolean;
  handleOpen: (id: number) => void;
  handleClose: () => void;
  handleFetchModalData: () => void;
}

export function CreateEditDocuments(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const { id } = useParams();

  const formSchema = Yup.object().shape({
    name: Yup.string().required(t('crud.validators.requiredField')).max(70, t('crud.validators.maxLength_70')),
    description: Yup.string()
      .required(t('crud.validators.requiredField'))
      .max(1024, t('crud.validators.maxLength_1024')),
  });

  let initialData: Model = {
    id: parseInt(id ?? '0') ?? null,
    name: '',
    description: '',
    processId: parseInt(id ?? '0'),
  };

  const [data, setData] = useState<Model>(initialData);
  const [file, setFile] = useState<any>('');

  const onFileChange = (event: any) => {
    event.preventDefault();
    setFile({ selectedFile: event.target.files[0] });
  };

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: Model = {
          id: data.id,
          name: values.name,
          description: values.description,
          formFile: file.selectedFile,
          processId: data.processId,
        };
        if (props.id) {
          const result = await Service.putData(body);
          props.handleClose();
          if (result && !result?.hasErrors) {
            toast.success(`${t('crud.update.successMessage')}`);
          }
        } else {
          const result = await Service.postData(body);
          if (result && !result?.hasErrors) {
            toast.success(`${t('crud.create.successMessage')}`);
          }
        }
      } catch (error) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        props.handleClose();
        props.handleFetchModalData();
        setIsLoading(false);
      }
    },
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (props.id) {
        const settingsResponse = await Service.getDataById(props.id);
        if (settingsResponse && !settingsResponse.hasErrors) {
          setData(settingsResponse.data);
        }
      } else {
        setData(initialData);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.open) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Modal
      show={props.open}
      onHide={props.handleClose}
      dialogClassName='modal-dialog-centered mw-900px h-auto'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3>{t('addDocument')}</h3>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.handleClose}>
            <ITSVG path={closeModalIcon} className='svg-icon-1' />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-body scroll-y mx-5 my-5">
            <div className="row">
              <div className="col-md-6 mb-10">
                <label className="required form-label">{t('form.label.name')}</label>
                <input
                  type="text"
                  name="name"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={t('form.placeholder.name')}
                  value={formik.values.name ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="mt-3 text-danger fw-bold">{formik.errors.name}</div>
                )}
              </div>
              <div className="col-md-6 mb-10">
                <label className="required form-label">{t('form.label.description')}</label>
                <input
                  type="text"
                  name="description"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={t('form.placeholder.description')}
                  value={formik.values.description ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="mt-3 text-danger fw-bold">{formik.errors.description}</div>
                )}
              </div>
              <div className="col-12">
                <label className="required form-label">{t('form.label.file')}</label>
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="file"
                  name="formFile"
                  onChange={(event) => onFileChange(event)}
                />

                {formik.touched.formFile && formik.errors.formFile && (
                  <div className="mt-3 text-danger fw-bold">{formik.errors.formFile}</div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-end py-6 px-9">
            <button type="button" className="btn btn-light btn-active-light-primary me-2" onClick={props.handleClose}>
              {t('crud.create.buttonCancel')}
            </button>
            <SaveButton type="submit" loading={isLoading} />
          </div>
        </form>
      </div>
    </Modal>
  );
}
