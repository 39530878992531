import SVG from 'react-inlinesvg'

interface Props {
  className?: string
  path: string
  svgClassName?: string
}

export default function ITSVG({ className = '', path, svgClassName = 'mh-50px' }: Props) {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG src={path} className={svgClassName} />
    </span>
  )
}