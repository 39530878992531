/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { AgentLogStatus, ConnectionsViewModel as Model } from 'app/dashboard/model/dashboard.model'
import LoadingSpinner from 'common/partials/LoadingSpinner'
import { DashboardService as Service } from '../../services/dashboard.service'
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import DashboardContext from 'app/dashboard/context/dashboard.context'

type Props = {
  className: string;
}

export function SuspiciousExecutions(props: Props) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();
  const { hubConnection, dashboardFilters, setFilter } = useContext(DashboardContext);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const initialData: Model = {
    active: 0,
    inactive: 0,
    percentActive: 0,
    percentInactive: 0,
    totalQuantity: 0,
  }

  const [suspiciousExecutions, setSuspiciousExecutions] = useState<Model>(initialData);

  const fetchSuspiciousExecutions = async () => {
    setIsLoading(true);
    const response = await Service.getSuspiciousExecutions();
    if (response && !response.hasErrors) {
      setSuspiciousExecutions(response.data);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const receiveMessages = async () => {
      if (hubConnection) {
        hubConnection.on("UpdateConnection", message => setMessage(message))
      }
    }
    receiveMessages();

    return (() => {
      if (hubConnection) {
        hubConnection.off("UpdateConnection");
      }
    })
  }, [hubConnection]);

  useEffect(() => {
    fetchSuspiciousExecutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <div
      className={
        `card ${props.className} ${dashboardFilters.status === AgentLogStatus.Suspect ? "shadow border border-3 border-primary" : ""} ${suspiciousExecutions.totalQuantity > 0
          ? 'bg-light-warning'
          : undefined} cursor-pointer`
      }
      onClick={() => setFilter({...dashboardFilters, status: dashboardFilters.status === AgentLogStatus.Suspect ? undefined : AgentLogStatus.Suspect})}
    >
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          {
            isLoading
              ? <LoadingSpinner loading={isLoading} align="start" />
              : <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{suspiciousExecutions.totalQuantity}</span>
          }
          <span><i className={`fa-solid fa-triangle-exclamation fs-2x ${suspiciousExecutions.totalQuantity > 0 ? 'text-warning' : undefined}`}></i></span>
        </div>
        <div>
          <span className="fw-semibold fs-6 text-gray-400">{t('suspiciousExecutions.title')}</span>
        </div>
      </div>
    </div >
  )
}