import { Avatar } from '@mui/material';
import { Buffer } from 'buffer';

interface Props {
    url?: string,
    file?: any,
    buffer?: string,
    fullName: string,
    height?: number,
    shape?: 'circular' | 'rounded' | 'square',
}

export function ITLabAvatar(props: Props) {

    function stringAvatar(name: string) {
        const splitedName = name.split(' ');
        let initials = `${name.split(' ')[0][0]}`
        if (splitedName.length > 1) {
            initials += `${splitedName[splitedName.length - 1][0]}`
        }
        return {
            children: initials.toUpperCase(),
        };
    }

    function getAvatar() {
        let localShape = props.shape || 'rounded';

        if (props.file) {
            return <Avatar
                src={`data:image/png;base64,${props.file}`}
                variant={localShape}
                sx={{
                    height: props.height || '40px',
                    width: props.height || '40px',
                }}
            />;
        } else if (props.url) {
            return <Avatar
                src={props.url}
                variant={localShape}
                sx={{
                    height: props.height || '40px',
                    width: props.height || '40px',
                }}
            />;
        } else if (props.buffer) {
            const bufferData = Buffer.from(props.buffer, 'binary').toString('base64');
            return <Avatar
                src={`data:image/png;base64,${bufferData}`}
                variant={localShape}
                sx={{
                    height: props.height || '40px',
                    width: props.height || '40px',
                }}
            />;
        }

        return <Avatar
            {...stringAvatar(`${props.fullName}`)}
            variant={localShape}
            sx={{
                height: props.height || '40px',
                width: props.height || '40px',
            }}
        />;
    }

    return (
        <>
            <div className="symbol symbol-circle p-2">
                {getAvatar()}
            </div>
        </>
    );
}
