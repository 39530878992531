import { ReactElement, useEffect, useState } from 'react';
import { SMTPConfigModel, } from '../../model/health-check.model';
import { HealthCheckService } from '../../services/health-check.service';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import { CardItemModel } from 'common/layout/components/card/model/card.model';
import { Card } from 'common/layout/components/card/Card';

let smtpArray: CardItemModel[] = [];

export function SMTPHealthCheck() {
    i18next.addResourceBundle('us', 'translation', en);
    i18next.addResourceBundle("es", "translation", es);
    i18next.addResourceBundle('br', 'translation', pt);

    const { t, i18n } = useTranslation();
    const [smtpState, setSmtpState] = useState<CardItemModel[]>([]);
    const [smtpData, setSmtpData] = useState<SMTPConfigModel>();
    const [fragment, setFragment] = useState<ReactElement>(<></>);
    const [email, setEmail] = useState<string>('');

    function buildSMTPItems(data: SMTPConfigModel | undefined): void {
        if (!data) { return; }

        smtpArray = [];
        smtpArray.push({ title: t('healthCheck.smtp.method'), value: data.smtpDeliveryMethodName });
        smtpArray.push({ title: t('healthCheck.smtp.senderTool'), value: data.smtpSenderToolName });
        smtpArray.push({ title: t('healthCheck.smtp.defaultEmail'), value: data.smtpDefaultFromAddress });
        smtpArray.push({ title: t('healthCheck.smtp.defaultSender'), value: data.smtpDefaultSenderName });

        if (data.smtpDeliveryMethod === 0) {
            if (data.smtpSenderTool === 1 || data.smtpSenderTool === 2) {
                smtpArray.push({ title: t('healthCheck.smtp.sender'), value: data.smtpCredentialsUsername });
                smtpArray.push({ title: t('healthCheck.smtp.host'), value: data.smtpServerHost });
                smtpArray.push({ title: t('healthCheck.smtp.port'), value: data.smtpServerPort });
                smtpArray.push({ title: t('healthCheck.smtp.sslEnabled'), value: data.smtpEnableSsl ? t('generalMessages.yes') : t('generalMessages.no') });
                smtpArray.push({ title: t('healthCheck.smtp.username'), value: data.smtpCredentialsUsername });
                smtpArray.push({ title: t('healthCheck.smtp.password'), value: '*********' });
            }
            else if (data.smtpSenderTool === 4) {
                smtpArray.push({ title: t('healthCheck.smtp.smtp3rdPartyKey'), value: data.smtP3rdPartyKey });
            }
        }
        else if (data.smtpDeliveryMethod === 1) {
            smtpArray.push({ title: t('healthCheck.smtp.directory'), value: data.smtpPickupDirectoryLocation });
        }
        setSmtpState(smtpArray);
    }

    useEffect(() => {
        (async () => {
            buildSMTPItems(smtpData);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language, smtpData]);

    useEffect(() => {
        (async () => {
            try {
                const smtpResponse = await HealthCheckService.getSMTPConfig();
                if (smtpResponse && !smtpResponse.hasErrors) {
                    setSmtpData(smtpResponse.data);
                }
            } catch (err) {
                toast.error(`${t('crud.read.errorMessage')}`);
            } finally {
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        testsmtp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const testsmtp = () => {
        (async () => {
            try {
                const response = await HealthCheckService.testSMTPConfig(email);
                if (response) {
                    setFragment(response.hasErrors
                        ? <span className="badge badge-light-danger fs-6">{t('healthCheck.tests.failure')}</span>
                        : <span className="badge badge-light-success fs-6">{t('healthCheck.tests.success')}</span>)
                }
            } catch (err) {
                toast.error(`${t('crud.read.errorMessage')}`);
            } finally {
            }
        })();
    }

    const footer: ReactElement = (
        <>
            <div className='row'>
                <div className="col-md-6">
                    <input
                        type="email"
                        name="smtptestemail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control form-control-lg form-control-solid"
                    />
                </div>
                <div className="col-md-6">
                    <button type="button" className="btn btn-primary" onClick={testsmtp}>
                        {t('healthCheck.smtp.testagain')}
                    </button>
                </div>
            </div>
        </>);

    return (
        <>
            <Card title={t('healthCheck.smtp.title')} items={smtpState} headerTopRightElement={fragment} footerElement={footer}></Card>
        </>
    );
}
