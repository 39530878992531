import { useTranslation } from "react-i18next";

export function AlertEmpty() {
  const { t } = useTranslation();
  return (
    <>
      {/*begin::Item*/}
      <div className="d-flex flex-stack py-4">
        {/*begin::Section*/}
        <div className="d-flex align-items-center">
          {/*begin::Title*/}
          <div className="mb-0 me-2">
            <span className="fs-6 text-gray-800 text-hover-primary fw-bold text-left">
              {`${t("alerts.empty")}`}
            </span>
          </div>
          {/*end::Title*/}
        </div>
      </div>
      {/*end::Item*/}
    </>
  );

}