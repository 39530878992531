import { Modal } from "react-bootstrap"
import { AgentVersionModel as Model } from '../model/agent-versions.model'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';

interface NotesModalProps {
  IsOpen: boolean;
  Close: () => void;
  Version?: Model;
}

export function NotesModal(props: NotesModalProps) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  const { t } = useTranslation();

  return (
    <Modal
      show={props.IsOpen}
      dialogClassName="modal-dialog-centered mw-500px mw-lg-900px h-auto"
    >
      <div className="modal-content">
        <div className="modal-header p-6">
          <h4>{props.Version?.version ?? ""}</h4>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.Close}></button>
        </div>
        <div className="modal-body py-lg-10 px-lg-10">
          <span className="text-muted">{t(`agentVersions.notesModel.description`)}</span>
          <p>{props.Version?.notes ?? ""}</p>
        </div>
        <div className="modal-footer">
          <p><small className="text-muted">{t(`agentVersions.notesModel.hash`)}: </small>{props.Version?.hash  ?? ""}</p>
        </div>
      </div>
    </Modal>
  );
}