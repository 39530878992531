import i18next from 'i18next';
import moment from 'moment-timezone'
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import FullCalendar, { DateSelectArg, EventChangeArg, EventClickArg, EventContentArg, CalendarApi } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ScreenLoader from 'common/partials/ScreenLoader';
import en from '../../../i18n/en-us.json';
import pt from '../../../i18n/pt-br.json';
import es from '../../../i18n/es-es.json';
import { SchedulePutTimeViewModel } from '../../../model/schedule.model';
import { ScheduleService as Service } from '../../../services/schedule.service';
import { ActionsScheduleModal } from '../shared/ActionsScheduleModal';
import ScheduleContext from 'app/schedule/context/schedule.context';
import "../../../styles/CustomFullCalendar.css"

export default function ScheduleCalendarView() {
    i18next.addResourceBundle('us', 'translation', en);
    i18next.addResourceBundle("es", "translation", es);
    i18next.addResourceBundle('br', 'translation', pt);
    const { t } = useTranslation();

    const { fetchEvents, scheduleData, isLoading, isFirstFetch, startEndDate, setClickedDate, setOpenCreateScheduleModal, contextOptions } = useContext(ScheduleContext)

    const [clickedEvent, setClickedEvent] = useState<EventClickArg>();
    const [minTime, setMinTime] = useState('00:00:00')
    const [maxTime, setMaxTime] = useState('24:00:00')
    const [openActionsScheduleModal, setOpenActionsScheduleModal] = useState(false);

    const handleClickOpenActionsScheduleModal = () => { setOpenActionsScheduleModal(true); };
    const handleCloseActionsScheduleModal = () => { setOpenActionsScheduleModal(false); };

    const calendarRef: any = useRef()

    useEffect(() => {
        if (calendarRef.current) {
            if (startEndDate) {
                const calendarApi = calendarRef.current.getApi() as CalendarApi;

                const diffInHours = startEndDate.end.getTime() - startEndDate.start.getTime();
                const diffInDays = Math.round(diffInHours / (1000 * 3600 * 24));

                let dateToGo = startEndDate.start;
                if (diffInDays > 1) {
                    dateToGo = moment(startEndDate.start).add(diffInDays / 2, "days").toDate();
                }
                
                calendarApi.view.calendar.gotoDate(dateToGo);
            }
        }
    }, [startEndDate])

    useEffect(() => {
        if (calendarRef.current) {
            if (contextOptions) {
                const calendarApi = calendarRef.current.getApi() as CalendarApi;
                calendarApi.changeView(contextOptions.calendarViewType);
            }
        }
    }, [contextOptions?.calendarViewType])

    useEffect(() => {
        if (!contextOptions?.isCompactMode) {
            setMinTime('00:00:00')
            setMaxTime('24:00:00')
        } else {
            setMinTime('08:00:00')
            setMaxTime('19:00:00')
        }
    }, [contextOptions?.isCompactMode])

    const handleDateSelect = (selectInfo: DateSelectArg) => {
        const start = selectInfo.start
        const end = selectInfo.end
        const event = {
            start,
            end,
        }
        setClickedDate(event)
        setOpenCreateScheduleModal(true)
    }

    const handleEventClick = async (event: EventClickArg) => {
        setClickedEvent(event);
        handleClickOpenActionsScheduleModal()
    }

    const handleEvents = async (event: EventChangeArg) => {
        try {
            if (!event.event._def.extendedProps.allowEdit)
                return

            const id = parseInt(event.event.id);
            const oldStartDate = moment.utc(event.oldEvent.start!).format('YYYY-MM-DDTHH:mm');
            const oldEndDate = event.oldEvent.end ? moment.utc(event.oldEvent.end).format('YYYY-MM-DDTHH:mm') : moment.utc(event.oldEvent.start).format('YYYY-MM-DDTHH:mm');
            const newStartDate = moment.utc(event.event.start).format('YYYY-MM-DDTHH:mm');
            const newEndDate = event.event.end ? moment.utc(event.event.end).format('YYYY-MM-DDTHH:mm') : moment.utc(event.event.start).format('YYYY-MM-DDTHH:mm');
            const rescheduleId = event.event._def.extendedProps.rescheduleId

            const putBody: SchedulePutTimeViewModel = {
                id: id,
                oldStartDate: oldStartDate,
                oldEndDate: oldEndDate,
                newStartDate: newStartDate,
                newEndDate: newEndDate,
            }
            if (rescheduleId !== 0) {
                putBody.rescheduleId = event.event._def.extendedProps.rescheduleId
            }
            const result = await Service.putDataTime(putBody)
            if (!result?.hasErrors) {
                toast.success(`${t('crud.update.successMessage')}`);
            } else {
                toast.error(`${t(`error.${result?.message}`)}`);
            }
        }
        catch (error) {
            toast.error(`${t('crud.update.errorMessage')}`);
        }
        finally {
            fetchEvents()
        }
    }

    const customEventContent = (event: EventContentArg) => {
        return (
            <>
                {event.event.allDay ? (
                    <div className="fc-event-title fc-sticky">
                        {event.event.title}
                    </div>
                ) : (
                    <div className="fc-event-main">
                        <div className="fc-event-main-frame">
                            <div className="fc-event-time w-100">
                                {moment(event.event.start).format('HH:mm') + '-' + moment(event.event.end).format('HH:mm')}
                            </div>
                        </div>
                    </div>)}
            </>
        );
    }

    return <>
        <ActionsScheduleModal
            open={openActionsScheduleModal}
            onClose={handleCloseActionsScheduleModal}
            event={clickedEvent}
            fetchEvents={fetchEvents}
        />

        <div className='position-relative'>
            <FullCalendar
                ref={calendarRef}
                viewClassNames={"remove-header"}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                locale={`${t('locale')}`}
                initialView={contextOptions?.calendarViewType}
                events={scheduleData}
                headerToolbar={false}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }}
                slotLabelFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }}
                views={
                    {
                        "customTimeGridDay": {
                            type: 'timeGrid',
                            duration: { days: 1 }
                        }
                    }
                }
                contentHeight='auto'
                buttonText={{
                    today: `${t('calendar.buttons.today')}`,
                    month: `${t('calendar.buttons.month')}`,
                    week: `${t('calendar.buttons.week')}`,
                    day: `${t('calendar.buttons.day')}`,
                    list: `${t('calendar.buttons.list')}`,
                }}
                themeSystem='bootstrap'
                slotLabelClassNames='text-gray-600 fw-bold'
                dayHeaderClassNames='fw-boldest fs-7 text-capitalize'
                dayMaxEvents={false}
                allDaySlot={false}
                slotMinTime={minTime}
                slotMaxTime={maxTime}
                defaultAllDay={false}
                displayEventEnd={true}
                eventDisplay='block'
                editable={true}
                eventDurationEditable={false}
                selectable={true}
                eventClick={handleEventClick}
                eventContent={customEventContent}
                selectMirror={true}
                weekends={contextOptions?.isWeekendsVisible}
                select={handleDateSelect}
                eventChange={handleEvents}
            />
            {scheduleData.length === 0 && !isFirstFetch && (
                <div className='text-center fs-3 fw-bold position-absolute top-0 w-100 h-100' style={{ zIndex: 50, background: 'rgba(0, 0, 0, 0.3)' }}>
                    <div className='position-absolute top-50 w-100 text-white'>{t('calendar.noDataMessage')}</div>
                </div>
            )}
        </div>
        <ScreenLoader isLoading={isLoading} />
    </>;
}