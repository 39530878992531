import i18next from 'i18next';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import es from '../../i18n/es-es.json';
import { UserProfileModel as Model } from '../../model/user-profile.model';
import { UserProfileService as Service } from '../../service/user-profile.service';
import SaveButton from 'common/partials/SaveButton';

interface LanguageOptionsModel {
  name: string;
  value: string;
}

export function EditProfile(props: any) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const helper = new TokenHelper();
  const userId = helper.GetId();
  const [languageDefaultValue, setLanguageDefaultValue] = useState<LanguageOptionsModel | undefined | null>(null);

  const languageOptions: LanguageOptionsModel[] = [
    {
      name: "Português",
      value: "br"
    },
    {
      name: "English",
      value: "us"
    }
  ];

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('crud.validators.requiredField'))
      .max(70, t('crud.validators.maxLength_70'))
      .min(5, t('crud.validators.minLength_5'))
      .matches(/\w+(?:[, ]\w+)+/, t('crud.validators.minTwoWords')),
    language: Yup.string()
      .required(t('crud.validators.requiredField')),
  });

  let initialData: Model = {
    id: 0,
    name: '',
    email: '',
  };

  const [data, setData] = useState(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values: any) => {
      try {
        setIsLoading(true);
        setIsLoading(true);

        const name = values.name.trim();

        const editProfileBody: Model = {
          id: data.id,
          language: values.language,
          name: name,
        };

        const response = await Service.putUser(editProfileBody);
        if (response) {
          if (response.hasErrors) {
            toast.error(`${t(`error.${response.errorCode}`)}`);
            const errors = response.message.split('/');
            errors.forEach((err) => {
              const regex: RegExp = /(?<=\bCode:\s)(\w+)/gi;
              const result = regex.exec(err);
              if (result && result?.length > 0) {
                toast.error(`${t(`error.${result[0]}`)}`);
              }
            });
          } else {
            toast.success(`${t('crud.update.successMessage')}`);
            setData(response.data);
            fetchUsers();
            props.onHandleClose();
          }
        }
      } catch (error) {
        toast.error(`${t('crud.update.errorMessage')}`);
      } finally {
        setIsLoading(false);
        setIsLoading(false);
      }
    },
  });

  const fetchUsers = () => {
    (async () => {
      try {
        setIsLoading(true);
        setIsLoading(true);
        if (userId) {
          const response = await Service.getUserById(userId);
          if (response && !response.hasErrors) {
            setData(response.data);
            setLanguageDefaultValue(languageOptions.find(value => value.value === response.data.language));
          }
        }
      } catch (err) {
        toast.error(`${t('crud.read.errorMessage')}`);
      } finally {
        setIsLoading(false);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form w-100">
        <div className="card w-100 mb-5">
          <div className="card-header d-flex justify-content-between align-items">
            <div className="card-title align-items">
              <h2>{t('userProfile.editProfileTitle')}</h2>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="fv-row fv-plugins-icon-container">
              <label className="required form-label mt-10">{t('form.name')}</label>

              <input
                type="text"
                name="name"
                className="form-control form-control-lg form-control-solid"
                placeholder={t('form.placeholder.name')}
                value={formik.values.name ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="mt-3 text-danger fw-bold">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              )}

              <div className="mt-3 text-danger fw-bold invalid-feedback"></div>
            </div>
            <div className=" fv-row fv-plugins-icon-container">
              <label className="required form-label mt-10">{t('form.email')}</label>

              <label className="form-control form-control-lg form-control-solid">{data.email}</label>

              <div className="mt-3 text-danger fw-bold invalid-feedback"></div>
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
              <div className="mt-3 text-danger fw-bold invalid-feedback"></div>
            </div>

            <div>
              <div className="mb-10 row fv-plugins-icon-container">
                <div className="col-6">
                  <label className="required form-label">{t('form.language.label')}</label>
                  <Autocomplete
                    id="language"
                    options={languageOptions}
                    value={languageDefaultValue ?? null}
                    getOptionLabel={option => option.name ? option.name : ""}
                    onChange={(_, value) => {
                      setLanguageDefaultValue(value)
                      formik.setFieldValue("language", value?.value);
                    }}
                    onInputChange={(_, value) => {
                      if (!value) {
                        setLanguageDefaultValue(null)
                        formik.setFieldValue("language", undefined)
                      }
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onBlur={formik.handleBlur}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        placeholder={`${t('generalMessages.select')}`}
                      />
                    }
                  />
                  {formik.errors.language && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.language}</div>
                  )}
                </div>
                <div className="mt-3 text-danger fw-bold invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="button" className="btn btn-light btn-active-light-primary me-2" onClick={props.onHandleClose}>
              {t('crud.update.buttonCancel')}
            </button>
            <SaveButton type="submit" loading={isLoading} />
            <SaveButton type="submit" loading={isLoading} />
          </div>
        </div>
      </form>
    </>
  );
}
