import FeatureFlagEnum, { FeatureFlagMapping } from 'common/enums/FeatureFlagEnum';
import { RolesDictionary } from '../dictionaries/roles-dictionary';
import { AuthToken } from './auth-token';

export type Language = 'us' | 'br' | 'es';

export class TokenHelper {
  private token: string | null;
  private language?: Language;
  private userId?: number;
  private userName?: string;
  private email?: string;
  private roles?: number[];
  private unique_name?: string;
  private enabledFeatures?: FeatureFlagEnum[];

  constructor() {
    this.token = AuthToken.getToken();
    const decodedToken = AuthToken.getDecodedToken() as any;
    let roleArray: string[] = [];

    if (decodedToken) {
      if (Array.isArray(decodedToken.role)) {
        roleArray = decodedToken.role as string[];
      } else {
        roleArray.push(decodedToken.role);
      }

      this.language = decodedToken.language;
      this.userId = decodedToken.userid;
      this.userName = decodedToken.username;
      this.email = decodedToken.email;
      this.roles = roleArray.map((r) => parseInt(r));
      this.unique_name = decodedToken.unique_name;

      this.enabledFeatures = decodedToken.enabledFeatures
                                         .split(',')
                                         .map((val: number) => FeatureFlagMapping[val])
                                         .filter((feature: any): feature is FeatureFlagEnum => feature !== undefined);
    }
  }

  GetLanguage(): Language {
    const localLang = localStorage.getItem('tokenLanguage');
    if (localLang === 'br')
      return 'br'
    else if (localLang === 'us')
      return 'us'
    else if (localLang === 'es')
      return 'es'
    else
      return this.language || 'br';
  }

  SetLanguage(language: Language) {
    this.language = language;
    localStorage.setItem('tokenLanguage', language);
  }

  GetName(): string {
    return this.unique_name || '';
  }

  GetId(): number {
    return this.userId || 0;
  }

  GetEmail(): string {
    return this.email || '';
  }

  FeatureIsEnabled(feature: FeatureFlagEnum): boolean {
    if (!this.enabledFeatures) {
      return false;
    }
    return this.enabledFeatures.includes(feature);
  }

  private HasRoles(): boolean {
    if (!this.roles) return false;

    return this.roles.length > 0;
  }

  HasAccess(roles: number[]): boolean {
    roles.push(RolesDictionary.Security.Administrator);
    if (!this.HasRoles()) {
      return false;
    }

    const found = this.roles?.find((item) => roles.indexOf(item) >= 0);
    return found ? found! > 0 : false;
  }

  HasToken(): boolean {
    return this.token != null;
  }
}
