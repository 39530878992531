import FeatureFlagEnum from "common/enums/FeatureFlagEnum";
import { TokenHelper } from "common/helpers/auth/token-helper";

interface FeatureFlagProps {
    feature: FeatureFlagEnum;
    children: JSX.Element;
}

export default function FeatureFlag(props: FeatureFlagProps){
    const tokenHelper = new TokenHelper();
    return tokenHelper.FeatureIsEnabled(props.feature) 
            ? props.children
            : <></>
}