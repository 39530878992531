import { BreadcrumbsComponent } from "../header/BreadcrumbsComponent";

interface Props {
    children?: React.ReactNode;
}

export function MasterLayout(props: Props) {

    return (
        <>
            <div id="kt_content_container" className="container-fluid">
                <div className='d-flex d-lg-none mb-5 mb-lg-0'>
                    <BreadcrumbsComponent />
                </div>
                {props.children}
            </div>
        </>
    );
}