import { http } from 'common/helpers/axios/axios-helper';
import { DepartamentsLinkModel as Model } from '../model/departaments-link.model';

const basePath = '/Process';

async function getDataList(id: any) {
  return await http.get<Model[]>(`${basePath}/${id}/department`);
}

async function getDataById(id: any) {
  return await http.get<Model>(`${basePath}/${id}`);
}

async function postData(data: Model) {
  return await http.post<Model>(`${basePath}/department`, data);
}

async function putData(data: Model) {
  return await http.put<Model>(`${basePath}/${data.id}`, data);
}

async function deleteDataById(data: any) {
  return await http.put(`${basePath}/department`, data);
}

export const DepartamentsLinkService = { getDataList, getDataById, postData, putData, deleteDataById };
