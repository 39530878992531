import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CardItemModel } from './model/card.model';
import { useTranslation } from 'react-i18next';

interface Props {
  model: CardItemModel;
}

export function CardItem({ model }: Props) {
  const { t } = useTranslation();
  const [currentItem, setCurrentItem] = useState<CardItemModel>({ title: '', value: '' });
  useEffect(() => {
    (async () => {
      try {
        setCurrentItem(model);
      } catch (err) {
        toast(`${t('crud.read.errorMessage')}`);
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <>
      <div className="row mb-7">
        <label className="col-lg-4 fw-bold text-muted">{currentItem.title}</label>
        <div className="col-lg-8">
          <span className="fw-bolder fs-6 text-dark">{currentItem.value?.toString()}</span>
        </div>
      </div>
    </>
  );
}
