import i18next from "i18next";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap"
import { useFormik } from "formik";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import copyIcon from "assets/images/icons/copy.svg";
import closeModalIcon from "assets/images/icons/close.svg"
import ITSVG from "common/helpers/ITSVG";
import SaveButton from "common/partials/SaveButton";
import { DepartamentsModel } from "app/departaments/model/departaments.model";
import { ScheduleService } from "app/schedule/services/schedule.service";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { APIClientsModel as Model } from "../model/api-clients.model";
import { APIClientsService as Service } from "../services/api-clients.service";

interface Props {
  open: boolean;
  handleClose: () => void;
  fetchData: () => void;
  editId: any;
}

export function CreateEditAPIClient(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isGettingSecret, setIsGettingSecret] = useState(false);
  const { t } = useTranslation();
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const [isNewAPIClient, setIsNewAPIClient] = useState(true);
  const [departments, setDepartaments] = useState<DepartamentsModel[]>([]);
  const [departmentDefaultValue, setDepartmentDefaultValue] = useState<DepartamentsModel | undefined | null>(null);
  const [tooltipText, setTooltipText] = useState<string>(t("generalMessages.copy"));

  const formSchema = Yup.object().shape({
    clientId: isNewAPIClient ?
      Yup.string()
        .required(t("crud.validators.requiredField"))
        .min(5, t("crud.validators.minLength_5"))
        .max(70, t("crud.validators.maxLength_70"))
        .matches(/^[A-Za-z0-9_-]*$/, t("crud.validators.onlyLettersOrNumbers"))
      : Yup.string()
        .notRequired(),
    description: Yup
      .string()
      .required(t("crud.validators.requiredField"))
      .min(5, t("crud.validators.minLength_5"))
      .max(1024, t("crud.validators.maxLength_1024")),
    type: Yup
      .number()
      .required(t("crud.validators.requiredField")),
    updateTokenTime: Yup
      .number()
      .required(t("crud.validators.requiredField")),
    origin: Yup
      .string()
      .required(t("crud.validators.requiredField")),
    clientSecret: isNewAPIClient ?
      Yup.string()
        .required(t("crud.validators.requiredField"))
        .min(32, t("crud.validators.minLength_32"))
        .max(70, t("crud.validators.maxLength_70"))
      : Yup.string()
        .notRequired(),
    departmentId: isNewAPIClient ?
      Yup.string()
        .required(t("crud.validators.requiredField"))
      : Yup.string()
        .notRequired(),
  });

  let initialData: Model = {
    id: 0,
    clientId: "",
    description: "",
    type: 0,
    updateTokenTime: 0,
    origin: "",
    clientSecret: "",
    isActive: true,
    departmentId: undefined
  };

  const [clientSecret, setClientSecret] = useState("");
  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: Model = {
          id: data.id,
          clientId: values.clientId,
          description: values.description,
          type: Number(values.type),
          updateTokenTime: values.updateTokenTime,
          origin: values.origin,
          clientSecret: clientSecret,
          isActive: values.isActive,
          departmentId: values.departmentId
        };

        if (props.editId > 0) {
          if (clientSecret === '') {
            delete body.clientSecret;
          }
          delete body.departmentId;
          const result = await Service.putData(body);
          if (result && !result?.hasErrors) {
            toast.success(`${t("crud.update.successMessage")}`);
          } else {
            toast.error(`${t(`error.${result?.errorCode}`)}`);
          }
        } else {
          const result = await Service.postData(body);
          if (result && !result?.hasErrors) {
            toast.success(`${t("crud.create.successMessage")}`);
          } else {
            toast.error(`${t(`error.${result?.errorCode}`)}`);
          }
        }
      } catch (error) {
        toast.error(`${t("crud.update.errorMessage")}`);
      } finally {
        props.handleClose();
        props.fetchData();
        setIsLoading(false);
      }
    },
  });

  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text)
  }
  const getClientSecret = async () => {
    try {
      setIsGettingSecret(true);
      const secret = await Service.getRandomSecret();
      if (secret) {
        setClientSecret(secret.data.guid);
        formik.setFieldValue("clientSecret", secret.data.guid);
      }
    } catch (err) {
      toast.error(`${t("crud.read.errorMessage")}`);
    } finally {
      setIsGettingSecret(false);
    }
  }

  const fetchData = async () => {
    try {
      setIsLoading(true);
      formik.resetForm();
      if (props.editId > 0) {
        setIsNewAPIClient(false);
        const response = await Service.getDataById(props.editId);
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      } else {
        setData(initialData);
        setIsNewAPIClient(true);
      }
    } catch (err) {
      toast.error(`${t("crud.read.errorMessage")}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDepartaments = async () => {
    setIsLoading(true);
    const response = await ScheduleService.getDepartamentsList();
    if (response && !response.hasErrors) {
      setDepartaments(response.data);
      setDepartmentDefaultValue(response?.data.find(value => value.id === data.department?.id));
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (props.open) {
      fetchData();
      setClientSecret("");
      setDepartaments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if ((data && data !== initialData) || (data && !props.editId)) {
      fetchDepartaments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <Modal
        show={props.open}
        onHide={props.handleClose}
        dialogClassName="modal-dialog-centered mw-900px h-auto"
      >
        <div className="modal-content">
          <div className="modal-header p-6">
            <h3>{props.editId ? t("clientsApi.form.titleEdit") : t("clientsApi.form.title")}</h3>
            <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={props.handleClose}>
              <ITSVG path={closeModalIcon} className="svg-icon-1" />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body scroll-y mx-5 my-5">
              <div className="row">
                <div className="col-md-6 mb-10">
                  <label className={`${props.editId === 0 ? "required" : ""} form-label`}>{t("clientsApi.form.label.clientId")}</label>
                  <input
                    disabled={props.editId > 0}
                    type="text"
                    name="clientId"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t("clientsApi.form.placeholder.clientId")}
                    value={formik.values.clientId ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.clientId && formik.errors.clientId && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.clientId}</div>
                  )}
                </div>

                <div className="col-md-6 mb-10">
                  <label className="required form-label">{t("clientsApi.form.label.description")}</label>
                  <input
                    type="text"
                    name="description"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t("clientsApi.form.placeholder.description")}
                    value={formik.values.description ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.description}</div>
                  )}
                </div>

                <div className="col-md-6 mb-10">
                  <label className="required form-label">{t("clientsApi.form.label.origin")}</label>
                  <input
                    type="text"
                    name="origin"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t("clientsApi.form.placeholder.origin")}
                    value={formik.values.origin ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.origin && formik.errors.origin && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.origin}</div>
                  )}
                </div>

                <div className="col-md-6 mb-10">
                  <label className="form-label">{t("clientsApi.form.label.updateTokenTime")}</label>
                  <input
                    type="number"
                    name="updateTokenTime"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t("clientsApi.form.placeholder.updateTokenTime")}
                    value={formik.values.updateTokenTime ?? 0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.updateTokenTime && formik.errors.updateTokenTime && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.updateTokenTime}</div>
                  )}
                </div>

                <div className="col-md-6 mb-10">
                  <label className={`${props.editId === 0 ? "required" : ""} form-label`}>{t("clientsApi.form.label.department")}</label>
                  <Autocomplete
                    id='departmentId'
                    options={departments}
                    value={departmentDefaultValue ?? null}
                    getOptionLabel={option => option.name ? option.name : ""}
                    onChange={(e, value) => {
                      setDepartmentDefaultValue(value)
                      formik.setFieldValue('departmentId', value?.id)
                    }}
                    onInputChange={(_, value) => {
                      if (!value) {
                        setDepartmentDefaultValue(null)
                        formik.setFieldValue("departmentId", undefined)
                      }
                    }}
                    onBlur={() => formik.setFieldTouched("departmentId")}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        placeholder={`${t('generalMessages.select')}`}
                      />
                    }
                    noOptionsText={t('filter.noDataMessage.department')}
                    disabled={!isNewAPIClient}
                  />
                  {formik.touched.departmentId && formik.errors.departmentId && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.departmentId}</div>
                  )}
                </div>

                <div className="col-md-6 mb-10">
                  <label className="form-label">{t("clientsApi.form.label.type.title")}</label>
                  <div className="d-flex fv-row mb-3">
                    <div className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        name="type"
                        id="confidential"
                        value={0}
                        checked={formik.values.type === 0}
                        onChange={() => formik.setFieldValue("type", 0)}
                      />
                      <label className="form-check-label" htmlFor="confidential">
                        <div className="fw-bold text-gray-800">{t("clientsApi.form.label.type.confidential")}</div>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex fv-row">
                    <div className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        name="type"
                        id="public"
                        value={1}
                        checked={formik.values.type === 1}
                        onChange={() => formik.setFieldValue("type", 1)}
                      />
                      <label className="form-check-label" htmlFor="public">
                        <div className="fw-bold text-gray-800">{t("clientsApi.form.label.type.public")}</div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-10">
                  <label className="form-label">{t("clientsApi.form.label.status")}</label>
                  <div className="form-check form-check-solid form-switch">
                    <input
                      className="form-check-input w-45px h-30px"
                      type="checkbox"
                      id="isActive"
                      name="isActive"
                      checked={formik.values.isActive}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.values.isActive ? (
                    <div className="text-muted fs-7 mt-5">{t("generalMessages.active")}</div>
                  ) : (
                    <div className="text-muted fs-7 mt-5">{t("generalMessages.inactive")}</div>
                  )}
                </div>

                <div className="col-md-12 mb-3">
                  <label className={`${props.editId ? '' : 'required'} form-label`}>{t("clientsApi.form.label.clientSecret")}</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="clientSecret"
                      className="form-control form-control-lg form-control-solid"
                      placeholder={t("clientsApi.form.placeholder.clientSecret")}
                      value={clientSecret}
                      onChange={(e) => {
                        setClientSecret(e.target.value)
                        formik.handleChange(e)
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <Tooltip title={tooltipText} placement="top" arrow>
                      <button
                        type="button"
                        className="btn btn-bg-light btn-active-color-primary"
                        onClick={() => {
                          copyToClipboard(clientSecret);
                          setTooltipText(t("generalMessages.copied"));

                          setTimeout(() => {
                            setTooltipText(t("generalMessages.copy"));
                          }, 2000);
                        }}
                      >
                        <ITSVG path={copyIcon} className="svg-icon-1" />
                      </button>
                    </Tooltip>
                  </div>
                  {formik.touched.clientSecret && formik.errors.clientSecret && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.clientSecret}</div>
                  )}
                </div>

                <div className="col-12 mb-5">
                  <div className="d-grid">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => getClientSecret()}
                      disabled={isGettingSecret}
                    >
                      {!isGettingSecret && t("clientsApi.form.generateKey")}
                      {isGettingSecret && (
                        <span className="indicator-progress" style={{ display: "block" }}>
                          {t("crud.common.wait")}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>

                <div className="col-12">
                  <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect></svg>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bold">
                        <h4 className="text-gray-800 fw-bolder">{t("clientsApi.form.disclaimer.title")}</h4>
                        <div className="fs-6 text-gray-600">{t("clientsApi.form.disclaimer.subtitle")}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-end py-6 px-9">
              <button
                type="button"
                className="btn btn-light btn-active-light-primary me-2"
                onClick={props.handleClose}
              >
                {t("crud.create.buttonCancel")}
              </button>
              <SaveButton type="submit" loading={isLoading} />
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
