import { useTranslation } from 'react-i18next';

interface Props {
  handleClearFields?: () => void;
  handleChange: (e: any) => void;
  recurringType: number;
  setRecurringType: (type: number) => void;
}

export function Step2(props: Props) {

  const { t } = useTranslation();

  return (
    <div className="mb-10">
      <div className="pb-8">
        <h2 className="fw-bolder text-dark">{t('scheduleModal.fields.recurrence.label')}</h2>
        <span className="text-gray-400 fw-bold fs-6">{t('scheduleModal.fields.recurrence.tooltip')}</span>
      </div>
      <div className="d-flex fv-row">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input me-3"
            type="radio"
            name='recurringType'
            id='noRecurrence'
            defaultValue={0}
            defaultChecked={props.recurringType === 0}
            onChange={(e: any) => {
              props.handleChange(e);
              props.setRecurringType(0)
            }}
          />
          <label className="form-check-label" htmlFor="noRecurrence">
            <div className="fw-bold text-gray-800">{t('scheduleModal.fields.recurrence.options.none')}</div>
            <div className="text-gray-600">{t('scheduleModal.fields.recurrence.description.none')}</div>
          </label>
        </div>
      </div>
      <div className="separator separator-dashed my-5"></div>
      <div className="d-flex fv-row">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input me-3"
            type="radio"
            name='recurringType'
            id='dailyRecurrence'
            value={1}
            defaultChecked={props.recurringType === 1}
            onChange={(e: any) => {
              props.handleChange(e);
              props.setRecurringType(1)
            }}
          />
          <label className="form-check-label" htmlFor="dailyRecurrence">
            <div className="fw-bold text-gray-800">{t('scheduleModal.fields.recurrence.options.daily')}</div>
            <div className="text-gray-600">{t('scheduleModal.fields.recurrence.description.daily')}</div>
          </label>
        </div>
      </div>
      <div className="separator separator-dashed my-5"></div>
      <div className="d-flex fv-row">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input me-3"
            type="radio"
            name='recurringType'
            id='weeklyRecurrence'
            value={2}
            defaultChecked={props.recurringType === 2}
            onChange={(e: any) => {
              props.handleChange(e);
              props.setRecurringType(2)
            }}
          />
          <label className="form-check-label" htmlFor="weeklyRecurrence">
            <div className="fw-bold text-gray-800">{t('scheduleModal.fields.recurrence.options.weekly')}</div>
            <div className="text-gray-600">{t('scheduleModal.fields.recurrence.description.weekly')}</div>
          </label>
        </div>
      </div>
      <div className="separator separator-dashed my-5"></div>
      <div className="d-flex fv-row">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input me-3"
            type="radio"
            name='recurringType'
            id='montlyRecurrence'
            value={3}
            defaultChecked={props.recurringType === 3}
            onChange={(e: any) => {
              props.handleChange(e);
              props.setRecurringType(3)
            }}
          />
          <label className="form-check-label" htmlFor="montlyRecurrence">
            <div className="fw-bold text-gray-800">{t('scheduleModal.fields.recurrence.options.monthly')}</div>
            <div className="text-gray-600">{t('scheduleModal.fields.recurrence.description.monthly')}</div>
          </label>
        </div>
      </div>
    </div>
  )
}
