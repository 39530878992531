import i18next from 'i18next';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import * as Yup from "yup";
import { RobotExecutionsService as Service } from '../services/robot-executions.service';
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ErrorReason } from '../models/error-reason.model';
import { ErrorReasonRequest as Model } from "../models/error-reason-request.model";
import { useFormik } from 'formik';
import { RobotExecutionsErrorReason } from '../models/robot-executions.model';

interface ErrorDescriptionModalProps {
    show: boolean;
    onClose: () => void;
    agenteLogErrorReason?: RobotExecutionsErrorReason;
}
export default function ErrorDescriptionModal(props: ErrorDescriptionModalProps) {
    i18next.addResourceBundle('us', 'translation', en);
    i18next.addResourceBundle("es", "translation", es);
    i18next.addResourceBundle('br', 'translation', pt);
    const { t } = useTranslation();

    const [errorReasonsArray, setErrorReasonsArray] = useState<ErrorReason[]>([]);

    const formSchema = Yup.object().shape({
        reason: Yup
            .string()
            .required(t("crud.validators.requiredField"))
            .min(5, t("crud.validators.minLength_5"))
            .max(1024, t("crud.validators.maxLength_1024")),
        errorReasonId: Yup
            .number()
            .required(t("crud.validators.requiredField"))
    });

    let initialData: Model = {
        agentLogId: props.agenteLogErrorReason?.agentLogId ?? 0,
        errorReasonId: props.agenteLogErrorReason?.id ?? 0,
        reason: props.agenteLogErrorReason?.reason ?? "",
    };

    const formik = useFormik<Model>({
        enableReinitialize: true,
        initialValues: initialData,
        validationSchema: formSchema,
        onSubmit: async (values) => {
            try {
                const body: Model = {
                    agentLogId: props.agenteLogErrorReason?.agentLogId!,
                    reason: values.reason,
                    errorReasonId: values.errorReasonId
                };

                const result = await Service.postMarkAsResolved(body);
                if (result && !result?.hasErrors) {
                    toast.success(`${t("crud.create.successMessage")}`);
                    props.onClose();
                } else {
                    toast.error(`${t(`error.${result?.errorCode}`)}`);
                }
            } catch (error) {
                toast.error(`${t("crud.update.errorMessage")}`);
            }
        },
    });

    const fetchErrorType = async () => {
        try {
            const response = await Service.getErrorReasons();
            if (response && !response.hasErrors) {
                setErrorReasonsArray(response.data);
            }
        } catch (err) {
            toast.error(`${t('crud.read.errorMessage')}`);
        } finally {
        }
    };

    useEffect(() => {
        fetchErrorType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        <Modal show={props.show} onHide={props.onClose}>
            <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                        <h3>{t("robotExecutions.errorReason.title")}</h3>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <FormControl variant="filled" style={{ width: "100%" }}>
                                    <label className="form-label required me-3">{t("robotExecutions.errorReason.type")}</label>
                                    <Select
                                        name="errorReasonId"
                                        MenuProps={{ disableScrollLock: true }}
                                        defaultValue={props.agenteLogErrorReason?.errorReasonId}
                                        onChange={formik.handleChange}
                                    >
                                        {errorReasonsArray?.map((value: ErrorReason) => (
                                            <MenuItem value={value.id} key={value.id}>
                                                {value.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formik.touched.errorReasonId && formik.errors.errorReasonId && (
                                        <div className="mt-3 text-danger fw-bold">{formik.errors.errorReasonId}</div>
                                    )}
                                </FormControl>
                            </div>
                            <div className="col-md-12">
                                <FormControl variant="filled" style={{ width: "100%" }}>
                                    <label className="form-label required me-3">{t("robotExecutions.errorReason.description")}</label>
                                    <textarea
                                        className="form-control form-control-lg form-control-solid"
                                        name="reason"
                                        defaultValue={props.agenteLogErrorReason?.reason}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                    {formik.touched.reason && formik.errors.reason && (
                                        <div className="mt-3 text-danger fw-bold">{formik.errors.reason}</div>
                                    )}
                                </FormControl>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-6">
                                <button type="submit" className="btn btn-danger my-3">
                                    {t('crud.create.buttonSave')}
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button type="button" className="btn btn-active-light-primary my-3" onClick={props.onClose}>
                                    {t('crud.create.buttonCancel')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    </>;
}