import { http } from 'common/helpers/axios/axios-helper';
import { GroupManagementModel as Model } from '../model/group-management.model';

const basePath = '/group';

async function getGroupManagementList() {
  return await http.get<Model[]>(basePath);
}

async function getGroupById(groupId: any) {
  return await http.get<Model>(`${basePath}/${groupId}`);
}

async function getGroupManagementListFiltering(page: number = 0, limit: number = 10, query: string) {
  var queryFilter = `_page=${page}&_limit=${limit}`;
  if (query !== '') {
    queryFilter += `&${query}`;
  }

  return await http.get<Model[]>(`${basePath}?${queryFilter}`);
}

async function postGroup(group: Model) {
  return await http.post<Model>(`${basePath}`, group);
}

async function putGroup(group: Model) {
  return await http.put(`${basePath}/${group.id}`, group);
}

async function deleteGroupById(id: any) {
  return await http.del(`${basePath}/${id}`);
}

export const GroupManagementService = {
  getGroupById,
  getGroupManagementList,
  getGroupManagementListFiltering,
  postGroup,
  putGroup,
  deleteGroupById
};
