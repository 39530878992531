import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { APP_ROUTES } from './routes';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import { UserManagement } from 'app/user-management/components/UserManagement';
import { GroupManagement } from 'app/group-management/components/GroupManagement';
import { UserProfile } from 'app/user-profile/components/UserProfile';
import { Unauthorized } from 'app/errors-page/components/Unauthorized';
import { NotFound } from 'app/errors-page/components/Not-Found';
import { HealthCheck } from 'app/health-check/components/HealthCheck';
import { GeneralSettings } from 'app/general-settings/components/GeneralSettings';
import { CreateEditUser } from 'app/user-management/components/CreateEditUser';
import { CreateEditGroup } from 'app/group-management/components/CreateEditGroup';
import { Dashboard } from 'app/dashboard/components/Dashboard';
import { Countries } from 'app/countries/components/Countries';
import { CreateEditCountries } from 'app/countries/components/CreateEditCountries';
import { Departaments } from 'app/departaments/components/Departaments';
import { CreateEditDepartaments } from 'app/departaments/components/CreateEditDepartaments';
import { Sites } from 'app/sites/components/Sites';
import { CreateEditSites } from 'app/sites/components/CreateEditSites';
import { CreateEditRobotType } from 'app/robot-type/components/CreateEditRobotType';
import { RobotType } from 'app/robot-type/components/RobotType';
import { OperationSystem } from 'app/operation-system/components/OperationSystem';
import { CreateEditOperationSystem } from 'app/operation-system/components/CreateEditOperationSystem';
import { Processes } from 'app/processes/components/Processes';
import { CreateEditProcesses } from 'app/processes/components/CreateEditProcesses';
import { CreateEditTechnologies } from 'app/technologies/components/CreateEditTechnologies';
import { Technologies } from 'app/technologies/components/Technologies';
import { MachineType } from 'app/machine-type/components/MachineType';
import { CreateEditMachineType } from 'app/machine-type/components/CreateEditMachineType';
import { Machine } from 'app/machine/components/Machine';
import { CreateEditMachine } from 'app/machine/components/CreateEditMachine';
import { Schedule } from 'app/schedule/components/Schedule';
import { APIClients } from 'app/api-clients/components/APIClients';
import { Loading } from 'app/login/components/Loading';
import { MachineActivity } from 'app/machine-activity/components/MachineActivity';
import { AgentVersions } from 'app/agent-versions/components/AgentVersions';
import LoginRoute from './components/LoginRoute';
import SecureRoute from './components/SecureRoute';
import ProtectedRoute from './ProtectedRoute';
import RobotExecutionsList from 'app/robot-executions/components/RobotExecutionsList';
import { ErrorReasons } from 'app/error-reasons/components/ErrorReasons';
import { CreateEditErrorReasons } from 'app/error-reasons/components/CreateEditErrorReasons';
import RecoveryPassword from 'app/recovery-password/components/recovery';
import { NotificationCenter } from 'app/notifications/components/notification-center';

export default function RootRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={APP_ROUTES.login} element={<LoginRoute />} />
        <Route path={APP_ROUTES.recoverypassword} element={<RecoveryPassword />} />
        <Route path={APP_ROUTES.dashboard} element={<SecureRoute />}>
          <Route path={APP_ROUTES.blank}
            element={
              <ProtectedRoute role={[RolesDictionary.Process.Dashboard_Read, RolesDictionary.Process.Schedule_Read]}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={APP_ROUTES.processes} element={<SecureRoute />}>
          <Route
            path={APP_ROUTES.schedule}
            element={
              <ProtectedRoute role={[RolesDictionary.Process.Schedule_Read]}>
                <Schedule />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.process.list}
            element={
              <ProtectedRoute role={[RolesDictionary.Process.Processes_Read]}>
                <Processes />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.process.add}
            element={
              <ProtectedRoute role={[RolesDictionary.Process.Processes_Update]}>
                <CreateEditProcesses />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.process.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.Process.Processes_Update]}>
                <CreateEditProcesses />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.robotExecutions.list}
            element={
              <ProtectedRoute role={[RolesDictionary.Process.AgentLog_Read]}>
                <RobotExecutionsList />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={APP_ROUTES.infrastructure} element={<SecureRoute />}>
          <Route
            path={APP_ROUTES.machine.list}
            element={
              <ProtectedRoute role={[RolesDictionary.Infrastructure.Machines_Read]}>
                <Machine />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.machine.add}
            element={
              <ProtectedRoute role={[RolesDictionary.Infrastructure.Machines_Update]}>
                <CreateEditMachine />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.machine.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.Infrastructure.Machines_Update]}>
                <CreateEditMachine />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.machineActivity.list}
            element={
              <ProtectedRoute role={[RolesDictionary.Infrastructure.Machines_Read]}>
                <MachineActivity />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={APP_ROUTES.masterdata} element={<SecureRoute />}>
          <Route
            path={APP_ROUTES.countries.list}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Countries_Read]}>
                <Countries />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.countries.add}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Countries_Update]}>
                <CreateEditCountries />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.countries.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Countries_Update]}>
                <CreateEditCountries />
              </ProtectedRoute>
            }
          />

          <Route
            path={APP_ROUTES.errorreasons.list}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.ErrorReason_Read]}>
                <ErrorReasons />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.errorreasons.add}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.ErrorReason_Update]}>
                <CreateEditErrorReasons />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.errorreasons.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.ErrorReason_Update]}>
                <CreateEditErrorReasons />
              </ProtectedRoute>
            }
          />

          <Route
            path={APP_ROUTES.robotType.list}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.RobotTypes_Read]}>
                <RobotType />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.robotType.add}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.RobotTypes_Update]}>
                <CreateEditRobotType />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.robotType.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.RobotTypes_Update]}>
                <CreateEditRobotType />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.sites.list}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Sites_Read]}>
                <Sites />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.sites.add}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Sites_Update]}>
                <CreateEditSites />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.sites.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Sites_Update]}>
                <CreateEditSites />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.departaments.list}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Departments_Read]}>
                <Departaments />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.departaments.add}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Departments_Update]}>
                <CreateEditDepartaments />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.departaments.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Departments_Update]}>
                <CreateEditDepartaments />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.technologies.list}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Technologies_Read]}>
                <Technologies />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.technologies.add}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Technologies_Update]}>
                <CreateEditTechnologies />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.technologies.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.MasterData.Technologies_Update]}>
                <CreateEditTechnologies />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.machineType.list}
            element={
              <ProtectedRoute role={[RolesDictionary.Infrastructure.MachineTypes_Read]}>
                <MachineType />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.machineType.add}
            element={
              <ProtectedRoute role={[RolesDictionary.Infrastructure.MachineTypes_Update]}>
                <CreateEditMachineType />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.machineType.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.Infrastructure.MachineTypes_Update]}>
                <CreateEditMachineType />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.operationsystem.list}
            element={
              <ProtectedRoute role={[RolesDictionary.Infrastructure.OperationSystems_Read]}>
                <OperationSystem />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.operationsystem.add}
            element={
              <ProtectedRoute role={[RolesDictionary.Infrastructure.OperationSystems_Update]}>
                <CreateEditOperationSystem />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.operationsystem.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.Infrastructure.OperationSystems_Update]}>
                <CreateEditOperationSystem />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={APP_ROUTES.security} element={<SecureRoute />}>
          <Route path={APP_ROUTES.userprofile} element={<UserProfile />} />
          <Route
            path={APP_ROUTES.usermanagement.list}
            element={
              <ProtectedRoute role={[RolesDictionary.Security.UserManagement]}>
                <UserManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.usermanagement.add}
            element={
              <ProtectedRoute role={[RolesDictionary.Security.UserManagement]}>
                <CreateEditUser />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.usermanagement.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.Security.UserManagement]}>
                <CreateEditUser />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.groupmanagement.list}
            element={
              <ProtectedRoute role={[RolesDictionary.Security.GroupManagement]}>
                <GroupManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.groupmanagement.add}
            element={
              <ProtectedRoute role={[RolesDictionary.Security.GroupManagement]}>
                <CreateEditGroup />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.groupmanagement.edit}
            element={
              <ProtectedRoute role={[RolesDictionary.Security.GroupManagement]}>
                <CreateEditGroup />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={APP_ROUTES.notifications.base} element={<SecureRoute />}>
          <Route
            path={APP_ROUTES.notifications.center}
            element={
              <ProtectedRoute role={[RolesDictionary.Notifications.Notification_Create, RolesDictionary.Notifications.Notification_Read]}>
                <NotificationCenter />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.notifications.view}
            element={
              <ProtectedRoute role={[RolesDictionary.Notifications.Notification_Create, RolesDictionary.Notifications.Notification_Read]}>
                <NotificationCenter />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={APP_ROUTES.settings} element={<SecureRoute />}>
          <Route
            path={APP_ROUTES.healthcheck}
            element={
              <ProtectedRoute role={[RolesDictionary.Security.Settings]}>
                <HealthCheck />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.generalsettings}
            element={
              <ProtectedRoute role={[RolesDictionary.Security.Settings]}>
                <GeneralSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.apiClients}
            element={
              <ProtectedRoute role={[RolesDictionary.Security.APIClients]}>
                <APIClients />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.agentVersions}
            element={
              <ProtectedRoute role={[RolesDictionary.Security.Administrator]}>
                <AgentVersions />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={APP_ROUTES.notfoundpage} element={<NotFound />} />
        <Route path={APP_ROUTES.unauthorizedpage} element={<Unauthorized />} />
        <Route path={APP_ROUTES.loading} element={<Loading />} />
        <Route path="" element={<Navigate to={APP_ROUTES.login} />} />
        <Route path="*" element={<Navigate to={APP_ROUTES.notfoundpage} />} />
      </Routes>
    </BrowserRouter>
  );
}
