import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { HubConnection } from '@microsoft/signalr';
import { tableHelper } from 'common/helpers/table/table-helper';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { MachineService } from 'app/machine/services/machine.service';
import { MachineActivityViewModel } from '../model/machine-activity.model';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';

type Props = {
  hubConnection?: HubConnection | null;
}

export function MachineActivity(props: Props) {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);

  const { t } = useTranslation();
  const [tableData, setTableData] = useState<MachineActivityViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const columns: TableColumn<any>[] = [
    {
      name: `${t('machineActivity.tableHeaders.machineName')}`,
      selector: (row) => row.machineName || '',
      sortable: true
    },
    {
      name: `${t('machineActivity.tableHeaders.situation')}`,
      cell: (row) =>
        row.isConnected ? (
          <span className="badge badge-light-success">{t('generalMessages.connected')}</span>
        ) : (
          <span className="badge badge-light-secondary">{t('generalMessages.disconnected')}</span>
        ),
      center: true,
      width: '150px'
    }
  ];

  useEffect(() => {
    const receiveMessages = async () => {
      if (props.hubConnection) {
          props.hubConnection.on("UpdateConnection", message => setMessage(message))
      }
    }
    receiveMessages();

    return (() => {
      if (props.hubConnection) {
        props.hubConnection.off("UpdateConnection");
      }
    })
  }, [props.hubConnection]);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await MachineService.getMachineActivity();
    if (response && !response.hasErrors) {
      setTableData(response.data);
    } else {
      toast.error(`${t('crud.read.errorMessage')}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card card-flush">
          <div className="card-body">
            <DataTable
              persistTableHead
              columns={columns}
              data={tableData}
              noDataComponent={tableHelper.getNoDataComponent()}
              selectableRowsHighlight
              dense
              highlightOnHover
              pagination
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
              paginationPerPage={tableHelper.defaultRowsPerPage}
              paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              contextMessage={tableHelper.getContextMessage()}
              selectableRowsVisibleOnly={true}
              customStyles={itLabTableStyle}
            />
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  );
}
