import { useContext } from "react";
import ScheduleContext from "../context/schedule.context";
import ScheduleCalendarView from "./views/calendar-view/CalendarView";
import ScheduleListView from "./views/list-view/ListView";
import ScheduleHeader from "./ScheduleHeader";

export default function SchedulePage() {
    const { contextOptions } = useContext(ScheduleContext)
    return <>
        <div className="card card-flush shadow-none">
            <div className="card-body">
                <ScheduleHeader />
                {contextOptions?.currentView === "calendar" && <ScheduleCalendarView />}
                {contextOptions?.currentView === "list" && <ScheduleListView />}
            </div>
        </div>
    </>;
}