import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import filterIcon from 'assets/images/icons/filter.svg';
import { ProcessStepsModel } from 'app/processes/components/childs/processes-steps/model/process-steps.model';
import { MachineModel } from 'app/machine/model/machine.model';
import { DepartamentsModel } from 'app/departaments/model/departaments.model';
import { ProcessStepsService } from 'app/processes/components/childs/processes-steps/services/process-steps.service';
import ITSVG from 'common/helpers/ITSVG';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import { ScheduleService as Service } from '../../../services/schedule.service'
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import { FetchEventsFilter } from 'app/schedule/model/schedule.model';
import ScheduleContext from 'app/schedule/context/schedule.context';

export function ScheduleFilterDropdown() {
  const { t } = useTranslation();

  const { setScheduleFilter } = useContext(ScheduleContext)

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [departments, setDepartaments] = useState<DepartamentsModel[]>([]);
  const [machines, setMachines] = useState<MachineModel[]>([]);
  const [steps, setSteps] = useState<ProcessStepsModel[]>([]);

  const [departmentId, setDepartmentId] = useState<number | null>(null)
  const [machineId, setMachineId] = useState<number | null>(null)
  const [stepId, setStepId] = useState<number | null>(null)

  const [departmentValue, setDepartmentValue] = useState<DepartamentsModel | null>(null);
  const [stepValue, setStepValue] = useState<ProcessStepsModel | null>(null);
  const [machineValue, setMachineValue] = useState<MachineModel | null>(null);
  const [filterButtonName, setFilterButtonName] = useState<string>('');

  const helper = new TokenHelper();

  const fetchDepartments = async () => {
    setIsLoading(true);
    const response = await Service.getDepartamentsList();
    if (response && !response.hasErrors) {
      setDepartaments(response.data);
    }
    setIsLoading(false);
  }

  const fetchMachines = async () => {
    setIsLoading(true);
    if (departmentId) {
      const response = await Service.getMachineListByDepartmentId(departmentId);
      if (response && !response.hasErrors) {
        setMachines(response.data);
      }
    } else {
      const response = await Service.getMachines();
      if (response && !response.hasErrors) {
        setMachines(response.data);
      }
    }
    setIsLoading(false);
  }

  const fetchSteps = async () => {
    setIsLoading(true);
    if (machineId) {
      const response = await Service.getRobotListByMachineId(machineId);
      if (response && !response.hasErrors) {
        setSteps(response.data);
      }
    } else if (departmentId) {
      const response = await Service.getRobotListByDepartmentId(departmentId);
      if (response && !response.hasErrors) {
        setSteps(response.data);
      }
    } else {
      const response = await ProcessStepsService.getDataList();
      if (response && !response.hasErrors) {
        setSteps(response.data);
      }
    }
    setIsLoading(false);
  }

  const handleFilterChange = () => {
    const filters: FetchEventsFilter={
      departmentId,
      machineId,
      stepId
    }

    setScheduleFilter(filters);

    const filterName = [
      departmentValue?.name,
      machineValue?.name,
      stepValue?.name,
    ]
      .filter(Boolean)
      .join(' | ');

    setFilterButtonName(filterName);
  };

  const clearDepartmentFilter = () => {
    setDepartaments([]);
    setDepartmentValue(null);
    setDepartmentId(null);
  }

  const clearMachineFilter = () => {
    setMachines([]);
    setMachineValue(null);
    setMachineId(null);
  }

  const clearStepFilter = () => {
    setSteps([]);
    setStepValue(null);
    setStepId(null);
  }

  return (
    <div className='me-4'>
      <button
        type='button'
        className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <ITSVG path={filterIcon} className='svg-icon-5 svg-icon-gray-500 me-1' />
        {filterButtonName === '' ? t('generalMessages.filter') : filterButtonName}
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{t('generalMessages.filterOptions')}</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>{t('filter.department')}</label>
            <div>
              <Autocomplete
                id='departments'
                options={departments}
                getOptionLabel={option => option.name ? option.name : ''}
                onChange={(e, value) => {
                  e.stopPropagation();
                  if (!value) {
                    clearDepartmentFilter();
                    clearMachineFilter();
                    clearStepFilter();
                  } else {
                    clearMachineFilter();
                    clearStepFilter();
                    setDepartmentValue(value);
                    setDepartmentId(value?.id ?? null);
                  }
                }}
                onOpen={() => {
                  if (departments.length === 0) {
                    fetchDepartments();
                  }
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    placeholder={`${t('generalMessages.select')}`}
                  />
                }
                loading={isLoading}
                loadingText={<div className='text-center'><CircularProgress size={14} /></div>}
                noOptionsText={t('filter.noDataMessage.department')}
              />
            </div>
          </div>
          <div className='mb-10'>
            <label className='form-label fw-bold'>{t('filter.machine')}</label>
            <div>
              <Autocomplete
                id='machines'
                options={machines}
                value={machineValue ?? null}
                getOptionLabel={option => option.name ? option.name : ''}
                onChange={(e, value) => {
                  e.stopPropagation();
                  if (!value) {
                    clearMachineFilter();
                    clearStepFilter();
                  } else {
                    clearStepFilter();
                    setMachineValue(value);
                    setMachineId(value?.id ?? null);
                  }
                }}
                onOpen={() => {
                  if (machines.length === 0 || departmentId) {
                    fetchMachines();
                  }
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    placeholder={`${t('generalMessages.select')}`}
                  />
                }
                loading={isLoading}
                loadingText={<div className='text-center'><CircularProgress size={14} /></div>}
                noOptionsText={t('filter.noDataMessage.machine')}
                disabled={!helper.HasAccess([RolesDictionary.Process.Schedule_Read])}
              />
            </div>
          </div>
          <div className='mb-10'>
            <label className='form-label fw-bold'>{t('filter.step')}</label>
            <div>
              <Autocomplete
                id='steps'
                options={steps}
                value={stepValue ?? null}
                getOptionLabel={option => option.name ? option.name : ''}
                onChange={(e, value) => {
                  e.stopPropagation();
                  if (!value) {
                    clearStepFilter();
                  } else {
                    setStepValue(value);
                    setStepId(value?.id ?? null);
                  }
                }}
                onOpen={(e) => {
                  if (steps.length === 0 || machineId || departmentId) {
                    fetchSteps();
                  }
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    placeholder={`${t('generalMessages.select')}`}
                  />
                }
                loading={isLoading}
                loadingText={<div className='text-center'><CircularProgress size={14} /></div>}
                noOptionsText={t('filter.noDataMessage.robot')}
              />
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
            >
              {t('generalMessages.cancel')}
            </button>
            <button
              type='button'
              onClick={handleFilterChange}
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              disabled={isLoading}
            >
              {t('generalMessages.apply')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
