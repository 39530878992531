import AppLogo from 'common/partials/AppLogo';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal } from "react-bootstrap";
import { ForgottenPassword as Model } from '../model/forgotten-password.model';
import { AuthService as Service } from 'common/helpers/auth/auth.service';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import clsx from 'clsx';

interface ForgottenPasswordModalProps {
    show: boolean;
    onHide: () => void;
}

export default function ForgottenPasswordModal(props: ForgottenPasswordModalProps) {
    i18next.addResourceBundle("us", "translation", en);
    i18next.addResourceBundle("es", "translation", es);
    i18next.addResourceBundle("br", "translation", pt);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const formSchema = Yup.object().shape({
        email: Yup.string().required(t('forgottenpassword.form.emailrequired'))
    });

    const forgottenPasswordRequest = async (values: Model) => {
        setLoading(true);
        try {
            const response = await Service.requestPassword(values);
            if (response && !response.hasErrors) {
                toast.success(`${t('forgottenpassword.form.success')}`);
                props.onHide();
            } else {
                const errorCode = response?.errorCode;
                toast.error(`${t(`forgottenpassword.form.errors.${errorCode}`)}`);
            }
        } catch (err) {
            toast.error(t('forgottenpassword.form.errors.entity-not-found'));
        }
        finally {
            setLoading(false);
        }
    };

    const formik = useFormik<Model>({
        initialValues: {
            email: ''
        },
        validationSchema: formSchema,
        onSubmit: forgottenPasswordRequest,
    });

    return <Modal show={props.show} onHide={props.onHide} >
        <Modal.Body>
            <form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="request_password_form">
                <div className="text-center mb-10">
                    <AppLogo height={70} />
                </div>
                <h3 className="text-center mb-3">{t("forgottenpassword.title")}</h3>
                <div className="mb-8">
                    <input
                        type="email"
                        placeholder={t("forgottenpassword.form.placeholder.email")}
                        autoComplete="off"
                        {...formik.getFieldProps('email')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.email && formik.errors.email,
                            },
                            {
                                'is-valid': formik.touched.email && !formik.errors.email,
                            },
                        )}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">
                                <span role="alert">{t("crud.validators.requiredField")}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="text-center">
                    <button
                        type="submit"
                        id="request_password_button"
                        className="btn btn-lg btn-primary w-100 mb-5"
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        {!loading && <span className="indicator-label">{t("forgottenpassword.form.submit")}</span>}
                        {loading && (
                            <span className="indicator-progress" style={{ display: 'block' }}>
                                {t("crud.common.wait")}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        )}
                    </button>
                </div>
            </form>
        </Modal.Body>
    </Modal>
}