import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { tableHelper } from 'common/helpers/table/table-helper';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import ActionButton from 'common/partials/ActionButton';
import { AgentVersionModel as Model } from '../model/agent-versions.model'
import { AgentVersionService as Service } from '../services/agent-versions.service';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import download from 'common/helpers/download/download';
import { NotesModal } from './NotesModal';

interface Notes {
  IsOpen: boolean;
  Version?: Model;
}

export function AgentVersions() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  i18next.addResourceBundle('es', 'translation', es);
  
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<Model[]>([]);
  const [notes, setNotes] = useState<Notes>({ IsOpen: false });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await Service.getDataList();
      if (response && !response.hasErrors) {
        setTableData(response.data);
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadButton = async (entity: Model) => {
    if (entity.hash) {
      const blobResponse = await Service.downloadFile(entity.version);
      if (blobResponse) {
        download(blobResponse, `agent-${entity.version}.zip`);
      }
    }
    else {
      toast.error(`${t('agentVersions.download.errorMessage')}`);
    }
  }

  const handleCopyButton = (entity: Model) => {
    navigator.clipboard.writeText(entity.hash);
    toast.info(`${t('agentVersions.copied')}`)
  }

  const handleOpenDetails = (entity: Model) => {
    setNotes({ IsOpen: true, Version: entity});
  }
  
  const handleCloseDetails = () => {
    setNotes({ IsOpen: false });
  }

  const columns: TableColumn<Model>[] = [
    {
      name: `${t('agentVersions.tableHeaders.id')}`,
      selector: (row) => row.id || '',
      sortable: false,
      center: true,
      width: '75px'
    },
    {
      name: `${t('agentVersions.tableHeaders.name')}`,
      selector: (row) => row.version || '',
      sortable: false
    },
    {
      name: `${t('agentVersions.tableHeaders.release')}`,
      selector: (row) => row.releaseIn || '',
      sortable: false
    },
    {
      name: `${t('agentVersions.tableHeaders.actions')}`,
      cell: (row) => (
        <div className="d-grid d-flex gap-1">
          <ActionButton type='details' onClick={() => handleOpenDetails(row)} />
          <ActionButton type='download' onClick={() => handleDownloadButton(row)} disabled={(row.hash == null)} />
          <ActionButton type='copy' onClick={() => handleCopyButton(row)} disabled={(row.hash == null)} />
        </div>
      ),
      center: true,
      width: '150px'
    }
  ];

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <NotesModal IsOpen={notes.IsOpen} Version={notes.Version} Close={handleCloseDetails} />
        <div className="alert alert-warning mb-5 p-5" role="alert">
          <h4 className="alert-heading">{t('agentVersions.validationFile.title')}</h4>
          <p>{t('agentVersions.validationFile.description')} </p>
          <div className="border-bottom border-dark opacity-20 mb-5"></div>
          <p className="mb-0">{t('agentVersions.validationFile.steps')} <code>(Get-FileHash {t('agentVersions.validationFile.file')}).Hash -eq '{t('agentVersions.validationFile.copied')}'</code></p>
        </div>
        <div className="card card-flush shadow-none mt-10">
          <div className="card-body p-0">
            <DataTable
              title={false}
              persistTableHead={true}
              columns={columns}
              data={tableData}
              noDataComponent={tableHelper.getNoDataComponent()}
              pagination
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
              highlightOnHover
              pointerOnHover
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              customStyles={itLabTableStyle}
              dense
            />
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  )

}


